import { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularLoaderStyle, LoadingContainer } from './index.style'

class CircularLoader extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    size: PropTypes.string,
    bgColor: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const { children, isLoading, bgColor, className = '', size = '50px' } = this.props
    return (
      <CircularLoaderStyle className={className}>
        {
          isLoading && (
            <LoadingContainer size={size} bgColor={bgColor}>
              <div className='lds-roller'><div /><div /><div /><div /><div /><div /><div /><div /></div>
            </LoadingContainer>
          )
        }
        {children}
      </CircularLoaderStyle>
    )
  }
}

export default CircularLoader
