import { PureComponent } from 'react'
import { RequiredStar, Row } from '../../commonStyle/index.style'
import { AgentColorPallette } from '../../configs/colorPallete'
import CustomText from '../CustomText'

class TitleError extends PureComponent {
  render () {
    const {
      title, error, required = false,
      weight, titleClassName, size = '12px'
    } = this.props
    return (
      <Row justify='space-between'>
        <CustomText
          size={size}
          className={titleClassName}
          color={AgentColorPallette.darkCharcoal}
          top='4px'
          bottom='4px'
          weight={weight}
        >
          {title}
          {required && <RequiredStar>*</RequiredStar>}
        </CustomText>
        {
          error && (
            <CustomText
              color={AgentColorPallette.errorDark}
              top='4px'
              bottom='4px'
            >
              {error}
            </CustomText>
          )
        }
      </Row>
    )
  }
}

export default TitleError
