import motorActions from './actions'

const initState = {
  motorBrandList: [],
  motorBrandListStatus: '',
  motorModelList: [],
  motorModelListStatus: ''
}

export default function motorReducer (state = initState, action) {
  switch (action.type) {
    case motorActions.GET_MOTOR_BRAND_SUCCESS:
    case motorActions.GET_MOTOR_BRAND_FAILURE:
      return {
        ...state,
        motorBrandList: action.data,
        motorBrandListStatus: action.message
      }
    case motorActions.GET_MOTOR_MODEL_SUCCESS:
    case motorActions.GET_MOTOR_MODEL_FAILURE:
      return {
        ...state,
        motorModelList: action.data,
        motorModelListStatus: action.message
      }
    case motorActions.RESET_MOTOR_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    default:
      return {
        ...state
      }
  }
}
