import propertyActions from './actions'

const initState = {
  submitPropertyFormData: {},
  submitPropertyFormStatus: '',
  provinces: [],
  provincesStatus: '',
  propertyCities: [],
  propertyCitiesStatus: '',
  propertyOccupation: [],
  propertyOccupationStatus: '',
  propertyConstruction: [],
  propertyAddonsData: [],
  propertyAddonsStatus: '',
  propertyConstructionStatus: ''
}

export default function propertyReducer (state = initState, action) {
  switch (action.type) {
    case propertyActions.SUBMIT_PROPERTY_FORM_SUCCESS:
    case propertyActions.SUBMIT_PROPERTY_FORM_FAILURE:
      return {
        ...state,
        submitPropertyFormData: action.data,
        submitPropertyFormStatus: action.message
      }
    case propertyActions.GET_PROPERTY_PROVINCE_SUCCESS:
    case propertyActions.GET_PROPERTY_PROVINCE_FAILURE:
      return {
        ...state,
        provinces: action.data,
        provincesStatus: action.message
      }
    case propertyActions.GET_PROPERTY_CITIES_SUCCESS:
    case propertyActions.GET_PROPERTY_CITIES_FAILURE:
      return {
        ...state,
        propertyCities: action.data,
        propertyCitiesStatus: action.message
      }
    case propertyActions.GET_PROPERTY_OCCUPATION_SUCCESS:
    case propertyActions.GET_PROPERTY_OCCUPATION_FAILURE:
      return {
        ...state,
        propertyOccupation: action.data,
        propertyOccupationStatus: action.message
      }
    case propertyActions.GET_PROPERTY_CONSTRUCTION_SUCCESS:
    case propertyActions.GET_PROPERTY_CONSTRUCTION_FAILURE:
      return {
        ...state,
        propertyConstruction: action.data,
        propertyConstructionStatus: action.message
      }
    case propertyActions.GET_PROPERTY_ADDONS_SUCCESS:
    case propertyActions.GET_PROPERTY_ADDONS_FAILURE:
      return {
        ...state,
        propertyAddonsData: action.data,
        propertyAddonsStatus: action.message
      }
    case propertyActions.RESET_PROPERTY_STORE: {
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        const stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initState
        }
      }
    }
    default:
      return state
  }
}
