import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const HeaderWrapper = styled.div`
  position: ${props => props.headerPosition || 'fixed'};
  background-color: ${props => props.backgroundColor || 'transparent'};
  width: 100%;
  max-width: 450px;

  .header-container {
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    display: grid;
    grid-template-columns: 1fr 20fr;
    color: ${props => props.titleColor || AgentColorPallette.white};
  }

  .back {
    width: 20px;
    grid-row: 1 / 1;
    grid-column: 1;
    z-index: 3;
    color: ${props => props.backBtnColor || props.theme.white};

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .heading {
    grid-row: 1 / 1;
    grid-column: 1 / 3;
    text-align: center;
  }
`
