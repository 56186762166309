import styled from 'styled-components'
import { MitraCard } from '../../commonStyle/index.style'

export const RadioInputCardStyle = styled(MitraCard)`
  width: auto;
  padding: 0;
  margin-top: 0px;

  .radio-border-btm {
    border-bottom: 1px solid ${props => props.theme.Gray90};
  }

  .vertical-group-pad-top {
    padding: 10px;
  }
`
