import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const CustomCollapseWrapper = styled.div`
  border: ${props => props.hideBorders ? 'unset' : `1px solid ${AgentColorPallette.fontDarkerGrey}`};
  border-radius: ${props => props.hideBorders ? 'unset' : '5px'};
  margin: ${props => props.hideBorders ? '0px' : '1% 0'};
  .display-none{
    display: none;
  }
  .collapse-header{
    padding: 4%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .collapse-body{
    border-top: ${props => props.hideBorders ? 'unset' : `1px solid ${AgentColorPallette.fontDarkerGrey}`};
    padding: 4%;
    padding-top: ${props => props.hideBorders ? '0px' : '4%'};
  }
  .arrow-holder {
    margin-top: -5px;
  }
  i {
    border: solid ${AgentColorPallette.fontDarkerGrey};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    border-radius: 2px;

    &.arrow-up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    &.arrow-down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
`
