import { CustomText } from '../../../../../components'
import { AgentColorPallette } from '../../../../../configs/colorPallete'
import { LANG_STRINGS } from '../../../../../lang'
import { BranchCardStyle } from './index.style'
import PropTypes from 'prop-types'

function BranchCard (props) {
  const { branch = {}, padding, borderColor } = props
  let branchAddress = ''
  if (branch?.location?.city_name) {
    branchAddress = `${branch?.location?.address}, ${branch?.location?.city_name} ${branch?.location?.province_name}, ${branch?.location?.postal_code}`
  }
  return (
    <BranchCardStyle padding={padding} borderColor={borderColor}>
      <CustomText
        size='13px'
        color={AgentColorPallette.regular}
      >
        {LANG_STRINGS.addressToBranch}
      </CustomText>
      <CustomText
        size='13px'
        color={AgentColorPallette.regular}
        weight={800}
      >
        {branch.name}
      </CustomText>
      <CustomText
        size='10px'
        color={AgentColorPallette.regular}
        weight={600}
      >
        {branchAddress}
      </CustomText>
    </BranchCardStyle>
  )
}

BranchCard.propTypes = {
  name: PropTypes.string,
  address: PropTypes.object
}

export default BranchCard
