import styled from 'styled-components'
import { DisplayFlexCCC } from '../../commonStyle/index.style'

export const ErrorBoundaryStyle = styled(DisplayFlexCCC)`
  height: 100vh;
  .reload-btn {
    width: 90%;
    margin: 20px 0;
  }

  .error-text {
    color: #fa6464;
    font-weight: 800;
    text-align: center;
  }

  .warning-error {
    color: #fa6464;
    font-size: 6em;
  }
`
