import actions from './action'
import { gTagForUserId } from '../../tagManager'
import { checkIsGojekDriver } from '../../helpers/checkAgentTags'
import { getGlobalStateObject } from '../../helpers/utility'
import { AGENT_TYPE } from '../../constants/appConstants'

const initState = {
  agentDetails: {},
  agentDetailsStatus: '',
  productList: [],
  notificationList: [],
  notificationMsg: '',
  updateReadNotifyData: {},
  updateReadNotifyMsg: '',
  agentDownLineList: [],
  agentDownLineListStatus: '',
  agentBadgesData: {},
  agentBadgesStatus: '',
  agentPayLaterCount: 0,
  agentPayLaterStatus: '',
  agentDownLineChildList: [],
  downlineLevel: 0,
  agentAggregatorDownLineList: [],
  isHideAgentBadges: undefined, // so that we can show loader
  isGojekDriver: false,
  isInternalAgent: false
}

export default function authReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_AGENT_DETAILS_SUCCESS:
    case actions.GET_AGENT_DETAILS_FAILURE: {
      if (action.data && action.data.id && !state?.agentDetails?.agent_code) {
        const data = action.data
        const agentSource = checkIsGojekDriver(data) || { code: 'mitra' }
        gTagForUserId(
          data.agent_code,
          ['@pasarpolis.com', '@pasarpolis.co.id'].includes(action.data.email) ? AGENT_TYPE.MARKETING_AGENT : AGENT_TYPE.RETAIL_AGENT,
          agentSource.code
        )
      }
      return {
        ...state,
        ...getGlobalStateObject(action.data),
        agentDetailsStatus: action.message
      }
    }
    case actions.GET_AGENT_BADGES_SUCCESS:
    case actions.GET_AGENT_BADGES_FAILURE:
      return {
        ...state,
        agentBadgesData: action.data,
        isHideAgentBadges: [406, 403].includes(action.statusCode),
        agentBadgesStatus: action.message
      }
    case actions.GET_AGENT_PAYLATER_COUNT_SUCCESS:
    case actions.GET_AGENT_PAYLATER_COUNT_FAILURE:
      return {
        ...state,
        agentPayLaterCount: action.data,
        agentPayLaterStatus: action.message
      }
    case actions.GET_NOTIFICATION_SUCCESS:
    case actions.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationList: action.data,
        notificationMsg: action.message
      }
    case actions.SEND_SMS_SUCCESS:
    case actions.SEND_SMS_FAILURE:
      return {
        ...state,
        sendSmsData: action.data,
        sendSmsMsg: action.message
      }
    case actions.UPDATE_READ_NOTIFICATION_SUCCESS:
    case actions.UPDATE_READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        updateReadNotifyData: action.data,
        updateReadNotifyMsg: action.message
      }
    case actions.GET_PRODUCT_LIST_SUCCESS:
    case actions.GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        productList: action.data,
        productListMessage: action.message
      }
    case actions.GET_DOWNLINE_SUCCESS:
    case actions.GET_DOWNLINE_FAILURE:
      return {
        ...state,
        agentDownLineList: action.data,
        agentDownLineListStatus: action.message
      }
    case actions.GET_DOWNLINE_CHILD_SUCCESS:
    case actions.GET_DOWNLINE_CHILD_FAILURE:
      return {
        ...state,
        downlineLevel: action.downLineLevel,
        agentDownLineChildList: state.downlineLevel !== action.downLineLevel ? [...action.data.data] : [...state.agentDownLineChildList, ...action.data.data],
        agentDownLineChildMeta: action.data.meta_data,
        agentDownLineChildListStatus: action.message
      }
    case actions.GET_AGGREGATOR_DOWNLINE_SUCCESS:
    case actions.GET_AGGREGATOR_DOWNLINE_FAILURE:
      return {
        ...state,
        agentAggregatorDownLineList: action.isFilterApply ? [...action.data.data] : [...state.agentAggregatorDownLineList, ...action.data.data],
        agentAggregatorDownLineMeta: action.data.meta_data,
        agentAggregatorDownLineListStatus: action.message
      }
    case actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_SUCCESS:
    case actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_FAILURE:
      return {
        ...state,
        agentAggregatorBusiness: action.data,
        agentAggregatorBusinessStatus: action.message
      }
    case actions.GET_STAFF_NAME_LIST_SUCCESS:
    case actions.GET_STAFF_NAME_LIST_FAILURE:
      return {
        ...state,
        staffNameList: action.data,
        staffNameListStatus: action.message
      }
    case actions.RESET_USER_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    default:
      return state
  }
}
