import { Component } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { DobInputStyle } from './index.style'
import moment from 'moment'
import CustomText from '../CustomText'
import { ERROR_LANG } from '../../lang'
import { AgentColorPallette } from '../../configs/colorPallete'

class DobInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    showCalenderIcon: PropTypes.bool,
    onDobSelect: PropTypes.func,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    defaultValue: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      date: new Date(this.props.defaultValue ? moment(this.props.defaultValue, 'DD/MM/YYYY').format() : Date.now()),
      selectedDate: this.props.defaultValue || '',
      showError: false,
      error: ERROR_LANG.invalidDateFormat
    }
    this.isValidDate = false
    this.isSetDefaultValue = false
    this.dobOnceFilled = false
  }

  componentWillReceiveProps (nextProps) {
    const { defaultValue } = nextProps

    if (!this.isSetDefaultValue && defaultValue && (defaultValue !== this.state.selectedDate)) {
      this.isSetDefaultValue = true
      this.setState({
        selectedDate: defaultValue,
        date: new Date(defaultValue ? moment(defaultValue, 'DD/MM/YYYY').format() : Date.now())
      })
    }
  }

  resetDate = () => {
    this.setState({
      selectedDate: ''
    })
    this.isSetDefaultValue = false
  }

  handleInputChange = (data) => {
    // this is the calendar view on change date
    const { minDate, maxDate, errorMsg } = this.props
    const date = moment((data.formattedValue || this.state.selectedDate), 'DD/MM/YYYY', true)
    const minDateWithoutTime = moment(moment(minDate).format('DD/MM/YYYY'), 'DD/MM/YYYY', true)._d
    const maxDateWithoutTime = moment(moment(maxDate).format('DD/MM/YYYY'), 'DD/MM/YYYY', true)._d
    let isValid = true
    let error = ''
    if (this.isValidDate && !data.value) {
      this.isValidDate = false
    }
    if (data.value && !date._isValid && data.value.length === 8) {
      isValid = false
    } else if (data.value && date._isValid && data.value.length === 8) {
      this.isValidDate = true
    } else if (this.isValidDate && !date._isValid) {
      isValid = false
    }

    if (!isValid) {
      error = ERROR_LANG.invalidDateFormat
    }

    if (
      (minDate && (Date.parse(date._d) < Date.parse(minDateWithoutTime))) ||
      (maxDate && (Date.parse(date._d) > Date.parse(maxDateWithoutTime)))
    ) {
      isValid = false
      error = errorMsg || `Silakan masukkan tanggal di antara ${moment(minDate).format('DD/MM/YYYY')} hingga ${moment(maxDate).format('DD/MM/YYYY')} `
    }

    this.setState({
      showError: !isValid,
      date: date._isValid ? new Date(date.format()) : new Date(),
      selectedDate: data.formattedValue ? data.formattedValue : (data.target && data.target.value),
      error
    }, () => {
      if (this.props?.dateRangeError) {
        this.props.dateRangeError(this.state.showError)
      }
      this.updateDob()
    })
  }

  updateDob = () => {
    const { selectedDate } = this.state
    if (selectedDate && !(
      selectedDate.includes('D') ||
      selectedDate.includes('M') ||
      selectedDate.includes('Y')
    )) {
      this.props.onDobSelect(selectedDate, this.state.showError)
      this.dobOnceFilled = true
    } else if (this.dobOnceFilled) {
      this.props.onDobSelect('')
    }
  }

  render () {
    const {
      errorField, name, displayBorder, className,
      disabled, showCalenderIcon, borderColor
    } = this.props
    const { showError, error } = this.state
    return (
      <DobInputStyle
        error={showError || (errorField === name)}
        displayBorder={displayBorder}
        borderColor={borderColor}
        className={className}
        disabled={disabled}
      >
        <NumberFormat
          format='##/##/####'
          placeholder='DD/MM/YYYY'
          mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
          value={this.state.selectedDate}
          className={`formate-dob-input ${showCalenderIcon ? 'input-calendar-icon' : ''}`}
          onValueChange={this.handleInputChange}
          disabled={disabled}
        />
        {
          showError && (
            <CustomText color={AgentColorPallette.errorDark} top='2px'>
              {error}
            </CustomText>
          )
        }
      </DobInputStyle>
    )
  }
}

export default DobInput
