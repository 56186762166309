export const getReferralLinkAndroid = (callback = () => {}) => {
  try {
    return window.PPBridge.getReferralLink()
  } catch (error) {
    // callback call if error
    callback(error)
  }
}

export const getReferralCodeAndroid = (callback = () => {}) => {
  try {
    return window.PPBridge.getReferredCode()
  } catch (error) {
    // callback call if error
    callback(error)
  }
}

export const getClearDataAndroid = (callback = () => {}) => {
  try {
    window.PPBridge.clearData()
  } catch (error) {
    // callback call if error
    callback(error)
  }
}

export const shareWithImage = (imgUrl, subject, msg, callback = () => {}) => {
  // share the image on whatsapp
  try {
    if (window.PPBridge.shareWithImage) {
      window.PPBridge.shareWithImage(
        imgUrl,
        subject,
        msg
      )
    } else {
      callback()
    }
  } catch (error) {
    callback(error)
  }
}

export const shareImageWithMob = (sharingImg, subject, msg, phone, callback = () => {}) => {
  try {
    if (window.PPBridge.shareImageWithMob) {
      window.PPBridge.shareImageWithMob(
        sharingImg,
        subject,
        msg,
        phone
      )
    } else {
      callback()
    }
  } catch (e) {
    callback(e)
  }
}

export const shareOnWhatsApp = (subject, message, callback = () => {}) => {
  // call this function to share the msg only to any whatsapp contact
  try {
    if (window.PPBridge.shareOnWhatsapp) {
      window.PPBridge.shareOnWhatsapp(subject, message)
    } else {
      callback()
    }
  } catch (e) {
    callback(e)
  }
}

export const shareImageOnWhatsApp = (imageUrl, subject, message, callback = () => {}) => {
  // call this api if you want to share only image with msg to any number
  try {
    if (window.PPBridge.shareImageOnWhatsapp) {
      window.PPBridge.shareImageOnWhatsapp(imageUrl, subject, message)
    } else {
      callback()
    }
  } catch (error) {
    callback()
  }
}

export const shareWithMob = (subject, message, phone, callback = () => {}) => {
  // this function open whatsApp with given mobile
  try {
    if (window.PPBridge.shareWithMob) {
      window.PPBridge.shareWithMob(subject, message, phone)
    } else {
      callback()
    }
  } catch (error) {
    callback()
  }
}

// get the version code
export const getVersionCode = (callback = () => {}) => {
  try {
    if (window?.PPBridge?.getVersionCode) {
      return window.PPBridge.getVersionCode()
    }
    callback()
  } catch (error) {
    // if this not exist then show error
    callback()
    return error
  }
}

// get the version name of the app
export const getVersionName = (callback = () => {}) => {
  try {
    if (window.PPBridge.getVersionName) {
      // this returns the version code for the app
      return window.PPBridge.getVersionName()
    }
    callback()
  } catch (error) {
    // if this not exist then show error
    callback()
    return error
  }
}

// open url in browser
export const openUrlInBrowser = (url, callback = () => {}) => {
  try {
    if (window.PPBridge.openUrlInBrowser) {
      // this returns the version code for the app
      window.PPBridge.openUrlInBrowser(url)
      return true
    }
    callback()
  } catch (error) {
    // if this not exist then show error
    callback()
    return error
  }
}

// open url in browser
export const isWhiteListedUrl = (url, callback = () => {}) => {
  try {
    if (window.PPBridge.isWhiteListedUrl) {
      // this returns the version code for the app
      window.PPBridge.isWhiteListedUrl(url)
      return true
    }
    callback()
  } catch (error) {
    // if this not exist then show error
    callback()
    return error
  }
}

// download file in the device
export const downloadFileInAndroid = (fileUrl, fileName, callback = () => {}) => {
  try {
    if (window.PPBridge.downloadFile) {
      // this returns the version code for the app
      window.PPBridge.downloadFile(fileUrl, fileName)
      return true
    }
    callback()
  } catch (error) {
    // if this not exist then show error
    callback()
    return error
  }
}

export const enableNativePullToRefresh = () => {
  try {
    if (window.PPBridge.enablePullToRefresh) {
      // this enable the pull refresh
      window.PPBridge.enablePullToRefresh()
      return true
    }
  } catch (error) {
    // if this not exist then show error
    return error
  }
}

export const disableNativePullToRefresh = () => {
  try {
    if (window.PPBridge.disablePullToRefresh) {
      // this disable the pull refresh
      window.PPBridge.disablePullToRefresh()
      return true
    }
  } catch (error) {
    // if this not exist then show error
    return error
  }
}

export const whiteListDomain = (data = []) => {
  try {
    if (window.PPBridge.whiteListDomain) {
      // this enable the pull refresh
      window.PPBridge.whiteListDomain(data)
      return true
    }
  } catch (error) {
    // if this not exist then show error
    return error
  }
}

export const getFCMToken = (data = []) => {
  try {
    if (window.PPBridge.getFCMToken) {
      // this enable the pull refresh
      return window.PPBridge.getFCMToken()
    }
  } catch (error) {
    // if this not exist then show error
    return null
  }
}

export const getFCMPayload = (data = []) => {
  try {
    if (window.PPBridge.getFCMPayload) {
      // this enable the pull refresh
      return JSON.parse(window.PPBridge.getFCMPayload())
    }
  } catch (error) {
    // if this not exist then show error
    return null
  }
}

export const saveFCMToken = (token = '') => {
  return new Promise(resolve => {
    if (window.PPBridge.saveFCMToken) {
      // this enable the pull refresh
      window.PPBridge.saveFCMToken(token)
      resolve()
    }
  })
}

// event should be triggered after successful login - To track login events
export const webengageLogInEvent = (userId = '') => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageLogInEvent) {
      window.PPBridge.webengageLogInEvent(userId)
      resolve()
    }
  })
}

// at successfull registration and when user changes their phone number - To register phone number
export const webengageUserPhone = (phoneNumber = '') => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageUserPhone) {
      window.PPBridge.webengageUserPhone(phoneNumber)
      resolve()
    }
  })
}

// at successfull registration and when user changes their first name - To register first name
export const webengageUserName = (firstName = '') => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageUserName) {
      window.PPBridge.webengageUserName(firstName)
      resolve()
    }
  })
}

// at successful logout event
export const webengageLogOutEvent = () => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageLogOutEvent) {
      window.PPBridge.webengageLogOutEvent()
      resolve()
    }
  })
}

// At successful register of user - to track user signup details
export const webengageUserSignedUp = () => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageUserSignedUp) {
      window.PPBridge.webengageUserSignedUp()
      resolve()
    }
  })
}

// on each page at the time of page load - to track users journey.
export const webengageScreenVisited = (args = '') => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageScreenVisited) {
      window.PPBridge.webengageScreenVisited(args)
      resolve()
    }
  })
}
// at successfull registration and when user changes their email - To register email
export const webengageUserEmail = (email = '') => {
  return new Promise(resolve => {
    if (window.PPBridge?.webengageUserEmail) {
      window.PPBridge.webengageUserEmail(email)
      resolve()
    }
  })
}

// this help to check of bridge available or not
export const ANDROID_BRIDGE = window.PPBridge ? {
  getReferralLink: window.PPBridge.getReferralLink && getReferralLinkAndroid,
  getReferredCode: window.PPBridge.getReferredCode && getReferralCodeAndroid,
  clearData: window.PPBridge.clearData && getClearDataAndroid,
  shareWithImage: window.PPBridge.shareWithImage && shareWithImage,
  shareImageWithMob: window.PPBridge.shareImageWithMob && shareImageWithMob,
  shareOnWhatsapp: window.PPBridge.shareOnWhatsapp && shareOnWhatsApp,
  shareImageOnWhatsapp: window.PPBridge.shareImageOnWhatsapp && shareImageOnWhatsApp,
  shareWithMob: window.PPBridge.shareWithMob && shareWithMob,
  getVersionCode: window.PPBridge.getVersionCode && getVersionCode,
  getVersionName: window.PPBridge.getVersionName && getVersionName,
  openUrlInBrowser: window.PPBridge.openUrlInBrowser && openUrlInBrowser,
  downloadFileInAndroid: window.PPBridge.downloadFile && downloadFileInAndroid,
  whiteListDomain: window.PPBridge.whiteListDomain && whiteListDomain,
  getFCMToken: window.PPBridge.getFCMToken && getFCMToken,
  getFCMPayload: window.PPBridge.getFCMPayload && getFCMPayload,
  saveFCMToken: window.PPBridge.saveFCMToken && saveFCMToken,
  webengageLogInEvent: window.PPBridge.webengageLogInEvent && webengageLogInEvent,
  webengageUserPhone: window.PPBridge.webengageUserPhone && webengageUserPhone,
  webengageUserName: window.PPBridge.webengageUserName && webengageUserName,
  webengageLogOutEvent: window.PPBridge.webengageLogOutEvent && webengageLogOutEvent,
  webengageUserSignedUp: window.PPBridge.webengageUserSignedUp && webengageUserSignedUp,
  webengageScreenVisited: window.PPBridge.webengageScreenVisited && webengageScreenVisited,
  webengageUserEmail: window.PPBridge.webengageUserSignedUp && webengageUserEmail
} : {}
