import { Component } from 'react'
import { ContentBoxWrapper } from './index.style'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import moment from 'moment'

class ContentBox extends Component {
  render () {
    const { heading, type, value, secondRowText, thirdRowText, color, tax } = this.props

    return (
      <ContentBoxWrapper color={color}>
        <div className='first-row'>
          <CustomText color={AgentColorPallette.black} size='14px' weight='800'>{heading}</CustomText>
          <CustomText color={type === 'WITHDRAWAL' ? AgentColorPallette.black : color} size='14px' weight='800'>{value}</CustomText>
        </div>
        <CustomText color={AgentColorPallette.fontDarkerGrey} top='6px'>{secondRowText}</CustomText>
        <div className='first-row'>
          <CustomText color={AgentColorPallette.fontSemiGrey} className='border-bottom' top='2px'>{moment(thirdRowText).format('DD MMM YYYY')}</CustomText>
          <CustomText color={AgentColorPallette.vibrantRed} top='2px'>{tax}</CustomText>
        </div>
      </ContentBoxWrapper>
    )
  }
}

export default ContentBox
