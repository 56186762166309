import styled from "styled-components"
// import { AgentColorPallette } from "../configs/colorPallete"

export const FormWrapper = styled.div`
  .plate-number-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plate-number-input {
    width: 64%;
    margin-bottom: 6px;
  }
  .plate-number-select {
    width: 24%;
    margin-bottom: 12px;
    background-position: 90% center;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`

export const CheckBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 25px;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  
  .disclaimer-terms {
    color: #fad200;
    font-size: 11px;
    font-weight: 700;
  }
`