import actions from './action'
import { getFromLocalStorageData } from '../../helpers/localstorage'
import { POLICY_DATA_KEY } from '../../constants/cookies'

const initState = {
  packages: {},
  regionCodes: [],
  packageDetail: [],
  uploadLink: '',
  applicationData: getFromLocalStorageData(POLICY_DATA_KEY) || {},
  step: 0,
  createPolicy: {},
  applicationDetail: {},
  applicationList: {},
  brandList: [],
  brandListStatus: '',
  modelList: [],
  variantList: [],
  variantListStatus: '',
  policyFormData: {},
  variantPrice: [],
  addonsData: {},
  savedAddonsData: {},
  payLaterData: {},
  payLaterStatus: '',
  policyPdfStatus: '',
  vehicleYear: [],
  transactionTypeStatus: '',
  uploadLinkStatus: '',
  insuranceDocsData: {},
  insuranceDocStatus: '',
  getTaxSlabData: {},
  getTaxSlabStatus: {},
  selectedPaymentTypeData: {
    id: 'agent'
  },
  createPolicyMsg: '',
  updatePolicyQuotationDiscountMsg: ''
}

export default function paymentReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_PACKAGES_SUCCESS:
    case actions.GET_PACKAGES_FAILURE:
      return {
        ...state,
        packages: action.data
      }
    case actions.GET_REGION_CODE_SUCCESS:
    case actions.GET_REGION_CODE_FAILURE:
      return {
        ...state,
        regionCodes: action.data
      }
    case actions.GET_PACKAGE_DETAIL_SUCCESS:
    case actions.GET_PACKAGE_DETAIL_FAILURE:
      return {
        ...state,
        packageDetail: action.data
      }
    case actions.GET_RENEWAL_QUOTATION_DATA_SUCCESS:
    case actions.GET_RENEWAL_QUOTATION_DATA_FAILURE:
      return {
        ...state,
        getRenewalQuotationData: action.data,
        getRenewalQuotationStatus: action.message
      }
    case actions.SUBMIT_RENEWAL_QUOTATION_DATA_SUCCESS:
    case actions.SUBMIT_RENEWAL_QUOTATION_DATA_FAILURE:
      return {
        ...state,
        submitRenewalQuotationData: action.data,
        submitRenewalQuotationStatus: action.message
      }
    case actions.GET_RENEWAL_PACKAGE_DETAIL_SUCCESS:
    case actions.GET_RENEWAL_PACKAGE_DETAIL_FAILURE:
      return {
        ...state,
        renewalPkgDetailData: action.data,
        renewalPkgDetailStatus: action.message
      }
    case actions.CREATE_POLICY_SUCCESS:
    case actions.CREATE_POLICY_FAILURE:
      return {
        ...state,
        createPolicy: action.data,
        createPolicyMsg: action.message
      }
    case actions.SUBMIT_APPLICATION_SUCCESS:
    case actions.SUBMIT_APPLICATION_FAILURE:
      return {
        ...state,
        submitApplicationData: action.data,
        submitApplicationMsg: action.message
      }
    case actions.UPDATE_POLICY_QUOTE_SUCCESS:
    case actions.UPDATE_POLICY_QUOTE_FAILURE:
      return {
        ...state,
        updatePolicyQuotationData: action.data,
        updatePolicyQuotationyMsg: action.message
      }
    case actions.UPDATE_POLICY_QUOTE_DISCOUNT_SUCCESS:
    case actions.UPDATE_POLICY_QUOTE_DISCOUNT_FAILURE:
      return {
        ...state,
        updatePolicyQuotationDiscountMsg: action.message
      }
    case actions.GET_UPLOAD_LINK_SUCCESS:
    case actions.GET_UPLOAD_LINK_FAILURE:
      return {
        ...state,
        uploadLinkStatus: action.message,
        uploadLink: { [action.data.name]: action.data.result }
      }
    case actions.SAVE_APPLICATION_DATA_REQUEST:
      return {
        ...state,
        applicationData: action.payload
      }
    case actions.GET_APPLICATION_DETAIL_SUCCESS:
    case actions.GET_APPLICATION_DETAIL_FAILURE:
      return {
        ...state,
        applicationDetail: action.data,
        applicationDetailStatus: action.message
      }
    case actions.GET_RENEWAL_POLICY_SUCCESS:
    case actions.GET_RENEWAL_POLICY_FAILURE:
      return {
        ...state,
        renewalPolicyData: action.data,
        renewalPolicyStatus: action.message
      }
    case actions.GET_WORK_SHOP_LIST_SUCCESS:
    case actions.GET_WORK_SHOP_LIST_FAILURE:
      return {
        ...state,
        workShopList: action.data,
        workShopMsg: action.message
      }
    case actions.GET_VA_DETAIL_SUCCESS:
    case actions.GET_VA_DETAIL_FAILURE:
      return {
        ...state,
        vaDetailData: action.data,
        vaDetailMsg: action.message
      }
    case actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_SUCCESS:
    case actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_FAILURE:
      return {
        ...state,
        downloadPaymentInsData: action.data,
        downloadPaymentInsMsg: action.message
      }
    case actions.UPDATE_TRANSACTION_TYPE_SUCCESS:
    case actions.UPDATE_TRANSACTION_TYPE_FAILURE:
      return {
        ...state,
        transactionTypeStatus: action.message
      }
    case actions.SAVE_POLICY_APPLICATION_STEP_REQUEST:
      return {
        ...state,
        step: action.payload
      }
    case actions.UPDATE_EMAIL_SUCCESS:
    case actions.UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        updateEmail: action.data
      }
    case actions.GET_APPLICATION_LIST_SUCCESS:
    case actions.GET_APPLICATION_LIST_FAILURE:
      return {
        ...state,
        applicationList: action.data
      }
    case actions.GET_BRAND_LIST_SUCCESS:
    case actions.GET_BRAND_LIST_FAILURE:
      return {
        ...state,
        brandList: action.data,
        brandListStatus: action.message
      }
    case actions.GET_MODEL_LIST_SUCCESS:
    case actions.GET_MODEL_LIST_FAILURE:
      return {
        ...state,
        modelList: action.data
      }
    case actions.GET_VARIANT_LIST_SUCCESS:
    case actions.GET_VARIANT_LIST_FAILURE:
      return {
        ...state,
        variantList: action.data,
        variantListStatus: action.message
      }
    case actions.GET_VEHICLE_YEAR_SUCCESS:
    case actions.GET_VEHICLE_YEAR_FAILURE:
      return {
        ...state,
        vehicleYear: action.data
      }
    case actions.GET_ADDONS_LIST_SUCCESS:
    case actions.GET_ADDONS_LIST_FAILURE:
      return {
        ...state,
        addonsData: action.data
      }
    case actions.SEND_QUOTATION_SUCCESS:
    case actions.SEND_QUOTATION_FAILURE:
      return {
        ...state,
        quotationData: action.data,
        quotationMsg: action.message
      }
    case actions.SEND_PAYMENT_LINK_SUCCESS:
    case actions.SEND_PAYMENT_LINK_FAILURE:
      return {
        ...state,
        sendPaymentLinkData: action.data,
        sendPaymentLinkMsg: action.message
      }
    case actions.AGENT_PAYING_SUCCESS:
    case actions.AGENT_PAYING_FAILURE:
      return {
        ...state,
        agentPayingData: action.data,
        agentPayingMsg: action.message
      }
    case actions.CALCULATE_ADDON_PREMIUM_SUCCESS:
    case actions.CALCULATE_ADDON_PREMIUM_FAILURE:
      return {
        ...state,
        calcAddonPremium: action.data,
        calcAddonPremiumMsg: action.message
      }
    case actions.PAY_LATER_SUCCESS:
    case actions.PAY_LATER_FAILURE:
      return {
        ...state,
        payLaterData: action.data,
        payLaterStatus: action.message
      }
    case actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST_SUCCESS:
    case actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST_FAILURE:
      return {
        ...state,
        policyDraftPdfData: action.data,
        policyDraftPdfStatus: action.message
      }
    case actions.GET_DOWNLOAD_PDF_SUCCESS:
    case actions.GET_DOWNLOAD_PDF_FAILURE:
      return {
        ...state,
        policyPdfData: action.data,
        policyPdfStatus: action.message
      }
    case actions.GET_INSURANCE_DOCS_SUCCESS:
    case actions.GET_INSURANCE_DOCS_FAILURE:
      return {
        ...state,
        insuranceDocsData: action.data,
        insuranceDocStatus: action.message
      }
    case actions.GET_INSTANT_PAYOUT_DETAIL_SUCCESS:
    case actions.GET_INSTANT_PAYOUT_DETAIL_FAILURE:
      return {
        ...state,
        instantPayoutDetails: action.data,
        instantPayoutDetailStatus: action.message
      }
    case actions.GET_TAX_SLAB_SUCCESS:
    case actions.GET_TAX_SLAB_FAILURE:
      return {
        ...state,
        getTaxSlabData: action.data,
        getTaxSlabStatus: action.message
      }
    case actions.STORE_POLICY_FORM_DATA:
      return {
        ...state,
        policyFormData: action.payload
      }
    case actions.SAVE_ADDONS_TO_STORE_REQUEST:
      return {
        ...state,
        savedAddonsData: action.payload
      }
    case actions.UPDATE_USE_BALANCE:
      return {
        ...state,
        useBalance: action.payload
      }
    case actions.UPDATE_USE_POINT:
      return {
        ...state,
        usePoint: action.payload
      }
    case actions.UPDATE_TAX_ON_BALANCE:
      return {
        ...state,
        taxOnBalance: action.payload
      }
    case actions.SELECTED_PAYMENT_TYPE_DATA:
      return {
        ...state,
        selectedPaymentTypeData: action.payload
      }
    case actions.VALIDATE_CHASSIS_NO_SUCCESS:
    case actions.VALIDATE_CHASSIS_NO_FAILURE:
      return {
        ...state,
        validateChassisNoData: action.data,
        validateChassisNoStatus: action.message
      }
    case actions.RESET_POLICY_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    case actions.RESET_POLICY_DATA:
      return {
        ...state,
        packageDetail: initState.packageDetail,
        uploadLink: initState.uploadLink,
        step: initState.step,
        packages: initState.packages,
        createPolicy: initState.createPolicy,
        applicationDetail: initState.applicationDetail,
        variantList: initState.variantList,
        variantPrice: initState.variantPrice
      }
    default:
      return state
  }
}
