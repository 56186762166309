import { Component } from 'react'
import { InnerTextareaWrapper } from './index.style'

class Textarea extends Component {
  render () {
    const {
      placeholder, backgroundColor, errorField,
      className, name, displayBorder, defaultValue,
      opacity, ...props
    } = this.props

    return (
      <InnerTextareaWrapper
        error={errorField === name}
        backgroundColor={backgroundColor}
        defaultValue={defaultValue}
        opacity={opacity}
        displayBorder={displayBorder}
        className={className}
        placeholder={placeholder || 'Masukkan sesuatu..'}
        {...props}
      />
    )
  }
}

export default Textarea
