import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import { get as lodashGet } from 'lodash/object'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import get from '../../methods/get'
import post from '../../methods/post'

export function * getCustomerPolicyDataSaga () {
  // this function returns the policy details as created or filled via agent
  yield takeEvery(actions.GET_CUSTOMER_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(get, {
        url: ConfigUrl.getCustomerPolicyData(payload),
        query: { show_agent: 1, include_insurance_details: true }
      })
      if (res) {
        yield put({
          type: actions.GET_CUSTOMER_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_CUSTOMER_POLICY_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_CUSTOMER_POLICY_FAILURE,
        message: res.data ? res.data.message : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getCustomerDraftQuoteDataSaga () {
  // this function returns the policy details as created or filled via agent
  yield takeEvery(actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(get, {
        url: ConfigUrl.customerQuoteDraftDetailsApi(payload.draftQuoteId),
        query: { show_agent: 1 }
      })
      if (res) {
        yield put({
          type: actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_FAILURE,
        message: res.data ? res.data.message : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * updateCustomerDraftQuoteSaga () {
  // this function returns the policy details as created or filled via agent
  yield takeEvery(actions.UPDATE_CUSTOMER_DRAFT_QUOTE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, {
        url: ConfigUrl.updateCustomerDraftApi(payload.draftQuoteId),
        body: payload
      })
      if (res) {
        yield put({
          type: actions.UPDATE_CUSTOMER_DRAFT_QUOTE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_CUSTOMER_DRAFT_QUOTE_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_CUSTOMER_DRAFT_QUOTE_FAILURE,
        message: res.data ? res.data.message : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * completeCustomerDraftQuoteSaga () {
  // this function returns the policy details as created or filled via agent
  yield takeEvery(actions.COMPLETE_CUSTOMER_DRAFT_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, {
        url: ConfigUrl.completeCustomerDraftApi(payload.draftQuoteId),
        body: payload
      })
      if (res) {
        yield put({
          type: actions.COMPLETE_CUSTOMER_DRAFT_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.COMPLETE_CUSTOMER_DRAFT_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.COMPLETE_CUSTOMER_DRAFT_FAILURE,
        message: res.data ? res.data.message : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * createCustomerPolicy () {
  yield takeEvery(actions.CUSTOMER_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, { body: payload, url: ConfigUrl.customerCreatePolicy })
      if (res) {
        yield put({
          type: actions.CUSTOMER_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CUSTOMER_POLICY_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.CUSTOMER_POLICY_FAILURE,
        message: getErrorMessage(res.status, lodashGet(res, 'data.error', '')),
        data: {}
      })
    }
  })
}

export function * updateCustPolicyData () {
  yield takeEvery(actions.UPDATE_CUSTOMER_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, {
        body: payload,
        url: ConfigUrl.updateCustomerPolicyData(payload.token)
      })
      if (res) {
        yield put({
          type: actions.UPDATE_CUSTOMER_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_CUSTOMER_POLICY_FAILURE,
          message: getErrorMessage(res.status, lodashGet(res, 'data.error_code', '')),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_CUSTOMER_POLICY_FAILURE,
        message: getErrorMessage(res.status, lodashGet(res, 'data.error_code', '')),
        data: {}
      })
    }
  })
}

export function * submitApplication () {
  yield takeEvery(actions.SUBMIT_CUSTOMER_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, { url: ConfigUrl.submitCustomerPolicyData(payload) })
      if (res) {
        yield put({
          type: actions.SUBMIT_CUSTOMER_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_CUSTOMER_POLICY_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SUBMIT_CUSTOMER_POLICY_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * customerPayingRequestCall () {
  yield takeEvery(actions.CUSTOMER_PAYING_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, {
        url: ConfigUrl.customerPayingApi(payload)
      })

      if (res) {
        yield put({
          type: actions.CUSTOMER_PAYING_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CUSTOMER_PAYING_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.CUSTOMER_PAYING_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * checkCustomerBlackList () {
  yield takeEvery(actions.CHEKC_BLACK_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, { body: payload, url: ConfigUrl.checkCustomerBlackList })
      if (res) {
        yield put({
          type: actions.CHEKC_BLACK_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CHEKC_BLACK_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.CHEKC_BLACK_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * validatePolicyData () {
  yield takeEvery(actions.VALIDATE_POLICY_DATA_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(post, { body: payload, url: ConfigUrl.policyDataValidationApi(payload.productCode) })
      if (res) {
        yield put({
          type: actions.VALIDATE_POLICY_DATA_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.VALIDATE_POLICY_DATA_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.VALIDATE_POLICY_DATA_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(customerPayingRequestCall),
    fork(getCustomerDraftQuoteDataSaga),
    fork(updateCustomerDraftQuoteSaga),
    fork(completeCustomerDraftQuoteSaga),
    fork(createCustomerPolicy),
    fork(getCustomerPolicyDataSaga),
    fork(submitApplication),
    fork(checkCustomerBlackList),
    fork(updateCustPolicyData),
    fork(validatePolicyData)
  ])
}
