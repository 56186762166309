export const AGENT_TYPE = {
  MARKETING_AGENT: 'MARKETING_AGENT',
  RETAIL_AGENT: 'RETAIL_AGENT'
}

export const QUOTE_CREATED_BY = {
  PIC_SUBMITTED_QUOTE: 'PIC_SUBMITTED_QUOTE',
  SELF_SUBMITTED_QUOTE: 'SELF_SUBMITTED_QUOTE'
}

export const USAGE = {
  Personal: 'Personal/Operasional Kantor',
  Commercial: 'Komersil'
}

export const PASARPOLIS_ADDRESS = {
  province_code: 'dki-jakarta',
  province_name: 'DKI JAKARTA',
  city_code: 'kota-jakarta-selatan',
  city_name: 'KOTA JAKARTA SELATAN',
  postal_code: '12710',
  region: '4',
  address: 'One Pacific Place, 10th Floor unit D-H SCBD Jl. Jend. Sudirman Kav 52-53 Jakarta Selatan 12190 '
}
export const PARTNER_CODE = {
  PROSPARK: 'prospark',
  POLICIESPANEL: 'policiespanel'
}

export const supportedVideoFormat = ['mp4']
