import { Component } from 'react'
import PropTypes from 'prop-types'
import { PdfContainerStyle } from './index.style'
import { getFileExtension } from '../../helpers/utility'
import { DisplayFlexCCC } from '../../commonStyle/index.style'
import ZoomImg from '../zoomImg'

class PdfContainer extends Component {
  static propTypes = {
    url: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    fileName: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const { className, url, width, height, children, fileName } = this.props
    const ext = getFileExtension(url)
    return (
      <PdfContainerStyle className={className}>
        {children}
        {
          ['pdf'].includes(ext) ? (
            <iframe
              className='pdf-object'
              type='application/pdf'
              src={url}
              width={width || 200}
              height={height || 250}
              aria-label='pdf'
              title='PDF'
            />
          ) : ['xlsx', 'xlx'].includes(ext) ? (
            <a
              href={url}
              download
              target='_blank'
              rel='noopener noreferrer'
            >
              {fileName}
            </a>
          ) : (
            <ZoomImg
              src={url}
              imgContainer={
                <DisplayFlexCCC className='pdf-img-container'>
                  <img src={url} alt='Certificate' />
                </DisplayFlexCCC>
              }
            />
          )
        }
      </PdfContainerStyle>
    )
  }
}

export default PdfContainer
