import { KTP_ACTIONS } from '../../../containers/Private/VerifyKTPNPWP/state/actions'
import { UPLOAD_ACTIONS } from './actions'

export const INITIAL_STATE = {

}

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload = {} } = action
  switch (type) {
    case UPLOAD_ACTIONS.GET_UPLOAD_LINK:
    case UPLOAD_ACTIONS.RESET_UPLOAD_FILE_STATE:
    case KTP_ACTIONS.UPLOAD_DOC:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}

export default reducer
