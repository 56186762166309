import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './action'
import authGetApi from '../../methods/authGet'
import postAuthApi from '../../methods/authPost'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import { history } from '../../dashApp'

export function * getAgentDetails () {
  yield takeEvery(actions.GET_AGENT_DETAILS_REQUEST, function * ({ payload = {} }) {
    try {
      let res = {}
      if (!payload.isLoggedIn && (Object.keys(payload).length === 0)) {
        res = yield call(authGetApi, { url: ConfigUrl.getAgentDetails })
      } else {
        res.data = payload.data
      }
      if (res.data || payload.isLoggedIn) {
        yield put({
          type: actions.GET_AGENT_DETAILS_SUCCESS,
          data: res.data,
          message: 'success'
        })
        if (res.data && res.data.questions_enable) {
          // if questions enable then redirect the agent to
          // questionnaire and then decide the steps
          history.replace('/review/1/questions')
        }
      } else {
        yield put({
          type: actions.GET_AGENT_DETAILS_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_AGENT_DETAILS_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getAgentBadgesSaga () {
  yield takeEvery(actions.GET_AGENT_BADGES_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGetApi, { query: payload, url: ConfigUrl.getAgentBadgesApi })
      if (res) {
        yield put({
          type: actions.GET_AGENT_BADGES_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_AGENT_BADGES_FAILURE,
          message: getErrorMessage(res.status),
          statusCode: res.status,
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_AGENT_BADGES_FAILURE,
        message: getErrorMessage(res.status),
        statusCode: res.status,
        data: {}
      })
    }
  })
}

export function * getAgentPayLaterCountSaga () {
  yield takeEvery(actions.GET_AGENT_PAYLATER_COUNT_REQUEST, function * () {
    try {
      const res = yield call(authGetApi, { url: ConfigUrl.getAgentPayLaterCountApi })
      if (res) {
        yield put({
          type: actions.GET_AGENT_PAYLATER_COUNT_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_AGENT_PAYLATER_COUNT_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_AGENT_PAYLATER_COUNT_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getDownlineSagas () {
  yield takeEvery(actions.GET_DOWNLINE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGetApi, {
        query: payload,
        url: ConfigUrl.downlineApi
      })
      if (res) {
        yield put({
          type: actions.GET_DOWNLINE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_DOWNLINE_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_DOWNLINE_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getDownlineChildSagas () {
  yield takeEvery(actions.GET_DOWNLINE_CHILD_REQUEST, function * ({ payload }) {
    const level = payload.level
    delete payload.level
    try {
      const res = yield call(authGetApi, {
        query: payload,
        url: ConfigUrl.downlineChildApi
      })
      if (res) {
        yield put({
          type: actions.GET_DOWNLINE_CHILD_SUCCESS,
          data: res.data,
          downLineLevel: level,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_DOWNLINE_CHILD_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_DOWNLINE_CHILD_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getAggregatorDownlineSagas () {
  yield takeEvery(actions.GET_AGGREGATOR_DOWNLINE_REQUEST, function * ({ payload = {} }) {
    const isFilterApply = payload.isFilterApply
    delete payload.isFilterApply
    try {
      const res = yield call(authGetApi, {
        query: payload,
        url: ConfigUrl.aggregatorDownlineApi
      })
      if (res) {
        yield put({
          type: actions.GET_AGGREGATOR_DOWNLINE_SUCCESS,
          data: res.data,
          isFilterApply,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_AGGREGATOR_DOWNLINE_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_AGGREGATOR_DOWNLINE_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getAggregatorDownlineBusinessSagas () {
  yield takeEvery(actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGetApi, {
        query: payload,
        url: ConfigUrl.aggregatorDownlineBusinessApi
      })
      if (res) {
        yield put({
          type: actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getStaffNameList () {
  yield takeEvery(actions.GET_STAFF_NAME_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGetApi, {
        query: payload,
        url: ConfigUrl.getStaffNameListApi
      })
      if (res) {
        yield put({
          type: actions.GET_STAFF_NAME_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_STAFF_NAME_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_STAFF_NAME_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getAgentNotifications () {
  yield takeEvery(actions.GET_NOTIFICATION_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authGetApi, { params: payload, url: ConfigUrl.getNotifications })
      if (res) {
        yield put({
          type: actions.GET_NOTIFICATION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_NOTIFICATION_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_NOTIFICATION_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * updateReadNotifications () {
  yield takeEvery(actions.UPDATE_READ_NOTIFICATION_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: payload, url: ConfigUrl.updateReadNotification })
      if (res) {
        yield put({
          type: actions.UPDATE_READ_NOTIFICATION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_READ_NOTIFICATION_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_READ_NOTIFICATION_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * sendSmsToCustomer () {
  yield takeEvery(actions.SEND_SMS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: payload, url: ConfigUrl.sendSmsToCustomer })
      if (res) {
        yield put({
          type: actions.SEND_SMS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SEND_SMS_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.SEND_SMS_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getProductList () {
  yield takeEvery(actions.GET_PRODUCT_LIST_REQUEST, function * () {
    try {
      const res = yield call(authGetApi, { url: ConfigUrl.getProduct })
      if (res) {
        yield put({
          type: actions.GET_PRODUCT_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PRODUCT_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PRODUCT_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getAgentDetails),
    fork(getAgentBadgesSaga),
    fork(getAgentPayLaterCountSaga),
    fork(getDownlineSagas),
    fork(getDownlineChildSagas),
    fork(getAggregatorDownlineSagas),
    fork(getAggregatorDownlineBusinessSagas),
    fork(getStaffNameList),
    fork(sendSmsToCustomer),
    fork(getAgentNotifications),
    fork(updateReadNotifications),
    fork(getProductList)
  ])
}
