function lazyPromise (fn, retriesLeft = 3, interval = 500) {
  // we have to make 3 retries and 500ms wait time
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          lazyPromise(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export default lazyPromise
