import styled from 'styled-components'

export const AgentPICSheetStyle = styled.div`
  .help-agent-header {
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
    padding: 10px;
  }

  .help-title {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    grid-auto-rows: 30px;
    text-align: center;
  }

  .close-icon {
    grid-row: 1 / 2;
    grid-column: 2;
    z-index: 2;

    img {
      width: 24px;
    }
  }

  .agent-pic-container {
    background-color: ${props => props.theme.white};
    padding: 1em 0;
  }

  .rm-icon-circle {
    border: 1px solid ${props => props.theme.borderGrey};
    img {
      height: 24px;
    }
  }

  .rm-contact-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5%;
    padding: 0 16px;
  }

  .contact-icon {
    width: 32px;
  }

  .customer-support-box {    
    padding-bottom: 6px;
  }

  .customer-section {
    margin-top: 5px;
    padding: 1em;
    background-color: ${props => props.theme.white};
  }

  .line {
    flex: 1;
    margin: 0 8px;
    align-self: flex-end;
    border-bottom: 1px solid ${props => props.theme.Gray90};
  }

  .customer-helpline {
    padding: 0.6em;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.Gray90};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5%;
  }

  .animation-show {
    max-height: 100px;
  }
`
