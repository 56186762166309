import { UPLOAD_GREY } from '../../../../images'
import { LANG_STRINGS } from '../../../../lang'
import { Row } from '../../../../commonStyle/index.style'
import CircularLoader from '../../../CircularLoader'
import { UploadFileButtonStyle } from './index.style'
import { useCallback, useEffect, useRef, useState } from 'react'
import ShowSnackbar from '../../../ShowNotification'
import { useUploadFile } from '../../state/hooks/useUploadFileState'
import { useLoaderContextValue } from '@pasarpolis/common-react-hooks'
import PdfContainer from '../../../PdfContainer'
import { getFileExtension, getFileNameFromUrl } from '../../../../helpers/utility'
import { useUploadFileContextValue } from '../../context'
import { resetUploadFileState } from '../../state/actions'

function UploadFileButtonContainer ({
  name, fileType, width, errorField, className,
  displayBorder, onUpload, height, hideUpload, defaultValue
}) {
  const [{ GET_UPLOAD_LINK_LOADER }] = useLoaderContextValue()
  const [, dispatchUploadFile] = useUploadFileContextValue()
  const [{ getUploadLinkData }, uploadFileRequest] = useUploadFile()
  const [uploadLink, setUploadLink] = useState(defaultValue || '')
  const that = useRef({
    currentFileUploadName: '',
    fileUploadedName: defaultValue && getFileNameFromUrl(defaultValue)
  })

  useEffect(() => {
    if (getUploadLinkData?.url && (that.current.currentFileUploadName === name)) {
      that.current.currentFileUploadName = ''
      dispatchUploadFile(resetUploadFileState({
        getUploadLinkData: undefined
      }))
      onUpload(getUploadLinkData?.url, name)
      setUploadLink(getUploadLinkData?.url)
    }
  }, [dispatchUploadFile, name, getUploadLinkData, onUpload])

  const handleUpload = useCallback((e) => {
    const file = document.getElementById(name)

    if ('files' in file) {
      if (file.files[0]) {
        that.current.currentFileUploadName = name
        if (file.files[0].size > 11000000) {
          ShowSnackbar.error({
            visible: true,
            text: 'Ukuran file terlalu besar'
          })
        } else {
          ShowSnackbar.success({
            visible: true,
            text: 'Pengunggahan dimulai'
          })
          const formData = new FormData()
          formData.append('file', file.files[0])
          that.current.fileUploadedName = file.files[0].name
          uploadFileRequest({
            body: formData
          })
        }
      }
    }
  }, [name, uploadFileRequest])

  const getImageContainer = useCallback((imgUrl) => {
    const ext = getFileExtension(imgUrl)
    if (ext === 'pdf') {
      return (
        <PdfContainer url={imgUrl} fileName={that.current.fileUploadedName}>
          {
            !hideUpload && (
              <Row
                align='center'
                justify='center'
                className='upload-change-btn'
              >
                <span>{LANG_STRINGS.change}</span>&nbsp;&nbsp;
                <input
                  type='file'
                  className='upload-file-input'
                  id={name}
                  name={name}
                  onChange={handleUpload}
                  accept={fileType || 'image/*'}
                />
                <i className='fas fa-pen' />
              </Row>
            )
          }
        </PdfContainer>
      )
    }
    return (
      <div
        className={`image-display ${width && 'small-img-container'}`}
        style={{
          backgroundImage: `url(${imgUrl})`,
          marginBottom: 8,
          height: (height || '145px'),
          width: (width || '100%')
        }}
      >
        <Row
          align='center'
          justify='center'
          className='upload-change-btn'
        >
          <span>{LANG_STRINGS.change}</span>&nbsp;&nbsp;
          <input
            type='file'
            className='upload-file-input'
            id={name}
            name={name}
            onChange={handleUpload}
            accept={fileType || 'image/*'}
          />
          <i className='fas fa-pen' />
        </Row>
      </div>
    )
  }, [handleUpload, width, fileType, name, height, hideUpload])

  return (
    <UploadFileButtonStyle
      width={width}
      error={errorField === name}
      className={className}
      displayBorder={displayBorder}
    >
      <CircularLoader
        isLoading={GET_UPLOAD_LINK_LOADER && (name === that.current.currentFileUploadName)}
      >
        {
          uploadLink && (!['xlsx'].includes(getFileExtension(uploadLink))) ? getImageContainer(uploadLink) : (
            <Row align='center' justify='space-between' className='upload-file-btn-box'>
              <span className='upload-btn-text'>
                {that.current.fileUploadedName ? (
                  <a href={uploadLink} download target='_blank' rel='noopener noreferrer'>
                    {that.current.fileUploadedName}
                  </a>
                ) : LANG_STRINGS.upload}
              </span>
              <img src={UPLOAD_GREY} alt='UPLOAD_GREY' />
              <input
                type='file'
                id={name}
                name={name}
                onChange={handleUpload}
                className='upload-file-input'
                accept={fileType || 'image/*'}
              />
            </Row>
          )
        }
      </CircularLoader>
      <ShowSnackbar />
    </UploadFileButtonStyle>
  )
}

export default UploadFileButtonContainer
