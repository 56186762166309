export const isRemovedPath = (url = '') => {
  // here we are checking the removed paths
  const paths = [
    'covid'
  ]
  let isValid = true
  for (const key of paths) {
    if (url.includes(key)) {
      isValid = false
    }
  }

  return isValid
}
