import moment from 'moment'
import { get } from 'lodash/object'
import {
  HEALTH_FULL_PROTECTION, PROPERTY_PROTECTION,
  PROPERTY_PROTECTION_BASIC, OFFLINE_POLICY_PURCHASE_TYPE
} from '../constants/pkgCode'

export const getPackagesReqBody = (applicationData = {}, category = false) => ({
  purchase_type: applicationData.purchase_type,
  package_type: applicationData.package_type,
  vehicle_type: applicationData.vehicle_type,
  brand: applicationData.brand,
  variant: applicationData.variant,
  variant_code: applicationData.variant_code,
  additional_sum_insured: applicationData.additional_sum_insured,
  year: applicationData.year,
  model: applicationData.model,
  region_code: applicationData.region_code,
  vehicle_sum_insured: applicationData.vehicle_sum_insured,
  vehicle_plate_no: applicationData.vehicle_plate_no,
  usage: applicationData.usage,
  start_date: applicationData.start_date,
  accessories: applicationData.accessories,
  ...(category ? { category_name: applicationData.category_name } : {})
})

export const getExtraVehicleData = (policyData) => {
  return {
    chasis_number: policyData.chasis_number,
    start_date: policyData.start_date,
    images: policyData.images,
    color: policyData.color,
    machine_number: policyData.machine_number,
    customer_data: policyData.customer_data,
    ktp_no: policyData.ktp_no,
    ktp_url: policyData.ktp_url,
    stnk_url: policyData.stnk_url,
    hard_copy_address: policyData.hard_copy_address,
    hard_copy_enabled: policyData.hard_copy_enabled,
    hard_copy_recipient: policyData.hard_copy_recipient,
    hard_copy_recipient_mobile: policyData.hard_copy_recipient_mobile,
    hard_copy_required: policyData.hard_copy_required,
    hard_copy_sent_to: policyData.hard_copy_sent_to,
    hard_copy_sent_to_name: policyData.hard_copy_sent_to_name,
    transmission_type: policyData.transmission_type
  }
}

export const getAddonsMapBody = (data) => {
  const obj = {
    add_on: [],
    no_of_pax: 0,
    driver_sum_insured: 0,
    passenger_sum_insured_pax: 0
  }

  for (const key in data) {
    const item = data[key]
    if (item.add_on_code && !item.group_of_addons) {
      obj.add_on.push(item.add_on_code)
      if (item.add_on_code === 'passenger-insurance' && item.premium) {
        obj.passenger_sum_insured_pax = parseInt((item.addonOnPremium || item.limit), 10) + obj.passenger_sum_insured_pax
        obj.no_of_pax = item.no_passanger + obj.no_of_pax
      } else if (item.add_on_code === 'driver-insurance') {
        obj.driver_sum_insured = parseInt((item.addonOnPremium || item.limit), 10) + obj.driver_sum_insured
      } else {
        const dataKey = `${item.add_on_code.replace(/-/g, '_')}_sum_insured`
        const amount = item.show_drop_down ? (item.addonOnPremium || item.limit) : item.premium
        obj[dataKey] = parseInt(amount, 10)
      }
    }
  }

  if (!obj.no_of_pax) {
    delete obj.no_of_pax
  }

  if (!obj.passenger_sum_insured_pax) {
    delete obj.passenger_sum_insured_pax
  }

  if (!obj.driver_sum_insured) {
    delete obj.driver_sum_insured
  }

  return obj
}

export const getAddonsMapFromCategory = (addonCategoryData) => {
  let addonsMapped = {}
  for (const key in addonCategoryData) {
    const addons = addonCategoryData[key]
    addonsMapped = {
      ...addonsMapped,
      ...addons
    }
  }
  return getAddonsMapBody(addonsMapped)
}

export const getCreatePolicyQuotationBdy = ({
  policyData = {}, packageData = {}, discount = 0
}) => {
  return {
    ...policyData,
    code: packageData.code,
    commission: packageData.commission,
    premium: {
      amount: packageData.total_bill,
      period: 12
    },
    package_type: policyData.package_type,
    vehicle_type: policyData.vehicle_type,
    brand: policyData.brand,
    admin_fee: policyData.admin_fee,
    hard_copy_enabled: packageData.hard_copy_enabled,
    model: policyData.model,
    variant: policyData.variant,
    variant_code: policyData.variant_code,
    year: policyData.year,
    region_code: policyData.region_code,
    accessories: policyData.accessories,
    additional_sum_insured: policyData.additional_sum_insured,
    name: packageData.name,
    commission_percent: packageData.commission_percent,
    points_percent: packageData.points_percent,
    points: packageData.points,
    sum_insured: policyData.additional_sum_insured,
    vehicle_plate_no: policyData.vehicle_plate_no ? `${policyData.vehicle_plate_no}`.replace(/ /g, '') : undefined,
    discount: discount,
    add_on: policyData.add_on,
    no_of_pax: policyData.no_of_pax,
    driver_sum_insured: policyData.driver_sum_insured,
    passenger_sum_insured_pax: policyData.passenger_sum_insured_pax,
    photo_required: (policyData.photo_required === undefined) ? packageData.photo_required : policyData.photo_required
  }
}

export const getPolicyDataForUpdateQuotation = ({
  policyDetail,
  customerData,
  product
}) => {
  return {
    ...policyDetail,
    code: product.code,
    customer_data: customerData
  }
}

export function getDobFmt (dob) {
  return moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD')
}

export function prefixCountryCode (mobile) {
  return `62-${mobile.substr(1)}`
}

export const getCovidPolicyCreationMap = (data, code) => {
  return {
    customer_data: {
      name: data.name,
      email: data.email,
      mobile_no: `${data.mobile_no}`.startsWith('0') ? prefixCountryCode(data.mobile_no) : `62-${data.mobile_no}`
    },
    dob: getDobFmt(data.dob),
    address: data.address,
    gender: data.gender,
    code: code,
    profession: data.profession,
    ktp_no: data.ktp
  }
}

const GET_GENDER_RELATION = {
  M: 'husband',
  F: 'wife'
}

const BENEFICIARY_GENDER_RELATION = {
  F: 'husband',
  M: 'wife'
}

function getInsurePersonMap (insuredMembers) {
  const insured = []

  for (let key in insuredMembers) {
    const member = insuredMembers[key]
    const { insuredData, beneficiaryData, sequence } = member
    if (sequence) {
      key = key.replace(`_${sequence}`, '')
    }
    const insuredDetails = {
      name: get(insuredData, 'name'),
      dob: getDobFmt(get(insuredData, 'dob')),
      gender: get(insuredData, 'gender'),
      relation: key === 'husband/wife' ? GET_GENDER_RELATION[get(insuredData, 'gender')] : key
    }
    const beneficiaryDetails = {
      name: get(beneficiaryData, 'name'),
      dob: getDobFmt(get(beneficiaryData, 'dob')),
      relation: get(beneficiaryData, 'relationshipWithInsured') === 'husband/wife'
        ? BENEFICIARY_GENDER_RELATION[get(insuredData, 'gender')]
        : get(beneficiaryData, 'relationshipWithInsured')
    }
    insured.push({
      insured_details: insuredDetails,
      beneficiary_details: beneficiaryDetails,
      sequence
    })
  }

  return insured
}

export const getCovidPolicyUpdateMap = (insuredData, policyHolder, code) => {
  // 1993-10-10  moment(data.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  return {
    reference_id: '',
    agent_code: '',
    agent_ref: '',
    ...getCovidPolicyCreationMap(policyHolder, code),
    insured: getInsurePersonMap(insuredData)
  }
}

export function getDobInCalendarView (date) {
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
}

export const mapInsuredMemberData = (data = []) => {
  if (!data.length) return {}
  let mapInsured = {}

  data.forEach((insured, index) => {
    const insuredData = {
      ...(insured.insured_details),
      dob: getDobInCalendarView(insured.insured_details.dob)
    }
    const beneficiaryData = {
      ...(insured.beneficiary_details),
      dob: getDobInCalendarView(insured.beneficiary_details.dob),
      relationshipWithInsured: ['wife', 'husband'].includes(insured.beneficiary_details.relation) ? 'husband/wife' : insured.beneficiary_details.relation
    }
    let type = ['wife', 'husband'].includes(insured.insured_details.relation) ? 'husband/wife' : insured.insured_details.relation
    const sequence = insured.sequence
    if (sequence) {
      type = `${type}_${sequence}`
    }
    mapInsured = {
      ...mapInsured,
      [type]: {
        insuredData,
        beneficiaryData,
        sequence
      }
    }
  })
  return mapInsured
}

export const getSharingMsgForProduct = (productCode) => {
  switch (productCode) {
    case HEALTH_FULL_PROTECTION:
      return 'Terima kasih telah melakukan transaksi Asuransi Kesehatan di PasarPolis Mitra. Untuk melanjutkan pembayaran, klik'
    case PROPERTY_PROTECTION:
    case PROPERTY_PROTECTION_BASIC:
      return 'Terima kasih telah melakukan transaksi Asuransi Properti di PasarPolis Mitra. Untuk melanjutkan pembayaran, klik'
    default:
      return 'Terima kasih telah melakukan transaksi Asuransi Kendaraan di PasarPolis Mitra. Untuk melanjutkan pembayaran, klik'
  }
}

export const getRenewalPolicyMap = (policy) => {
  return {
    ...policy.policy_detail
  }
}

export const getCreateOfflineQuoteBody = (data) => {
  if (!Object.keys(data).length) return {}
  const pkgType = (data.package_type || data.packageType)
  return {
    product_key: data.productCode || 'car-protection',
    code: `offline-${pkgType || 'car-protection-comprehensive'}`,
    purchase_type: data.purchaseType || OFFLINE_POLICY_PURCHASE_TYPE[data.protection_type?.code],
    package_type: pkgType,
    vehicle_type: data.transportationType,
    brand: data.brand,
    brand_data: data.brand_data,
    brand_other: data.brand_data?.brand_other,
    variant: data.variant_name,
    variant_name: data.variant_name,
    variant_code: data.variant_code,
    variant_other: data.model_other,
    model_other: data.model_other,
    variant_type: data.variant_type,
    additional_sum_insured: data.totalSumInsured,
    year: data.year,
    model: data.model,
    model_code: data.model_code,
    model_name: data.model_name,
    region_code: data.region_code,
    vehicle_sum_insured: data.vehicle_sum_insured,
    vehicle_plate_no: data.vehicle_plate_no ? `${data.vehicle_plate_no}`.replace(/ /g, '') : undefined,
    usage: data.usage || data.vehicleUse,
    coverage_period: data.policyPeriod?.selectedTimePeriod,
    accessories: data.accessories,
    addons: data.addons,
    ...getAddonsMapFromCategory(data.addons),
    discount: data.discount,
    protection_type: data.protection_type,
    protection_case: data.protection_case,
    total_gwp_ojk_rate: data.total_gwp_ojk_rate,
    custom_add_on: data.custom_add_on,
    total_bill: data.total_gwp_ojk_rate,
    coverage_period_start: getDobFmt(data.policyPeriod?.startDate),
    coverage_period_end: getDobFmt(data.policyPeriod?.endDate),
    additional_info: data.additionalInfo || data.additional_info,
    customer_name: data.customer_name || data.name,
    mobile_no: data.customer_phone_no,
    email: data.customer_email,
    customer_phone_no: data.customer_phone_no,
    customer_email: data.customer_email,
    customer_address: {
      ...data.customer_address
    },
    loss_ratio: data.loss_ratio,
    chasis_number: data.chasis_number,
    machine_number: data.machine_number,
    transmission_type: data.transmission_type?.value || data.transmission_type,
    appointment_letter_url: data.appointment_letter_url,
    previous_certificate_url: data.previous_certificate_url,
    color: data.color,
    stnk_url: data.stnk_url || data.stank_url,
    other_document_url: data.other_document_url,
    hard_copy_recipient: data.hard_copy_recipient,
    premium_calculation_note: data.premium_calculation_note,
    hard_copy_recipient_mobile: data.hard_copy_recipient_mobile,
    hard_copy_address: data.hard_copy_address,
    hard_copy_sent_to: data.hard_copy_sent_to,
    ktp_no: data.ktp_no,
    ktp_url: data.ktp_url || data.ktp,
    npwp_url: data.npwp_url,
    hard_copy_required: data.hard_copy_required,
    images: data.images,
    vehicle_photos: data.vehicle_photos,
    prov_maps: data?.providerData && Object.values(data?.providerData),
    currency_label: data?.currency_label,
    exchange_rate: data?.exchange_rate,
    currency_symbol: data?.currency_symbol,
    exchange_rate_from: data?.exchange_rate_from,
    isCoinsurance: data?.isCoinsurance
  }
}

export const getProviderMap = (providers = []) => {
  const map = {}
  if (!providers.length) return map

  providers.forEach((provider) => {
    map[provider.code] = provider
  })
  return map
}

export const mapOfflineCasesDataForStepOne = (data = {}) => {
  return {
    ...data,
    protectionType: (data.protection_type || data.protectionType),
    packageType: (data.packageType || data.package_type),
    providerData: getProviderMap(data.prov_maps),
    transportationType: data.vehicle_type,
    vehicleUse: data.usage,
    policyPeriod: {
      selectedTimePeriod: data.coverage_period,
      endDate: getDobInCalendarView(data.coverage_period_end),
      startDate: getDobInCalendarView(data.coverage_period_start)
    },
    totalSumInsured: data.additional_sum_insured
  }
}

export const getOfflineStepOneReBody = (data = {}) => {
  return {
    ...data,
    prov_maps: data?.providerData && Object.values(data?.providerData),
    custom_add_on: data.custom_add_on,
    addons: data.addons,
    ...getAddonsMapFromCategory(data.addons),
    discount: data.discount,
    coverage_period_start: getDobFmt(data.policyPeriod?.startDate),
    coverage_period: data.policyPeriod?.selectedTimePeriod,
    product_key: data.productCode || 'car-protection',
    purchase_type: data.purchaseType,
    package_type: data.packageType,
    vehicle_type: data.transportationType,
    brand: data.brand,
    brand_data: data.brand_data,
    brand_other: data.brand_other,
    variant: data.variant_name,
    variant_name: data.variant_name,
    variant_code: data.variant_code,
    variant_other: data.variant_other,
    variant_type: data.variant_type,
    additional_sum_insured: data.totalSumInsured,
    year: data.year,
    model: data.model,
    model_code: data.model_code,
    model_name: data.model_name,
    region_code: data.region_code,
    vehicle_sum_insured: data.vehicle_sum_insured,
    vehicle_plate_no: data.vehicle_plate_no ? `${data.vehicle_plate_no}`.replace(/ /g, '') : undefined,
    usage: data.usage || data.vehicleUse,
    accessories: data.accessories,
    coverage_period_end: getDobFmt(data.policyPeriod?.endDate)
  }
}

export const getOfflineAdditionInfoReqBody = (data = {}) => {
  return {
    customer_phone_no: data.customer_phone_no,
    customer_email: data.customer_email,
    additional_info: data.additional_info,
    accessories: data.accessories,
    chasis_number: data.chasis_number,
    machine_number: data.machine_number,
    transmission_type: data.transmission_type?.value || data.transmission_type,
    appointment_letter_url: data.appointment_letter_url,
    other_document_url: data.other_document_url,
    images: data.images,
    color: data.color,
    vehicle_photos: data.vehicle_photos
  }
}

export const getOfflineStepTwoReqBody = (data = {}) => {
  return {
    hard_copy_recipient_mobile: data.hard_copy_recipient_mobile,
    hard_copy_address: data.hard_copy_address,
    hard_copy_sent_to: data.hard_copy_sent_to,
    ktp_no: data.ktp_no,
    ktp_url: data.ktp_url || data.ktp,
    npwp_url: data.npwp_url,
    hard_copy_required: data.hard_copy_required,
    images: data.images,
    vehicle_photos: data.vehicle_photos,
    chasis_number: data.chasis_number,
    machine_number: data.machine_number,
    transmission_type: data.transmission_type?.value,
    appointment_letter_url: data.appointment_letter_url,
    previous_certificate_url: data.previous_certificate_url,
    color: data.color,
    stnk_url: data.stnk_url || data.stank_url,
    other_document_url: data.other_document_url,
    hard_copy_recipient: data.hard_copy_recipient,
    customer_phone_no: data.customer_phone_no || data.mobile_no,
    customer_email: data.customer_email,
    customer_address: {
      ...data.customer_address
    },
    additional_info: data.additionalInfo
  }
}
