import { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, CustomText, Button } from '../../components'
import { LANG_STRINGS } from '../../lang'
import { ConfirmationStyle } from './index.style'
import { AgentColorPallette } from '../../configs/colorPallete'
import { Row } from '../../commonStyle/index.style'

class ConfirmationModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool,
    confirmText: PropTypes.string,
    modelTitle: PropTypes.string,
    agreeText: PropTypes.string,
    cancelText: PropTypes.string,
    hideClose: PropTypes.bool,
    onAgree: PropTypes.func,
    onClose: PropTypes.func
  }

  handleAgree = () => {
    this.props.onAgree()
  }

  render () {
    const {
      showModal, onClose, confirmText,
      modelTitle, agreeText, hideClose, cancelText
    } = this.props
    return (
      <Modal
        heading={modelTitle || LANG_STRINGS.information}
        isOpen={showModal}
        onClose={onClose}
      >
        <ConfirmationStyle>
          <CustomText
            color={AgentColorPallette.black}
            size='14px'
            lineHeight={1.5}
            bottom='20px'
          >
            {confirmText}
          </CustomText>
          <Row align='center' justify='space-between'>
            {
              !hideClose && (
                <Button
                  width='45%'
                  backgroundColor={AgentColorPallette.blue}
                  color={AgentColorPallette.white}
                  onClick={onClose}
                >
                  {cancelText || LANG_STRINGS.cancel}
                </Button>
              )
            }
            <Button width={!hideClose ? '45%' : '100%'} onClick={this.handleAgree}>
              {agreeText || LANG_STRINGS.yesIAgree}
            </Button>
          </Row>
        </ConfirmationStyle>
      </Modal>
    )
  }
}

export default ConfirmationModal
