import { Component } from 'react'
import { InputWrapper } from './index.style'

class Input extends Component {
  render () {
    const {
      type, placeholder, backgroundColor,
      errorField, name, opacity, autoFocus,
      inputmode, value, ...props
    } = this.props
    return (
      <InputWrapper
        error={errorField === name}
        backgroundColor={backgroundColor}
        opacity={opacity}
        name={name}
        type={type}
        value={value}
        inputmode={inputmode}
        autoFocus={autoFocus}
        placeholder={placeholder || 'Masukkan sesuatu..'}
        {...props}
      />
    )
  }
}

export default Input
