import { lazy, Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { ContainerWrapper } from './index.style'
import Header from '../Header'
import Loader from '../Loader'
import { isWebView } from '../../helpers/utility'
import CustomText from '../CustomText'
import { LANG_STRINGS } from '../../lang'
import { AgentColorPallette } from '../../configs/colorPallete'
import HeaderGreetings from '../Header/components/HeaderGreetings'
import AgentPICSheet from '../AgentPICSheet'
import { GlobalContext } from '../../contextProvider/GlobalContext'
import { showAgentPICInfo } from '../../state/globalState/actions'
import { OJK_PP_BLACK } from '../../images'

const PasarPolisMitraHeader = lazy(() => import('../Header/components/PasaPolisMitraHeader'))
const CustomerHeader = lazy(() => import('../CustomerHeader'))
const InnerHeader = lazy(() => import('../InnerHeader'))
const BottomTabs = lazy(() => import('../BottomTabs'))
const AddHomeScreen = lazy(() => import('../AddToHomeScreen'))

class Container extends Component {
  state = {
    isWebView: isWebView(),
    isShowLoader: false
  }

  showHideLoader = (showHide = true) => {
    this.setState({ isShowLoader: showHide })
  }

  handleClosePIC = () => {
    const [, dispatchGlobalStore] = this.context
    dispatchGlobalStore(showAgentPICInfo())
  }

  renderHeader () {
    const {
      title, loggedIn, customer, showNotificationIcon, bgColor, showInnerHeader,
      hideBackButton, displayBackButton, mitraHeader, showFaq, showCustomerFaq,
      showRightFaq, headerPosition, onBackClick, showSideMenuIcon, sideMenuItems, titleColor,
      backBtnColor, showAddIcon, onAddIconClick, showSearchBar, onSearch, searchDescription,
      handleShowSearchDesc, backBtnId, closeIcon, isBorderBtm, onClose, typeHeader,
      agentDetails, showCloseIcon, showSaveIcon, onSaveClick, disabledSave = false,
      saveLoader, showDeleteIcon, onDeleteClick, searchPlaceHolder,
      hideNotification, hideHelp, showInfoIcon, onInfo
    } = this.props
    if (customer) {
      return <CustomerHeader />
    } else if (mitraHeader) {
      return (
        <PasarPolisMitraHeader
          closeIcon={closeIcon}
          isBorderBtm={isBorderBtm}
          title={title}
          onClose={onClose}
          backgroundColor={bgColor}
        />
      )
    } else if (title) {
      if (loggedIn || showInnerHeader) {
        return (
          <InnerHeader
            showFaq={showFaq}
            headerPosition={headerPosition}
            showRightFaq={showRightFaq}
            heading={title}
            showCustomerFaq={showCustomerFaq}
            hideBackButton={hideBackButton}
            displayBackButton={displayBackButton}
            onBackClick={onBackClick}
            showNotificationIcon={showNotificationIcon}
            showDeleteIcon={showDeleteIcon}
            showSideMenuIcon={showSideMenuIcon}
            showAddIcon={showAddIcon}
            onAddIconClick={onAddIconClick}
            sideMenuItems={sideMenuItems}
            showSearchBar={showSearchBar}
            searchDescription={searchDescription}
            handleShowSearchDesc={handleShowSearchDesc}
            onSearch={onSearch}
            searchPlaceHolder={searchPlaceHolder}
            backBtnId={backBtnId}
            showCloseIcon={showCloseIcon}
            showSaveIcon={showSaveIcon}
            showInfoIcon={showInfoIcon}
            onInfo={onInfo}
            onSaveClick={onSaveClick}
            disabledSave={disabledSave}
            onClose={onClose}
            backgroundColor={bgColor}
            saveLoader={saveLoader}
            onDeleteClick={onDeleteClick}
          />
        )
      } else {
        return (
          <Header
            backBtnColor={backBtnColor}
            titleColor={titleColor}
            heading={title}
            backBtnId={backBtnId}
            backgroundColor={bgColor}
            headerPosition={headerPosition}
          />
        )
      }
    } else if (typeHeader === 'greeting') {
      return (
        <HeaderGreetings
          user={agentDetails}
          hideHelp={hideHelp}
          hideNotification={hideNotification}
        />
      )
    }
  }

  render () {
    const {
      children, loader, loggedIn, customer, hideBottomTabs,
      agentDetails, activeTab, className, headerPosition,
      isGuardianAgentLogin = false, hideHeader, typeHeader,
      hideGuardianInfo = false, showOJKText
    } = this.props
    const { isShowLoader } = this.state
    const [{ showAgentPIC }] = this.context
    return (
      <ContainerWrapper className={className} isGreetingHeader={typeHeader === 'greeting'}>
        <Loader visible={!!loader || isShowLoader}>
          <Suspense fallback={<Loader visible />}>
            {!hideHeader && this.renderHeader()}
            {
              isGuardianAgentLogin && !hideGuardianInfo &&
                <>
                  <CustomText
                    size='11px'
                    color={AgentColorPallette.white}
                    className='header-info'
                  >
                    {LANG_STRINGS.guardianAgentMessage}
                  </CustomText>
                  <div className='header-info-balancer' />
                </>
            }
          </Suspense>
          {children}
          {
            showOJKText &&
              <div className='footer-container'>
                <CustomText weight='600' size='7px' textAlign='center' color='#000000'>
                  {LANG_STRINGS.OjkText}
                </CustomText>
                <img src={OJK_PP_BLACK} className='ojk-logo' alt='logo' />
              </div>
          }
          {
            !customer && loggedIn && !hideBottomTabs && (
              <Suspense fallback={<Loader visible />}>
                <BottomTabs showHideLoader={this.showHideLoader} activeTab={activeTab} tabPosition={headerPosition} />
              </Suspense>
            )
          }
          {
            loggedIn && !this.state.isWebView && (
              <Suspense fallback={<Loader visible />}>
                <AddHomeScreen agentDetails={agentDetails} />
              </Suspense>
            )
          }
        </Loader>
        <AgentPICSheet
          show={showAgentPIC}
          onClose={this.handleClosePIC}
          agent={agentDetails}
        />
      </ContainerWrapper>
    )
  }
}

Container.contextType = GlobalContext

export default connect((state) => ({
  loggedIn: !!state.Auth.agent_token,
  agentDetails: state.User.agentDetails,
  isGuardianAgentLogin: state.Auth.isGuardianAgentLogin
}))(Container)
