const actions = {
  GET_VERIFICATION_DOCS_REQUEST: 'GET_VERIFICATION_DOCS_REQUEST',
  GET_VERIFICATION_DOCS_SUCCESS: 'GET_VERIFICATION_DOCS_SUCCESS',
  GET_VERIFICATION_DOCS_FAILURE: 'GET_VERIFICATION_DOCS_FAILURE',

  SUBMIT_VERIFICATION_DOCS_REQUEST: 'SUBMIT_VERIFICATION_DOCS_REQUEST',
  SUBMIT_VERIFICATION_DOCS_SUCCESS: 'SUBMIT_VERIFICATION_DOCS_SUCCESS',
  SUBMIT_VERIFICATION_DOCS_FAILURE: 'SUBMIT_VERIFICATION_DOCS_FAILURE',

  UPLOAD_DOC_REQUEST: 'UPLOAD_DOC_REQUEST',
  UPLOAD_DOC_SUCCESS: 'UPLOAD_DOC_SUCCESS',
  UPLOAD_DOC_FAILURE: 'UPLOAD_DOC_FAILURE',

  EDIT_EMAIL_REQUEST: 'EDIT_EMAIL_REQUEST',
  EDIT_EMAIL_SUCCESS: 'EDIT_EMAIL_SUCCESS',
  EDIT_EMAIL_FAILURE: 'EDIT_EMAIL_FAILURE',

  SEND_EMAIL_VERIFY_LINK_REQUEST: 'SEND_EMAIL_VERIFY_LINK_REQUEST',
  SEND_EMAIL_VERIFY_LINK_SUCCESS: 'SEND_EMAIL_VERIFY_LINK_SUCCESS',
  SEND_EMAIL_VERIFY_LINK_FAILURE: 'SEND_EMAIL_VERIFY_LINK_FAILURE',

  VALIDATE_EMAIL_REQUEST: 'VALIDATE_EMAIL_REQUEST',
  VALIDATE_EMAIL_SUCCESS: 'VALIDATE_EMAIL_SUCCESS',
  VALIDATE_EMAIL_FAILURE: 'VALIDATE_EMAIL_FAILURE',

  RESET_VERIFICATION_STORE: 'RESET_VERIFICATION_STORE',

  getVerificationDocs: () => ({
    type: actions.GET_VERIFICATION_DOCS_REQUEST
  }),
  uploadDocRequest: (payload) => ({
    type: actions.UPLOAD_DOC_REQUEST,
    payload
  }),
  submitVerificationDoc: (payload) => ({
    type: actions.SUBMIT_VERIFICATION_DOCS_REQUEST,
    payload
  }),
  updateUserEmail: (payload) => ({
    type: actions.EDIT_EMAIL_REQUEST,
    payload
  }),
  sendEmailVerifyLink: (payload) => ({
    type: actions.SEND_EMAIL_VERIFY_LINK_REQUEST,
    payload
  }),
  validateUserEmail: payload => ({
    type: actions.VALIDATE_EMAIL_REQUEST,
    payload
  }),
  resetVerificationStore: (payload) => ({
    type: actions.RESET_VERIFICATION_STORE,
    payload
  })
}

export default actions
