import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UploadWrapper } from './index.style'
import { ImageUrl } from '../../images'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import PolicyActions from '../../redux/policy/action'
import LoadingActions from '../../redux/loading/action'
import ErrorActions from '../../redux/error/action'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import CircularLoader from '../CircularLoader'
import { RequiredStar, Row } from '../../commonStyle/index.style'
import { LANG_STRINGS } from '../../lang'
import { getFileExtension } from '../../helpers/utility'
import PdfContainer from '../PdfContainer'

const { getUploadLink, resetPolicyStore } = PolicyActions
const { resetLoadingRedux } = LoadingActions
const { resetErrorRedux } = ErrorActions

class Upload extends Component {
  static propTypes = {
    fileType: PropTypes.string,
    icon: PropTypes.string,
    height: PropTypes.string,
    displayBorder: PropTypes.bool,
    hideUpload: PropTypes.bool,
    defaultValue: PropTypes.string,
    showImg: PropTypes.bool
  }

  state = {
    name: '',
    imgUrl: this.props.defaultValue || ''
  }

  componentDidMount () {
    this.props.resetErrorRedux(['GET_UPLOAD_LINK'])
    this.props.resetLoadingRedux(['GET_UPLOAD_LINK'])
  }

  componentWillReceiveProps (nextProps) {
    const { name, resetPolicyStore, getUploadLinkError, uploadLinkStatus, defaultValue } = nextProps
    if (nextProps.getUploadLinkError && nextProps.getUploadLinkLoading === false) {
      this.props.resetErrorRedux(['GET_UPLOAD_LINK'])
      this.props.resetLoadingRedux(['GET_UPLOAD_LINK'])
    }
    if (nextProps.uploadLink[name]) {
      const imgUrl = nextProps.uploadLink[name].url
      this.props.onUpload({ [name]: imgUrl })
      this.setState({
        imgUrl
      })
      resetPolicyStore(['uploadLink'])
      this.props.resetErrorRedux(['GET_UPLOAD_LINK'])
      this.props.resetLoadingRedux(['GET_UPLOAD_LINK'])
    }

    if (getUploadLinkError && uploadLinkStatus) {
      NotificationManager.error(uploadLinkStatus)
      resetPolicyStore(['uploadLinkStatus'])
    }

    if (defaultValue && !this.state.imgUrl && !this.isSetImgUrl) {
      this.isSetImgUrl = true
      this.setState({
        imgUrl: defaultValue
      })
    }
  }

  handleUpload = (e, inputName) => {
    // e.preventDefault()
    const { name, onlyImg } = this.props
    const file = document.getElementById(name || inputName)
    if ('files' in file) {
      if (onlyImg && ((getFileExtension(file.files[0].name).toLowerCase()) !== 'png' && (getFileExtension(file.files[0].name).toLowerCase()) !== 'jpg' && (getFileExtension(file.files[0].name).toLowerCase()) !== 'jpeg')) {
        NotificationManager.error('Hanya file png/jpg/jpeg diperbolehkan')
        file.value = ''
        return
      }
      if (file.files[0]) {
        if (file.files[0].size >= 11000000) {
          NotificationManager.error('Maksimum ukuran file 10MB')
          file.value = ''
        } else {
          NotificationManager.info('Pengunggahan dimulai')
          this.setState({
            name
          })
          this.props.getUploadLink({ file: file.files[0], name: name })
        }
      }
    }
  }

  getImageContainer = (imgUrl, width) => {
    const { name, fileType, height, hideUpload } = this.props
    const ext = getFileExtension(imgUrl)
    if (ext === 'pdf') {
      return (
        <PdfContainer url={imgUrl}>
          {
            !hideUpload && (
              <Row
                align='center'
                justify='center'
                className='upload-change-btn'
              >
                <span>{LANG_STRINGS.change}</span>&nbsp;&nbsp;
                <input
                  type='file'
                  id={name}
                  name={name}
                  onChange={this.handleUpload}
                  accept={fileType || 'image/*'}
                />
                <i className='fas fa-pen' />
              </Row>
            )
          }
        </PdfContainer>
      )
    }
    return (
      <div
        className={`image-display ${width && 'small-img-container'}`}
        style={{
          backgroundImage: `url(${imgUrl})`,
          marginBottom: 8,
          height: (height || '145px'),
          width: (width || '100%')
        }}
      >
        <Row
          align='center'
          justify='center'
          className='upload-change-btn'
        >
          <span>{LANG_STRINGS.change}</span>&nbsp;&nbsp;
          <input
            type='file'
            id={name}
            name={name}
            onChange={this.handleUpload}
            accept={fileType || 'image/*'}
          />
          <i className='fas fa-pen' />
        </Row>
      </div>
    )
  }

  render () {
    const {
      label, width, name, invert, errorField, hideLabel, showImg,
      className, getUploadLinkLoading, labelAlign, fileType, icon,
      displayBorder, required
    } = this.props
    const { imgUrl } = this.state
    return (
      <UploadWrapper width={width} error={errorField === name} className={className} displayBorder={displayBorder}>
        <CircularLoader isLoading={getUploadLinkLoading && (this.state.name === name)}>
          {
            invert && !!label && !hideLabel && (
              <CustomText
                size='12px'
                color={AgentColorPallette.fontSecondary}
                top='4px'
                bottom='8px'
                textAlign={labelAlign || 'center'}
              >
                {label}
              </CustomText>
            )
          }
          {
            !(showImg && imgUrl) && (
              <div className='upload-button-wrapper'>
                <img src={icon || ImageUrl.cameraIcon} alt='camera-icon' width='50' />
                <input
                  type='file'
                  id={name}
                  name={name}
                  onChange={this.handleUpload}
                  accept={fileType || 'image/*'}
                />
              </div>
            )
          }
          {
            !invert && !hideLabel && label && (
              <CustomText size='12px' color={AgentColorPallette.fontSecondary} top='4px' bottom='8px' textAlign='center'>
                {label}{required && <RequiredStar>*</RequiredStar>}
              </CustomText>
            )
          }
          {
            showImg && imgUrl && (
              this.getImageContainer(imgUrl, width)
            )
          }
          <NotificationContainer />
        </CircularLoader>
      </UploadWrapper>
    )
  }
}

export default connect((state) => ({
  getUploadLinkLoading: state.Loading.GET_UPLOAD_LINK,
  uploadLinkStatus: state.Policy.uploadLinkStatus,
  getUploadLinkError: state.Error.GET_UPLOAD_LINK,
  uploadLink: state.Policy.uploadLink
}), {
  getUploadLink,
  resetErrorRedux,
  resetPolicyStore,
  resetLoadingRedux
})(Upload)
