import styled from 'styled-components'

export const PdfContainerStyle = styled.div`
  position: relative;

  .pdf-object {
    max-width: 100%;
    width: 100%;    
  }

  .upload-change-btn {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.darkerGold};
    position: absolute;
    font-size: 10px;
    font-weight: 800;
    padding: 8px;
    right: -3px;
    border-bottom-left-radius: 10px;
    cursor: pointer;

    input[type="file"]{
      top: 0; 
      left: 0;
      opacity: 0; 
      position: absolute;
      font-size: 100px;
      width: 100%;
    }
  }

  .pdf-img-container {
    height: 145px;
    
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
`
