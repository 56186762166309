import styled from 'styled-components'

export const UploadFileButtonStyle = styled.div`
  margin-bottom: 12px; 

  .upload-btn-text {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.goldDarker};
  }

  .upload-file-btn-box {
    position: relative;
    background-color: ${props => props.theme.white};
    border: ${props => props.error ? `2px solid ${props.theme.errorDark}` : props.displayBorder ? `1px solid ${props.theme.Gray90}` : 'none'};
    border-radius: 12px;
    padding: 16px;
    height: auto;
    box-shadow: ${props => props.displayBorder || ('0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06)')};
    margin-bottom: 8px;
    cursor: pointer;
  }

  .upload-file-input {
    top: 0; 
    left: 0;
    opacity: 0; 
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .image-display {
    height: 124px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 164px;
    /* margin: 12px; */
    border: 1px solid ${props => props.theme.grey};
    border-radius: 12px;
    position: relative;
  }

  .small-img-container {
    width: 148px;
    height: 100px !important;
    max-width: 100px;
    min-width: 148px !important;
  }

  .upload-change-btn {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.darkerGold};
    position: absolute;
    font-size: 10px;
    font-weight: 800;
    padding: 8px;
    right: 0;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
  }
`
