const actions = {
  GET_AGENT_DETAILS_REQUEST: 'GET_AGENT_DETAILS_REQUEST',
  GET_AGENT_DETAILS_SUCCESS: 'GET_AGENT_DETAILS_SUCCESS',
  GET_AGENT_DETAILS_FAILURE: 'GET_AGENT_DETAILS_FAILURE',

  GET_AGENT_BADGES_REQUEST: 'GET_AGENT_BADGES_REQUEST',
  GET_AGENT_BADGES_SUCCESS: 'GET_AGENT_BADGES_SUCCESS',
  GET_AGENT_BADGES_FAILURE: 'GET_AGENT_BADGES_FAILURE',

  GET_AGENT_PAYLATER_COUNT_REQUEST: 'GET_AGENT_PAYLATER_COUNT_REQUEST',
  GET_AGENT_PAYLATER_COUNT_SUCCESS: 'GET_AGENT_PAYLATER_COUNT_SUCCESS',
  GET_AGENT_PAYLATER_COUNT_FAILURE: 'GET_AGENT_PAYLATER_COUNT_FAILURE',

  GET_PRODUCT_LIST_REQUEST: 'GET_PRODUCT_LIST_REQUEST',
  GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
  GET_PRODUCT_LIST_FAILURE: 'GET_PRODUCT_LIST_FAILURE',

  GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILURE: 'GET_NOTIFICATION_FAILURE',

  UPDATE_READ_NOTIFICATION_REQUEST: 'UPDATE_READ_NOTIFICATION_REQUEST',
  UPDATE_READ_NOTIFICATION_SUCCESS: 'UPDATE_READ_NOTIFICATION_SUCCESS',
  UPDATE_READ_NOTIFICATION_FAILURE: 'UPDATE_READ_NOTIFICATION_FAILURE',

  GET_DOWNLINE_REQUEST: 'GET_DOWNLINE_REQUEST',
  GET_DOWNLINE_SUCCESS: 'GET_DOWNLINE_SUCCESS',
  GET_DOWNLINE_FAILURE: 'GET_DOWNLINE_FAILURE',

  GET_DOWNLINE_CHILD_REQUEST: 'GET_DOWNLINE_CHILD_REQUEST',
  GET_DOWNLINE_CHILD_SUCCESS: 'GET_DOWNLINE_CHILD_SUCCESS',
  GET_DOWNLINE_CHILD_FAILURE: 'GET_DOWNLINE_CHILD_FAILURE',

  GET_AGGREGATOR_DOWNLINE_REQUEST: 'GET_AGGREGATOR_DOWNLINE_REQUEST',
  GET_AGGREGATOR_DOWNLINE_SUCCESS: 'GET_AGGREGATOR_DOWNLINE_SUCCESS',
  GET_AGGREGATOR_DOWNLINE_FAILURE: 'GET_AGGREGATOR_DOWNLINE_FAILURE',

  GET_AGGREGATOR_DOWNLINE_BUSINESS_REQUEST: 'GET_AGGREGATOR_DOWNLINE_BUSINESS_REQUEST',
  GET_AGGREGATOR_DOWNLINE_BUSINESS_SUCCESS: 'GET_AGGREGATOR_DOWNLINE_BUSINESS_SUCCESS',
  GET_AGGREGATOR_DOWNLINE_BUSINESS_FAILURE: 'GET_AGGREGATOR_DOWNLINE_BUSINESS_FAILURE',

  GET_STAFF_NAME_LIST_REQUEST: 'GET_STAFF_NAME_LIST_REQUEST',
  GET_STAFF_NAME_LIST_SUCCESS: 'GET_STAFF_NAME_LIST_SUCCESS',
  GET_STAFF_NAME_LIST_FAILURE: 'GET_STAFF_NAME_LIST_FAILURE',

  SEND_SMS_REQUEST: 'SEND_SMS_REQUEST',
  SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
  SEND_SMS_FAILURE: 'SEND_SMS_FAILURE',

  RESET_USER_STORE: 'RESET_USER_STORE',

  getAgentDetails: (payload) => ({
    type: actions.GET_AGENT_DETAILS_REQUEST,
    payload
  }),
  getAgentBadges: (payload) => ({
    type: actions.GET_AGENT_BADGES_REQUEST,
    payload
  }),
  getAgentPayLaterCount: () => ({
    type: actions.GET_AGENT_PAYLATER_COUNT_REQUEST
  }),
  getDownline: (payload) => ({
    type: actions.GET_DOWNLINE_REQUEST,
    payload
  }),
  getDownlineChild: (payload) => ({
    type: actions.GET_DOWNLINE_CHILD_REQUEST,
    payload
  }),
  getAggregatorDownline: (payload) => ({
    type: actions.GET_AGGREGATOR_DOWNLINE_REQUEST,
    payload
  }),
  getAggregatorDownlineBusiness: (payload) => ({
    type: actions.GET_AGGREGATOR_DOWNLINE_BUSINESS_REQUEST,
    payload
  }),
  getStaffNameList: (payload) => ({
    type: actions.GET_STAFF_NAME_LIST_REQUEST,
    payload
  }),
  getAgentNotifications: (payload) => ({
    type: actions.GET_NOTIFICATION_REQUEST,
    payload
  }),
  updateReadNotification: (payload) => ({
    type: actions.UPDATE_READ_NOTIFICATION_REQUEST,
    payload
  }),
  sendSms: (payload) => ({
    type: actions.SEND_SMS_REQUEST,
    payload
  }),
  resetUserStore: (payload) => ({
    type: actions.RESET_USER_STORE,
    payload
  }),
  getProductList: () => ({
    type: actions.GET_PRODUCT_LIST_REQUEST
  })
}

export default actions
