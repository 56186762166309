import actions from './actions'

const initState = {
  verificationData: {},
  updateUserEmail: {},
  updateEmailMsg: ''
}

export default function verificationReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_VERIFICATION_DOCS_SUCCESS:
    case actions.GET_VERIFICATION_DOCS_FAILURE:
      return {
        ...state,
        verificationData: action.data,
        verificationDataStatus: action.message
      }
    case actions.UPLOAD_DOC_SUCCESS:
    case actions.UPLOAD_DOC_FAILURE:
      return {
        ...state,
        uploadedDocData: action.data,
        uploadDocStatus: action.message
      }
    case actions.SUBMIT_VERIFICATION_DOCS_SUCCESS:
    case actions.SUBMIT_VERIFICATION_DOCS_FAILURE:
      return {
        ...state,
        submitVerifyDocData: action.data,
        submitVerifyMsg: action.message
      }
    case actions.EDIT_EMAIL_SUCCESS:
    case actions.EDIT_EMAIL_FAILURE:
      return {
        ...state,
        updateUserEmail: action.data,
        updateEmailMsg: action.message
      }
    case actions.SEND_EMAIL_VERIFY_LINK_SUCCESS:
    case actions.SEND_EMAIL_VERIFY_LINK_FAILURE:
      return {
        ...state,
        sendEmailVerifyLinkData: action.data,
        sendEmailVerifyMsg: action.message
      }
    case actions.VALIDATE_EMAIL_SUCCESS:
    case actions.VALIDATE_EMAIL_FAILURE:
      return {
        ...state,
        emailStatusData: action.data,
        emailStatusDataMsg: action.message
      }
    case actions.RESET_VERIFICATION_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    default:
      return state
  }
}
