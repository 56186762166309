import { LANG_STRINGS } from '../../../../lang'
import {
  COVID_PROTECTION, JIWA_PROTECTION, BIKE_PROTECTION_LITE, HEALTH_PROTECTION
} from '../../../../constants/pkgCode'
import { getPackagesReqBody } from '../../../../helpers/getPackageBody'
import { QUOTE_STATUS_LANG } from '../../../../helpers/data'
import { BUS_ICON, TRUCK_ICON, PASSENGER_CAR_ICON } from '../../../../images/imageUrl'

export const EXCLUDE_PRODUCT_KEY = [
  COVID_PROTECTION,
  JIWA_PROTECTION,
  HEALTH_PROTECTION,
  BIKE_PROTECTION_LITE
]

export const NEW_POLICY_NEW_CAR = 'new-policy-new-car'
export const NEW_POLICY_USED_CAR = 'new-policy-used-car'
export const RENEW_POLICY_WITH_SAME_PROVIDER = 'renew-policy-with-same-provider'
export const RENEW_POLICY_WITH_DIFFERENT_PROVIDER = 'renew-policy-with-different-provider'

export const MANUAL_FORM_STEP_1 = [
  'brand', 'model', 'packageType', 'policyPeriod', 'currency_label',
  'providerData', 'region_code', 'transportationType', 'vehicleUse',
  'vehicle_plate_no', 'vehicle_sum_insured', 'year'
]

// TODO: Add this field 'premium_calculation_note',
export const FLEET_FORM_STEP_1 = [
  'premium_calculation_note', 'providerData', 'policyPeriod', 'total_gwp_ojk_rate'
]

export const VEHICLE_PHOTO_TYPE = ['dashboard', 'front', 'back', 'left', 'right']
export const MANUAL_FORM_STEP_2 = {
  [NEW_POLICY_NEW_CAR]: { // this fields are required else other field are optional
    customer_name: true,
    customer_address: true,
    stnk_url: true,
    ktp: true,
    ktp_no: true
  },
  [NEW_POLICY_USED_CAR]: {
    customer_name: true,
    customer_address: true,
    stnk_url: true,
    ktp: true,
    ktp_no: true
  },
  [RENEW_POLICY_WITH_SAME_PROVIDER]: {
    customer_name: true,
    customer_address: true,
    stnk_url: true,
    previous_certificate_url: true,
    appointment_letter_url: true,
    ktp: true,
    ktp_no: true
  },
  [RENEW_POLICY_WITH_DIFFERENT_PROVIDER]: {
    customer_name: true,
    customer_address: true,
    stnk_url: true,
    previous_certificate_url: true,
    ktp: true,
    ktp_no: true
  }
}

export const FLEET_FORM_STEP_2 = {
  [NEW_POLICY_NEW_CAR]: {
    customer_name: true,
    customer_address: true,
    stnk_url: true,
    npwp_url: true,
    ktp: true,
    npwp: true,
    ktp_no: true
  },
  [NEW_POLICY_USED_CAR]: {
    customer_name: true,
    customer_address: true,
    npwp_url: true,
    stnk_url: true,
    ktp: true,
    ktp_no: true
  },
  [RENEW_POLICY_WITH_SAME_PROVIDER]: {
    customer_name: true,
    customer_address: true,
    npwp_url: true,
    stnk_url: true,
    previous_certificate_url: true,
    appointment_letter_url: true,
    ktp: true,
    ktp_no: true
  },
  [RENEW_POLICY_WITH_DIFFERENT_PROVIDER]: {
    customer_name: true,
    customer_address: true,
    npwp_url: true,
    stnk_url: true,
    previous_certificate_url: true,
    ktp: true,
    ktp_no: true
  }
}

export const POLICY_STATUS_FILTER = [
  {
    display: LANG_STRINGS.allTypeOfTransactions,
    type: 'ALL'
  },
  {
    display: LANG_STRINGS.normalTransactions,
    type: 'NORMAL'
  },
  {
    display: LANG_STRINGS.paylaterTransactions,
    type: 'PAY_LATER'
  }
]

export const POLICY_STEPS = [
  {
    index: 1,
    text: LANG_STRINGS.protectionTypeNew
  },
  {
    index: 2,
    text: LANG_STRINGS.packageInsurance
  },
  {
    index: 3,
    text: LANG_STRINGS.insuredObject
  },
  {
    index: 4,
    text: LANG_STRINGS.customerData
  },
  {
    index: 5,
    text: LANG_STRINGS.protectionTypeNew
  }
]

export const getCategory = (type) => {
  switch (type) {
    case 'bus':
      return 'BUS'
    case 'non-bus-non-truck':
      return 'NON_TRUCK_NON_BUS'
    default:
      return 'TRUCK_PICKUP'
  }
}

export const MOBIL_FILTER = [
  {
    title: LANG_STRINGS.all,
    level: 'first',
    id: 'transaction-all',
    filter: [],
    excludeFilter: EXCLUDE_PRODUCT_KEY.join(',')
  },
  {
    title: LANG_STRINGS.alreadyPaid,
    level: 'second',
    id: 'transaction-paid',
    filter: ['PAID']
  },
  {
    title: LANG_STRINGS.waitingForPayment,
    level: 'third',
    id: 'transaction-payment-pending',
    filter: ['WAITING_FOR_PAYMENT'],
    excludeFilter: EXCLUDE_PRODUCT_KEY.join(',')
  },
  {
    title: LANG_STRINGS.paymentFaild,
    level: 'fourth',
    id: 'transaction-payment-failed',
    filter: ['FAILED']
  },
  {
    title: LANG_STRINGS.expired,
    level: 'fifth',
    id: 'transaction-payment-expired',
    filter: ['EXPIRED']
  },
  {
    title: LANG_STRINGS.inProcess,
    level: 'sixth',
    type: 'leadPanel',
    id: 'transaction-lead-panel',
    filter: ['ACTIVE']
  }
]

export const COVID_FILTER = [
  {
    title: LANG_STRINGS.alreadyPaid,
    level: 'first',
    type: 'quotationPanel',
    id: 'transaction-paid',
    filter: ['PAID']
  },
  {
    title: LANG_STRINGS.inProcess,
    level: 'second',
    id: 'transaction-lead-panel',
    type: 'leadPanel',
    filter: ['ACTIVE']
  },
  {
    title: LANG_STRINGS.waitingForPayment,
    level: 'third',
    id: 'transaction-payemnet-panding',
    filter: ['WAITING_FOR_PAYMENT'],
    excludeFilter: EXCLUDE_PRODUCT_KEY.join(',')
  }
]

export const TRANSACTIONS_TYPE = [
  {
    name: 'Normal',
    label: 'Normal',
    subText: 'Polis dapat diproses setelah pembayaran berhasil dilakukan.',
    type: 'NORMAL',
    id: 'NORMAL',
    code: 'NORMAL'
  },
  {
    name: 'Pay Later',
    label: 'Pay Later',
    subText: 'Polis dapat diproses terlebih dahulu dengan maksimum waktu pembayaran yang telah ditentukan. S&K berlaku.',
    type: 'PAYLATER',
    id: 'PAYLATER',
    code: 'PAYLATER'
  }
]

export const TRANSACTION_CHANGE_STATUS = [
  'APPLICATION_COMPLETED',
  'PAYMENT_PENDING'
]

export const getAddonsCatMapBody = (addonsData) => {
  let addonsMap = {}
  const addons = {
    add_on: [],
    no_of_pax: 0,
    passenger_sum_insured_pax: 0
  }
  for (const key in addonsData) {
    addonsMap = {
      ...addonsMap,
      ...addonsData[key]
    }
  }

  for (const key in addonsMap) {
    const item = addonsMap[key]
    if (item.add_on_code && !item.group_of_addons) {
      addons.add_on.push(item.add_on_code)
      if (item.add_on_code === 'passenger-insurance' && item.premium) {
        addons.passenger_sum_insured_pax = item.limit + addons.passenger_sum_insured_pax
        addons.no_of_pax = item.no_passanger + addons.no_of_pax
      } else if (item.add_on_code === 'driver-insurance') {
        addons.driver_sum_insured = item.limit
      } else {
        const dataKey = `${item.add_on_code.replace(/-/g, '_')}_sum_insured`
        addons[dataKey] = item.show_drop_down ? item.limit : item.premium
      }
    }
  }
  return addons
}

export const mvPackageDetailBody = (data = {}, addons) => ({
  ...getPackagesReqBody(data.policy_detail),
  ...getAddonsCatMapBody(addons),
  accessories_sum_insured: data.policy_detail && data.policy_detail.accessories_sum_insured,
  purchase_type: data.policy_detail && data.policy_detail.purchase_type
})

export const getPropertyAddonsMap = (addons = {}) => {
  const addon = []
  for (const key in addons) {
    const addonData = addons[key]
    for (const addonKey in addonData) {
      addon.push(addonKey)
    }
  }
  return addon
}

export const propertyPackageData = (data = {}, addons = {}) => ({
  building_content_value: data.building_content_value,
  building_value: data.building_value,
  city_code: data.city_code,
  class_type: data.class_type,
  code: data.package_code,
  construction_class: data.construction_class,
  flood: data.flood,
  machine_value: data.machine_value,
  occupation_code: data.occupation_code,
  occupation_remarks: data.occupation_remarks,
  package_type: data.package_type,
  province_code: data.province_code,
  stock_value: data.stock_value,
  sum_insured: data.sum_insured,
  zone: data.zone,
  add_on: getPropertyAddonsMap(addons)
})

export const POLICY_TYPE = [
  {
    title: LANG_STRINGS.newPolicyNewCar,
    code: NEW_POLICY_NEW_CAR
  },
  {
    title: LANG_STRINGS.newPolicyWithUsedCar,
    code: NEW_POLICY_USED_CAR
  },
  {
    title: LANG_STRINGS.renewPolicyWithSameProvider,
    code: RENEW_POLICY_WITH_SAME_PROVIDER
  },
  {
    title: LANG_STRINGS.renewPolicyWithDifferentProvider,
    code: RENEW_POLICY_WITH_DIFFERENT_PROVIDER
  }
]

export const BENEFIT_TYPE_MAP = {
  'car-protection-comprehensive': 'Komprehensif',
  'car-protection-tlo': 'TLO (Total Lost Only)'
}

export const BENEFIT_TYPE = [
  {
    title: 'Komprehensif',
    id: 'car-protection-comprehensive',
    value: 'car-protection-comprehensive'
  },
  {
    title: 'TLO (Total Lost Only)',
    id: 'car-protection-tlo',
    value: 'car-protection-tlo'
  }
]

export const POLICY_TIME_PERIOD = [
  {
    title: '1 tahun',
    value: 1
  },
  {
    title: '2 tahun',
    value: 2
  },
  {
    title: '3 tahun',
    value: 3
  },
  {
    title: '4 tahun',
    value: 4
  },
  {
    title: '5 tahun',
    value: 5
  }
]

export const TRANSPORTATION_TYPE = [
  {
    title: LANG_STRINGS.passengerCar,
    id: 'non-bus-non-truck',
    icon: PASSENGER_CAR_ICON,
    value: 'non-bus-non-truck'
  },
  {
    title: LANG_STRINGS.bus,
    id: 'bus',
    icon: BUS_ICON,
    value: 'bus'
  },
  {
    title: LANG_STRINGS.truckPickup,
    id: 'truck-pickup',
    icon: TRUCK_ICON,
    value: 'truck-pickup'
  }
]

export const VEHICLE_USE = [
  {
    title: 'Personal/Operasional Kantor',
    id: 'Personal',
    value: 'Personal'
  },
  {
    title: 'Komersil',
    id: 'Commercial',
    value: 'Commercial'
  }
]

export const getVehicleDataForm = (data = {}, type, isRequired) => {
  const requiredFields = MANUAL_FORM_STEP_2[type] || {}
  return {
    fields: [
      {
        type: 'chasis_number',
        placeholder: 'Nomor Rangka',
        name: 'chasis_number',
        error: 'Masukkan nomor rangka 8 sampai 20 karakter',
        defaultValue: data?.chasis_number,
        label: 'Nomor Rangka',
        skipEmptyCheck: isRequired ? false : !requiredFields.chasis_number,
        subTitle: LANG_STRINGS.combinationOf17NumberAndLetters
      },
      {
        type: 'machine_number',
        placeholder: 'Nomor Mesin',
        name: 'machine_number',
        error: 'Masukkan nomor mesin 5 sampai 21 karakter',
        defaultValue: data?.machine_number,
        label: 'Nomor Mesin',
        skipEmptyCheck: isRequired ? false : !requiredFields.machine_number,
        subTitle: LANG_STRINGS.combinationOf16NumberAndLetters
      },
      {
        type: 'radioCard',
        label: 'Transmisi',
        name: 'transmission_type',
        skipEmptyCheck: isRequired ? false : !requiredFields.transmission_type,
        data: [
          {
            title: 'Manual',
            id: 'Manual',
            value: 'Manual'
          },
          {
            title: 'Otomatis',
            id: 'Automatic',
            value: 'Automatic'
          }
        ],
        defaultValue: data?.transmission_type?.value || data?.transmission_type
      },
      {
        type: 'color',
        label: 'Warna',
        name: 'color',
        skipEmptyCheck: isRequired ? false : !requiredFields.color,
        error: 'Memasukkan warna 2 sampai 35 karakter',
        defaultValue: data?.color,
        placeholder: 'Warna'
      }
    ]
  }
}

export const getFleetVehicleDocuments = (data = {}, protectionType) => {
  const requiredFields = FLEET_FORM_STEP_2[protectionType] || {}
  const fieldsData = [
    {
      type: 'uploadBulkImgButton',
      label: `${LANG_STRINGS.copyOfPreviousPolicy}${!requiredFields.previous_certificate_url ? ` (${LANG_STRINGS.optional})` : ''}`,
      guide: true,
      hideGuideIcon: true,
      skipEmptyCheck: !requiredFields.previous_certificate_url,
      placeholder: LANG_STRINGS.copyOfPreviousPolicy,
      defaultValue: data?.previous_certificate_url,
      fileType: 'image/*,.pdf',
      name: 'previous_certificate_url'
    },
    {
      type: 'uploadBulkImgButton',
      label: LANG_STRINGS.stnkBstkPhoto,
      guide: true,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.stnkBstkPhoto,
      defaultValue: data?.stnk_url,
      skipEmptyCheck: !requiredFields.stnk_url,
      name: 'stnk_url'
    },
    {
      type: 'uploadBulkImgButton',
      label: `${LANG_STRINGS.vehiclePhoto}${!requiredFields.vehicle_photos ? ` (${LANG_STRINGS.optional})` : ''}`,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.upload,
      defaultValue: data?.vehicle_photos,
      skipEmptyCheck: !requiredFields.vehicle_photos,
      name: 'vehicle_photos'
    },
    {
      type: 'uploadFileButton',
      label: `${LANG_STRINGS.letterOfAppointmentFromCustomer}${requiredFields.appointment_letter_url ? '' : ` (${LANG_STRINGS.optional})`}`,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.letterOfAppointmentFromCustomer,
      defaultValue: data?.appointment_letter_url,
      fileType: 'image/*,.pdf',
      skipEmptyCheck: !requiredFields.appointment_letter_url,
      name: 'appointment_letter_url'
    },
    {
      type: 'uploadBulkImgButton',
      label: `${LANG_STRINGS.otherDocumentIfAny} (${LANG_STRINGS.optional})`,
      hideGuideIcon: true,
      skipEmptyCheck: !requiredFields.other_document_url,
      fileType: 'image/*,.pdf',
      placeholder: LANG_STRINGS.otherDocumentIfAny,
      defaultValue: data?.other_document_url,
      name: 'other_document_url'
    },
    {
      type: 'textarea',
      label: `${LANG_STRINGS.additionalInfo} (${LANG_STRINGS.optional})`,
      name: 'additional_info',
      skipEmptyCheck: !requiredFields.additional_info,
      error: '',
      defaultValue: data?.additional_info,
      placeholder: LANG_STRINGS.additionalInfo
    }
  ]
  let filterData = [
    ...fieldsData
  ]
  if (protectionType === NEW_POLICY_NEW_CAR) {
    const fieldsForCase = {
      stnk_url: true,
      additional_info: true,
      other_document_url: true
    }
    filterData = fieldsData.filter(item => fieldsForCase[item.name])
  } else if (protectionType === NEW_POLICY_USED_CAR) {
    const fieldsForCase = {
      stnk_url: true,
      previous_certificate_url: true,
      vehicle_photos: true,
      additional_info: true,
      other_document_url: true
    }
    filterData = fieldsData.filter(item => fieldsForCase[item.name])
  } else if (protectionType === RENEW_POLICY_WITH_DIFFERENT_PROVIDER) {
    filterData.splice(3, 1)
  }
  return ({
    fields: filterData
  })
}

export const getAdditionalInfoAttachments = (data, protectionType, isManualPolicy) => {
  const type = isManualPolicy ? 'uploadFileButton' : 'uploadBulkImgButton'
  const fields = [
    {
      type,
      label: LANG_STRINGS.otherDocumentIfAny,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.otherDocumentIfAny,
      defaultValue: data?.other_document_url,
      skipEmptyCheck: true,
      fileType: 'image/*,.pdf',
      name: 'other_document_url'
    },
    {
      type: 'textarea',
      label: LANG_STRINGS.additionalInfo,
      name: 'additional_info',
      error: '',
      skipEmptyCheck: true,
      defaultValue: data?.additional_info,
      placeholder: LANG_STRINGS.additionalInfo
    }
  ]
  if (!isManualPolicy && [NEW_POLICY_NEW_CAR, NEW_POLICY_USED_CAR].includes(protectionType)) {
    fields.unshift({
      type,
      label: LANG_STRINGS.vehiclePhoto,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.upload,
      defaultValue: data?.vehicle_photos,
      name: 'vehicle_photos'
    })
  }
  if ([RENEW_POLICY_WITH_SAME_PROVIDER, RENEW_POLICY_WITH_DIFFERENT_PROVIDER].includes(protectionType)) {
    fields.unshift({
      type: 'uploadFileButton',
      label: LANG_STRINGS.letterOfAppointmentFromCustomer,
      guide: true,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.letterOfAppointmentFromCustomer,
      defaultValue: data?.appointment_letter_url,
      fileType: 'image/*,.pdf',
      skipEmptyCheck: protectionType === RENEW_POLICY_WITH_DIFFERENT_PROVIDER,
      name: 'appointment_letter_url'
    })
  }
  return {
    fields
  }
}

export const getPolicyVehicleAttachments = (data = {}, protectionType) => {
  // Please don't use for regular flow
  const requiredFields = MANUAL_FORM_STEP_2[protectionType] || {}
  const fieldsData = [
    {
      type: 'uploadFileButton',
      label: `${LANG_STRINGS.copyOfPreviousPolicy}${!requiredFields.previous_certificate_url ? ` (${LANG_STRINGS.optional})` : ''}`,
      guide: true,
      hideGuideIcon: true,
      skipEmptyCheck: !requiredFields.previous_certificate_url,
      placeholder: LANG_STRINGS.previous_certificate_url,
      defaultValue: data?.previous_certificate_url,
      fileType: 'image/*,.pdf',
      name: 'previous_certificate_url'
    },
    {
      type: 'uploadFileButton',
      label: LANG_STRINGS.stnkBstkPhoto,
      guide: true,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.stnkBstkPhoto,
      defaultValue: data?.stnk_url,
      skipEmptyCheck: !requiredFields.stnk_url,
      name: 'stnk_url'
    },
    {
      type: 'uploadFileButton',
      label: `${LANG_STRINGS.letterOfAppointmentFromCustomer}${requiredFields.appointment_letter_url ? '' : ` (${LANG_STRINGS.optional})`}`,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.letterOfAppointmentFromCustomer,
      defaultValue: data?.appointment_letter_url,
      fileType: 'image/*,.pdf',
      skipEmptyCheck: !requiredFields.appointment_letter_url,
      name: 'appointment_letter_url'
    },
    {
      type: 'uploadFileButton',
      label: LANG_STRINGS.otherDocumentIfAny,
      hideGuideIcon: true,
      placeholder: LANG_STRINGS.otherDocumentIfAny,
      defaultValue: data?.other_document_url,
      fileType: 'image/*,.pdf',
      skipEmptyCheck: !requiredFields.other_document_url,
      name: 'other_document_url'
    },
    {
      type: 'textarea',
      label: LANG_STRINGS.additionalInfo,
      name: 'additional_info',
      error: '',
      skipEmptyCheck: !requiredFields.additional_info,
      defaultValue: data?.additional_info,
      placeholder: LANG_STRINGS.additionalInfo
    }
  ]
  let filterData = [
    ...fieldsData
  ]
  if (protectionType === NEW_POLICY_NEW_CAR) {
    const fieldsForNewPolicyNewCar = {
      stnk_url: true,
      additional_info: true,
      other_document_url: true
    }
    filterData = fieldsData.filter(item => fieldsForNewPolicyNewCar[item.name])
  } else if (NEW_POLICY_USED_CAR === protectionType) {
    const fieldsForNewPolicyNewCar = {
      stnk_url: true,
      additional_info: true,
      previous_certificate_url: true,
      other_document_url: true
    }
    filterData = fieldsData.filter(item => fieldsForNewPolicyNewCar[item.name])
  } else if (protectionType === RENEW_POLICY_WITH_DIFFERENT_PROVIDER) {
    const fieldsForNewPolicyNewCar = {
      stnk_url: true,
      additional_info: true,
      previous_certificate_url: true,
      appointment_letter_url: true,
      other_document_url: true
    }
    filterData = fieldsData.filter(item => fieldsForNewPolicyNewCar[item.name])
  }
  return {
    fields: filterData
  }
}

export const getCustomerData = (data = {}, isManualPolicy, protectionType) => {
  const requiredFields = (isManualPolicy ? MANUAL_FORM_STEP_2[protectionType] : FLEET_FORM_STEP_2[protectionType]) || {}
  const fields = [
    {
      type: 'uploadFileButton',
      label: LANG_STRINGS.photoKTP,
      guide: true,
      hideGuideIcon: true,
      defaultValue: data?.ktp_url || data?.ktp,
      skipEmptyCheck: !requiredFields.ktp,
      name: 'ktp'
    },
    {
      type: 'uploadFileButton',
      label: LANG_STRINGS.npwpRequiredForCorporateNeeds,
      hideGuideIcon: true,
      defaultValue: data?.npwp_url,
      skipEmptyCheck: !requiredFields.npwp_url,
      name: 'npwp_url'
    },
    {
      type: 'ktp',
      label: 'Nomor KTP',
      placeholder: 'Nomor KTP',
      error: 'KTP harus 16 atau 18 digit',
      defaultValue: data?.ktp_no,
      name: 'ktp_no',
      skipEmptyCheck: !requiredFields.ktp_no,
      className: 'customer-phone-input'
    },
    {
      type: 'name',
      label: 'Nama Lengkap (sesuai KTP)',
      placeholder: 'Nama Lengkap (sesuai KTP)',
      skipEmptyCheck: !requiredFields.customer_name,
      defaultValue: data?.name || data?.customer_name,
      name: 'customer_name'
    },
    {
      type: 'phone_number',
      label: `${LANG_STRINGS.customerPhone}${!isManualPolicy ? ` (${LANG_STRINGS.optional})` : ''}`,
      skipEmptyCheck: !requiredFields.mobile_no,
      placeholder: 'Nomor Handphone E.g., 08xxxxxxxxx',
      defaultValue: data?.customer_phone_no,
      className: 'customer-phone-input',
      name: 'customer_phone_no'
    },
    {
      type: 'email',
      label: `${LANG_STRINGS.emailAddress}${!isManualPolicy ? ` (${LANG_STRINGS.optional})` : ''}`,
      skipEmptyCheck: !requiredFields.email,
      placeholder: 'Alamat Email',
      defaultValue: data?.customer_email,
      name: 'customer_email'
    }
  ]

  return {
    fields
  }
}

export const HARD_COPY_DELIVERY_OPTIONS = [
  {
    id: 'send-to-customer',
    name: LANG_STRINGS.sendToCustomer,
    subText: ''
  },
  {
    id: 'send-to-another-address',
    name: LANG_STRINGS.sendToAnotherAddress,
    subText: ''
  },
  {
    id: 'send-to-pasarpolis',
    name: LANG_STRINGS.sendToPasarpolis,
    subText: ''
  }
]

export const OFFLINE_POLICY_FILTER = [
  {
    title: LANG_STRINGS.otherStatus,
    filters: [
      {
        title: LANG_STRINGS.allStatus,
        value: 'ALL'
      },
      {
        title: LANG_STRINGS.active,
        value: 'QUOTE_IN_PROGRESS'
      },
      {
        title: QUOTE_STATUS_LANG?.QUOTE_ISSUED,
        value: 'QUOTE_ISSUED'
      },
      {
        title: QUOTE_STATUS_LANG?.QUOTE_ACCEPTED,
        value: 'QUOTE_ACCEPTED'
      },
      {
        title: QUOTE_STATUS_LANG?.POLICY_ISSUANCE_PENDING,
        value: 'POLICY_ISSUANCE_PENDING'
      },
      {
        title: QUOTE_STATUS_LANG?.POLICY_ISSUED,
        value: 'POLICY_ISSUED'
      },
      {
        title: QUOTE_STATUS_LANG?.POLICY_COMPLETED,
        value: 'POLICY_COMPLETED'
      },
      {
        title: QUOTE_STATUS_LANG?.ADDITIONAL_INFORMATION_REQUIRED,
        value: 'ADDITIONAL_INFORMATION_REQUIRED'
      },
      {
        title: QUOTE_STATUS_LANG?.QUOTE_REJECTED,
        value: 'QUOTE_REJECTED'
      }
    ]
  }
]

export const OFFER_QUOTES_TABS = [
  {
    title: LANG_STRINGS.bidding,
    value: 'bidding'
  },
  {
    title: LANG_STRINGS.specification,
    value: 'specification'
  }
]

export const getEndorsementStatus = (statusCode) => {
  switch (statusCode) {
    case 'UNPAID_ENDORSEMENT':
      return LANG_STRINGS.waitingForPayment
    case 'ADDITIONAL_BILL_PAID':
      return LANG_STRINGS.alreadyPaid
    case 'PROOF_SUBMITTED':
      return LANG_STRINGS.beingVerified
    case 'PROOF_REJECTED':
      return LANG_STRINGS.invalidPayment
    case 'ADDITIONAL_PAYMENT_PENDING':
      return LANG_STRINGS.waitingForPayment
    default:
      return ''
  }
}
