import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import authGetApi from '../../methods/authGet'
import postAuthApi from '../../methods/authPost'
import { ConfigUrl } from '../../configs/url'
import authPostFormData from '../../methods/authPostFormData'
import { getErrorMessage } from '../../helpers/utility'
import get from '../../methods/get'
import { ERROR_LANG } from '../../lang'

export function * getVerificationDocSaga () {
  // this function returns the list of verification doc
  // like email, KTP and NPWP
  yield takeEvery(actions.GET_VERIFICATION_DOCS_REQUEST, function * () {
    try {
      const res = yield call(authGetApi, { url: ConfigUrl.getVerificationDataUrl })
      if (res) {
        yield put({
          type: actions.GET_VERIFICATION_DOCS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_VERIFICATION_DOCS_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_VERIFICATION_DOCS_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * submitVerificationDocs () {
  yield takeEvery(actions.SUBMIT_VERIFICATION_DOCS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.submitVerificationDoc
      })
      if (res) {
        yield put({
          type: actions.SUBMIT_VERIFICATION_DOCS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_VERIFICATION_DOCS_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      let genericError = res.data && res.data.message
      if (genericError.includes("code='invalid'")) {
        genericError = 'Sedang terjadi kesalahan, silahkan coba lagi nanti'
      }
      yield put({
        type: actions.SUBMIT_VERIFICATION_DOCS_FAILURE,
        message: (res.error_code && getErrorMessage('', res.error_code)) || genericError,
        data: []
      })
    }
  })
}

export function * editUserEmailId () {
  yield takeEvery(actions.EDIT_EMAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.editUserEmail
      })
      if (res) {
        yield put({
          type: actions.EDIT_EMAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.EDIT_EMAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.EDIT_EMAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * sendEmailVerifyLink () {
  yield takeEvery(actions.SEND_EMAIL_VERIFY_LINK_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.sendEmailVerifyLink
      })
      if (res) {
        yield put({
          type: actions.SEND_EMAIL_VERIFY_LINK_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SEND_EMAIL_VERIFY_LINK_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SEND_EMAIL_VERIFY_LINK_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getUploadDoc () {
  yield takeEvery(actions.UPLOAD_DOC_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPostFormData, {
        body: payload,
        url: ConfigUrl.uploadUserDocUrl,
        headers: { DocumentType: payload.name.toUpperCase() }
      })
      if (res) {
        yield put({
          type: actions.UPLOAD_DOC_SUCCESS,
          data: { data: res.data, name: payload.name },
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPLOAD_DOC_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPLOAD_DOC_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * validateUserEmailStatus () {
  // this function returns the list of verifiation doc
  // like email, KTP and NPWP
  yield takeEvery(actions.VALIDATE_EMAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(get, {
        query: {
          token: payload.token
        },
        url: ConfigUrl.emailStatusCheckApi(payload.agentCode)
      })
      if (res) {
        yield put({
          type: actions.VALIDATE_EMAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.VALIDATE_EMAIL_FAILURE,
          message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.VALIDATE_EMAIL_FAILURE,
        message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
        data: {}
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getVerificationDocSaga),
    fork(submitVerificationDocs),
    fork(editUserEmailId),
    fork(sendEmailVerifyLink),
    fork(validateUserEmailStatus),
    fork(getUploadDoc)
  ])
}
