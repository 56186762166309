import { Component } from 'react'
import { NotificationWrapper } from './index.style'

class Notification extends Component {
  render () {
    const { message, type, visible, ...restProps } = this.props

    return (
      visible ? (
        <NotificationWrapper type={type} {...restProps}>
          {message}
        </NotificationWrapper>
      ) : null
    )
  }
}

export default Notification
