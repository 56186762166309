import { ConfigUrl } from '../../../../configs/url'
import { getHeaderUploadFile } from '../../../../helpers/utility'

export const KTP_ACTIONS = {
  GET_VERIFICATION_DOCS_REQUEST: 'GET_VERIFICATION_DOCS_REQUEST',
  GET_VERIFICATION_DOCS: 'GET_VERIFICATION_DOCS',

  SUBMIT_VERIFICATION_DOCS_REQUEST: 'SUBMIT_VERIFICATION_DOCS_REQUEST',
  SUBMIT_VERIFICATION_DOCS: 'SUBMIT_VERIFICATION_DOCS',

  UPLOAD_DOC_REQUEST: 'UPLOAD_DOC_REQUEST',
  UPLOAD_DOC: 'UPLOAD_DOC',

  RESET_KTP_NPWP_STATE: 'RESET_KTP_NPWP_STATE'
}

export const getVerificationDoc = () => ({
  type: KTP_ACTIONS.GET_VERIFICATION_DOCS_REQUEST,
  payload: {
    url: ConfigUrl.getVerificationDataUrl,
    method: 'GET'
  }
})

export const submitVerificationDoc = (payload) => ({
  type: KTP_ACTIONS.SUBMIT_VERIFICATION_DOCS_REQUEST,
  payload: {
    ...payload,
    url: ConfigUrl.submitVerificationDoc,
    method: 'POST'
  }
})

export const uploadKtpNpwpDoc = (payload) => ({
  type: KTP_ACTIONS.UPLOAD_DOC_REQUEST,
  payload: {
    ...payload,
    headers: {
      ...getHeaderUploadFile(),
      DocumentType: payload.name.toUpperCase()
    },
    url: ConfigUrl.uploadUserDocUrl,
    method: 'POST'
  }
})

export const resetKtpNpwpState = (payload) => ({
  type: KTP_ACTIONS.RESET_KTP_NPWP_STATE,
  payload
})
