import styled from 'styled-components'

export const PolicyCardStyle = styled.div`
  width: auto;
  margin: 1rem 20px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.Gray90};
  display: grid;
  grid-template-columns: 40px 1fr;
  background-color: ${props => props.theme.white};

  .product-icon {
    width: 36px;
    height: 36px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .offline-card-right {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-top-section {
    padding: 12px 12px 0;
  }

  .card-btm-section {
    padding: 0 12px 12px;
  }

  .card-order-date {
    padding-top: 0.8rem;
  }

  .card-warning {
    background-color: ${props => props.theme.warning};
    padding: 12px;
    border-radius: 18px;
    grid-column: 1 / 3;
    margin-top: 0.7rem;
  }
`

export const PolicyStatusChip = styled.div`
  font-size: ${props => props.config.size || '10px'};
  color: ${props => props.color || props.theme.white};
  font-weight: 800;
  text-align: ${props => props.textAlign || 'start'};
  background-color: ${props => props.bgColor};
  padding: ${props => props.config.padding || '6px 8px'};
  border-radius: 50px;
  text-transform: uppercase;
  display: inline;
`
