// this function used to scroll to an element in the view
// if an error occurs
export const smoothScroll = (elementId) => {
  var MIN_PIXELS_PER_STEP = 16
  var MAX_SCROLL_STEPS = 30
  const scrollElement = document.getElementById(elementId)
  var target = document.getElementById(elementId)
  var scrollContainer = target
  do {
    scrollContainer = scrollContainer.parentNode
    if (!scrollContainer) return
    scrollContainer.scrollTop += 1
  } while (scrollContainer.scrollTop === 0)

  var targetY = -60 // this set for header
  do {
    if (target === scrollContainer) break
    targetY += target.offsetTop
  // eslint-disable-next-line no-cond-assign
  } while (target = target.offsetParent)

  var pixelsPerStep = Math.max(MIN_PIXELS_PER_STEP,
    Math.abs(targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS)

  var isUp = targetY < scrollContainer.scrollTop

  var stepFunc = function () {
    const scrollElmReact = scrollElement.getBoundingClientRect()
    if ((scrollElmReact.top > 60) && (scrollElmReact.top < window.innerHeight)) {
      return
    }
    if (isUp) {
      scrollContainer.scrollTop = Math.max(targetY, scrollContainer.scrollTop - pixelsPerStep)
      if (scrollContainer.scrollTop <= targetY) {
        return
      }
    } else {
      scrollContainer.scrollTop = Math.min(targetY, scrollContainer.scrollTop + pixelsPerStep)

      if (scrollContainer.scrollTop >= targetY) {
        return
      }
    }

    const frameId = window.requestAnimationFrame(stepFunc)
    setTimeout(() => {
      window.cancelAnimationFrame(frameId)
    }, 50)
  }

  const animationId = window.requestAnimationFrame(stepFunc)
  setTimeout(() => {
    window.cancelAnimationFrame(animationId)
  }, 50)
}
