import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import appSagas from './app/saga'
import errorSagas from './error/saga'
import loadingSagas from './loading/saga'
import paymentSagas from './payment/saga'
import UserSaga from './user/saga'
import TransactionSaga from './transaction/saga'
import PolicySaga from './policy/saga'
import VerificationSaga from './verification/saga'
import Customer from './customer/saga'
import Lead from './lead/sagas'
import Motor from './motor/saga'
import Address from './Address/sagas'
import Property from './property/sagas'

export default function * rootSaga () {
  yield all([
    authSagas(),
    appSagas(),
    errorSagas(),
    loadingSagas(),
    paymentSagas(),
    UserSaga(),
    TransactionSaga(),
    PolicySaga(),
    VerificationSaga(),
    Customer(),
    Lead(),
    Motor(),
    Address(),
    Property()
  ])
}
