import { UploadFileContextProvider } from '../context'
import UploadBulkImgModalContainer from './container'

function UploadBulkImgModal (props) {
  return (
    <UploadFileContextProvider>
      <UploadBulkImgModalContainer {...props} />
    </UploadFileContextProvider>
  )
}

export default UploadBulkImgModal
