import { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import memoize from 'memoize-one'
import {
  AutoSearchModalStyle, SearchAgentInput, SearchItemsContainer,
  AddItemStyle
} from './index.style'
import { LANG_STRINGS } from '../../lang'
import InnerInput from '../InnerInput'
import { Row, DisplayFlexCCC, DisplayCircleIcon } from '../../commonStyle/index.style'
import { CustomText } from '..'
import { AgentColorPallette } from '../../configs/colorPallete'
import { ARROW_DOWN, ImageUrl, SEND_GOLD_ICON } from '../../images'
import CircularLoader from '../CircularLoader'
import { find } from 'lodash'
import { disableNativePullToRefresh, enableNativePullToRefresh } from '../../bridges/android'
import isEmpty from 'lodash/isEmpty'
import ButtonWithIcon from '../ButtonWithIcon'
import Button from '../Button'
import TitleError from '../TitleError'

class AutoSearchModal extends Component {
  static propTypes = {
    data: PropTypes.array,
    defaultProviders: PropTypes.object,
    disabled: PropTypes.bool,
    showCustomAddText: PropTypes.bool,
    isMultiple: PropTypes.bool, // this is used to show multiple values selection
    maxSelectCount: PropTypes.number,
    titleForMultiple: PropTypes.string,
    value: PropTypes.any,
    displayBorder: PropTypes.bool,
    showOther: PropTypes.bool,
    isOtherData: PropTypes.bool,
    name: PropTypes.string,
    subTitle: PropTypes.string,
    errorField: PropTypes.string,
    id: PropTypes.string,
    loading: PropTypes.string,
    displayKey: PropTypes.string,
    dataKey: PropTypes.string,
    title: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      query: '',
      showSearchModal: false,
      selectedItem: this.getDefaultSelected() || {}
    }
    this.isInitialDefaultSelect = false
    this.selectedItemCount = (props.defaultProviders && Object.keys(props.defaultProviders).length) || 0
  }

  componentDidUpdate (prevProps) {
    const { dataKey, data, defaultValue, defaultProviders } = this.props
    const isDefaultValueValid = `${defaultValue}`.trim()
    if (
      (this.props.value !== this.state.selectedItem[dataKey]) &&
      prevProps.value !== this.props.value
    ) {
      // this need to reset the modal state
      this.resetModalState()
    }
    if (
      defaultValue && isDefaultValueValid && !this.isInitialDefaultSelect &&
      (
        ((prevProps.data !== data) && data?.length) || (data?.length && isEmpty(this.state.selectedItem))
      )
    ) {
      this.isInitialDefaultSelect = true
      this.setState({
        selectedItem: this.getDefaultSelected() || {}
      })
    }
    if (
      defaultProviders && !this.isInitialDefaultSelect &&
      (
        ((prevProps.data !== data) && data?.length) || (data?.length && isEmpty(this.state.selectedItem))
      )
    ) {
      this.isInitialDefaultSelect = true
      this.setState({
        selectedItem: this.getDefaultSelected() || {}
      })
      this.selectedItemCount = Object.keys(defaultProviders).length
    }
  }

  getDefaultSelected = () => {
    const { data, defaultValue, dataKey, isMultiple, defaultProviders, isOtherData, displayKey } = this.props
    if (isMultiple && defaultProviders) {
      return defaultProviders
    }
    const findObj = find(data, { [dataKey]: defaultValue })
    if (findObj) {
      return findObj
    }
    if (isOtherData) {
      return {
        [displayKey]: defaultValue,
        [dataKey]: defaultValue
      }
    }
  }

  filterList = memoize((list, filterText) => list.length > 0 && list.filter(item => {
    const { displayKey } = this.props
    const displayItem = item[displayKey]
    if (displayItem && displayItem.toString().toLowerCase().includes(filterText.toString().toLowerCase())) {
      return item
    }

    return false
  }))

  handleToggleShowModal = () => {
    if (this.props.disabled) return
    if (this.props.handlePreClicked && !this.state.showSearchModal) {
      this.props.handlePreClicked()
    }
    if (!this.state.showSearchModal) {
      disableNativePullToRefresh()
    } else {
      enableNativePullToRefresh()
    }
    this.setState(prevState => ({
      showSearchModal: !prevState.showSearchModal
    }))
  }

  handleInputChange = (e) => {
    // handle input search change
    this.setState({
      query: e.currentTarget.value
    })
  }

  handleSearchItemClick = (item = {}) => {
    // here we assigned the click item to selected Item
    const { selectedItem } = this.state
    const { dataKey, onSelect, isMultiple, maxSelectCount, index } = this.props
    let selectItem = item
    if (selectedItem[item[dataKey]] || (item[dataKey] === selectedItem[dataKey])) {
      // if both matched the empty the value
      selectItem = {}
    }
    if (selectItem[dataKey] && isMultiple && (maxSelectCount === this.selectedItemCount)) {
      return
    }
    if (isMultiple) {
      // handle the multiple cases
      this.setState(({ selectedItem }) => {
        if (!selectItem[dataKey]) {
          this.selectedItemCount -= 1
          delete selectedItem[item[dataKey]]
        } else {
          selectedItem[selectItem[dataKey]] = selectItem
          this.selectedItemCount += 1
        }
        return {
          selectedItem
        }
      })
    } else {
      this.setState({
        selectedItem: selectItem
      })
      if (onSelect) {
        onSelect(selectItem[dataKey], selectItem, index)
        this.handleToggleShowModal()
      }
    }
  }

  resetModalState = () => {
    this.setState({
      selectedItem: {}
    })
    this.selectedItemCount = (this.props.defaultProviders && Object.keys(this.props.defaultProviders).length) || 0
  }

  isSelectedItemPresent = (listItem) => {
    const { selectedItem } = this.state
    const { dataKey, isMultiple } = this.props
    if (isMultiple) {
      const itemValue = listItem[dataKey]
      return !!selectedItem[itemValue]
    }
    return selectedItem[dataKey] === listItem[dataKey]
  }

  getMultipleItemName = (items) => {
    let name = ''
    const values = Object.values(items)
    const { displayKey } = this.props
    for (let index = 0; index < values.length; index++) {
      name += values[index][displayKey]
      if ((index + 1) !== values.length) {
        name += ', '
      }
    }
    return name
  }

  handleSave = () => {
    // handle the save button
    this.props.onSelect(this.state.selectedItem)
    this.handleToggleShowModal()
  }

  handleBackToSearch = () => {
    this.setState({
      addCustom: false
    })
  }

  handleCustomAdd = () => {
    this.setState({
      addCustom: true
    })
  }

  handleAddInputChange = (e) => {
    // store the value
    this.setState({
      addCustomValue: e.target.value,
      addError: false
    })
  }

  handleSaveNewItem = () => {
    // store the value
    const { displayKey, onSelect, name } = this.props
    if (!this.state.addCustomValue) {
      this.setState({
        addError: true
      })
      return
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        selectedItem: {
          [displayKey]: prevState.addCustomValue
        },
        addCustom: false,
        showSearchModal: false
      }
    }, () => {
      onSelect(this.state.selectedItem[displayKey], {
        [`${name}_other`]: true,
        [name]: this.state.selectedItem[displayKey]
      })
    })
  }

  renderEmptyData = () => {
    const { showCustomAddText, title } = this.props
    return (
      <DisplayFlexCCC className='search-items-empty'>
        <img
          className='empty-box'
          src={ImageUrl.emptyBox}
          alt='Empty'
        />
        <CustomText
          size='16px'
          weight={800}
          lineHeight={1.5}
          top='20px'
          color={AgentColorPallette.fontSecondary}
          textAlign='center'
          className='dependent-not-exist'
        >
          {LANG_STRINGS.noDataAtThisTime}
        </CustomText>
        {
          showCustomAddText && (
            <>
              <CustomText
                size='16px'
                weight={800}
                lineHeight={1.5}
                top='20px'
                color={AgentColorPallette.fontSecondary}
                textAlign='center'
                bottom='20px'
                className='dependent-not-exist'
              >
                {`Cek penulisan atau silakan tulis ${title} Anda`}
              </CustomText>
              <Button onClick={this.handleCustomAdd}>
                {LANG_STRINGS.write} {title}
              </Button>
            </>
          )
        }
      </DisplayFlexCCC>
    )
  }

  render () {
    const {
      showSearchModal, query, selectedItem = {},
      addError, addCustom
    } = this.state
    const {
      data = [], title, placeholder, loading, name, disabled,
      displayKey, dataKey, errorField, className, displayBorder,
      isMultiple, titleForMultiple, subTitle, showOther
    } = this.props
    const isLoader = !!(loading === name)
    const filterListItems = this.filterList(data, query)
    const selectedItemValue = selectedItem[displayKey]
    const isMultipleItemSelected = isMultiple && !isEmpty(selectedItem)
    return (
      <AutoSearchModalStyle
        error={errorField === name}
        className={className}
        displayBorder={displayBorder}
        disabled={disabled}
      >
        <Row
          className='search-type-input'
          justify='space-between'
          align='center'
          onClick={this.handleToggleShowModal}
          disabled={disabled}
        >
          <CustomText
            color={(selectedItemValue || isMultipleItemSelected) ? AgentColorPallette.black : AgentColorPallette.fontSecondary}
            size='12px'
          >
            {
              (isMultipleItemSelected ? `${this.selectedItemCount} ${titleForMultiple}` : selectedItemValue) || placeholder
            }
            {
              isMultipleItemSelected && (
                <div className='multiple-item-name'>
                  {this.getMultipleItemName(selectedItem)}
                </div>
              )
            }
          </CustomText>
          <img src={ARROW_DOWN} width='12' alt='' />
        </Row>
        {
          subTitle && (
            <CustomText
              color={AgentColorPallette.fontDarkerGrey}
              size='11px'
              bottom='4px'
              top='6px'
              className='inner-form-subtitle'
            >
              {subTitle}
            </CustomText>
          )
        }
        <Modal
          heading={`Pilih ${title}`}
          isOpen={showSearchModal}
          onClose={this.handleToggleShowModal}
        >
          {
            addCustom ? (
              <AddItemStyle>
                <TitleError
                  title={title}
                  required
                  error={addError && LANG_STRINGS.required}
                />
                <InnerInput
                  name={name}
                  error={addError && name}
                  placeholder={`${LANG_STRINGS.write} ${title}`}
                  displayBorder
                  onChange={this.handleAddInputChange}
                />
                <Row align='center' justify='center'>
                  <Button
                    onClick={this.handleSaveNewItem}
                    className='save-added-brand-btn'
                  >
                    {LANG_STRINGS.save}
                  </Button>
                </Row>
                <Row align='center' justify='center'>
                  <CustomText
                    size='14px'
                    weight={700}
                    lineHeight={1.5}
                    top='20px'
                    color={AgentColorPallette.darkerGold}
                    onClick={this.handleBackToSearch}
                    className='cursor'
                  >
                    {LANG_STRINGS.back}
                  </CustomText>
                </Row>
              </AddItemStyle>
            ) : (
              <AutoSearchModalStyle>
                <SearchAgentInput>
                  <InnerInput
                    name='search'
                    className='search-input'
                    value={this.state.query}
                    placeholder={placeholder}
                    onChange={this.handleInputChange}
                  />
                </SearchAgentInput>
                <CircularLoader isLoading={isLoader}>
                  <SearchItemsContainer multipleSelectionAllowed={isMultiple}>
                    {
                      filterListItems.length ? (
                        <>
                          {
                            filterListItems.map(item => (
                              <Row
                                key={item[dataKey]}
                                justify='space-between'
                                align='center'
                                className='search-items'
                                onClick={() => this.handleSearchItemClick(item)}
                              >
                                <CustomText
                                  size='13px'
                                  weight='600'
                                  lineHeight={1.5}
                                  color={AgentColorPallette.black}
                                  className='search-item'
                                >
                                  {item[displayKey]}
                                </CustomText>
                                {
                                  (isMultiple || this.isSelectedItemPresent(item)) && (
                                    <DisplayCircleIcon
                                      width='18px'
                                      height='18px'
                                      bgColor={this.isSelectedItemPresent(item) ? AgentColorPallette.primary : AgentColorPallette.saturatedGrey}
                                    >
                                      <i className={`fa fa-check selected-icon-size ${this.isSelectedItemPresent(item) ? 'search-selected-item' : ''}`} />
                                    </DisplayCircleIcon>
                                  )
                                }
                              </Row>
                            ))
                          }
                          {
                            showOther && (
                              <Row
                                key='other'
                                justify='space-between'
                                align='center'
                                className='search-items'
                                onClick={() => this.handleCustomAdd()}
                              >
                                <CustomText
                                  size='13px'
                                  weight='600'
                                  lineHeight={1.5}
                                  color={AgentColorPallette.black}
                                >
                                  {LANG_STRINGS.others}
                                </CustomText>
                              </Row>
                            )
                          }
                        </>
                      ) : !isLoader && this.renderEmptyData()
                    }
                  </SearchItemsContainer>
                  {
                    isMultiple && (
                      <div className='save-selected-item'>
                        <ButtonWithIcon
                          text={LANG_STRINGS.save}
                          icon={SEND_GOLD_ICON}
                          iconRight
                          onClick={this.handleSave}
                          disabled={!data.length}
                        />
                      </div>
                    )
                  }
                </CircularLoader>
              </AutoSearchModalStyle>
            )
          }
        </Modal>
      </AutoSearchModalStyle>
    )
  }
}

export default AutoSearchModal
