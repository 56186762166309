import { Component } from 'react'
import { LoaderWrapper } from './index.style'

class Loader extends Component {
  render () {
    const { visible, children, bgColor, className } = this.props
    return (
      <LoaderWrapper bgColor={bgColor} className={className}>
        {
          visible &&
            <div className='loader-image-container'>
              <div className='loader-height'>
                <div className='lds-roller'><div /><div /><div /><div /><div /><div /><div /><div /></div>
              </div>
            </div>
        }
        {
          children
        }
      </LoaderWrapper>
    )
  }
}

export default Loader
