import styled from 'styled-components'

export const ZoomImgContainer = styled.div`
  height: 100%;
  display: flex;
  align: center;
  justify-content: center;
  flex-direction: column;
`

export const ZoomImgStyle = styled.div`
  .zoom-img-wrapper {
    position: fixed;
    left:0;
    right: 0;
    top: -100%;
    width: 100%;
    background: rgba(0,0,0,.7);
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    overflow: hidden;
    z-index: 99;
  }

  .zoom-img-parent {
    height: 100vh;
    position: relative;
    margin: 0 20px;
  }

  .img-zoom {
    margin: auto;
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
    max-height: 0%;
    max-width: 0%;
    border: 3px solid white;
    box-shadow: 0px 0px 8px rgba(0,0,0,.3);
    box-sizing: border-box;
    -webkit-transition: .6s ease-in-out;
    -moz-transition: .6s ease-in-out;
    -o-transition: .6s ease-in-out;
    transition: .6s ease-in-out;
  }

  .zoom-img-close {
    display: block;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    background: white;
    color: black;
    text-decoration: none;
    position: absolute;
    right: 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .zoom-img-close:before {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    background: black;
    position: absolute;
    top:10px;
    z-index: 100;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
  }

  .zoom-img-close:after {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    background: black;
    position: absolute;
    z-index: 100;
    top:10px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }

  .zoom-img-wrapper-target {
    opacity: 1;
    top: 0;
    bottom: 0;
    overflow: scroll;
  }

  .zoom-img-target {
    max-height: calc(100vh - 20%) !important;
    max-width: 100%;
  }

  .zoom-img-target-close {
    top: 0;
  }

`
