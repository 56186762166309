import styled from 'styled-components'

export const ShowSnackbarStyle = styled.div`
  position: fixed;
  bottom: 6%;
  left: 0;
  right: 0;
  max-width: 450px;
  margin: 0 auto;
  z-index: 999999999;
  visibility: hidden; /* Hidden by default. Visible on click */
  color: ${props => props.theme.white}; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  bottom: 30px;

  .snack-bar-box {
    background-color: ${props => props.error ? props.theme.lightPink : props.theme.regular};
    border-radius: 4px;
    padding: 18px;
    margin: 0 20px;
  }
`
