const actions = {
  GET_TRANSACTION_LIST_REQUEST: 'GET_TRANSACTION_LIST_REQUEST',
  GET_TRANSACTION_LIST_SUCCESS: 'GET_TRANSACTION_LIST_SUCCESS',
  GET_TRANSACTION_LIST_FAILURE: 'GET_TRANSACTION_LIST_FAILURE',

  GET_POINTS_LIST_REQUEST: 'GET_POINTS_LIST_REQUEST',
  GET_POINTS_LIST_SUCCESS: 'GET_POINTS_LIST_SUCCESS',
  GET_POINTS_LIST_FAILURE: 'GET_POINTS_LIST_FAILURE',

  GET_BANK_LIST_REQUEST: 'GET_BANK_LIST_REQUEST',
  GET_BANK_LIST_SUCCESS: 'GET_BANK_LIST_SUCCESS',
  GET_BANK_LIST_FAILURE: 'GET_BANK_LIST_FAILURE',

  GET_WITHDRAWAL_LIST_REQUEST: 'GET_WITHDRAWAL_LIST_REQUEST',
  GET_WITHDRAWAL_LIST_SUCCESS: 'GET_WITHDRAWAL_LIST_SUCCESS',
  GET_WITHDRAWAL_LIST_FAILURE: 'GET_WITHDRAWAL_LIST_FAILURE',

  BANK_WITHDRAW_REQUEST: 'BANK_WITHDRAW_REQUEST',
  BANK_WITHDRAW_SUCCESS: 'BANK_WITHDRAW_SUCCESS',
  BANK_WITHDRAW_FAILURE: 'BANK_WITHDRAW_FAILURE',

  CONVERT_POINTS_REQUEST: 'CONVERT_POINTS_REQUEST',
  CONVERT_POINTS_SUCCESS: 'CONVERT_POINTS_SUCCESS',
  CONVERT_POINTS_FAILURE: 'CONVERT_POINTS_FAILURE',

  RESET_TRANSACTION_STORE: 'RESET_TRANSACTION_STORE',

  getTransactionList: (payload) => ({
    type: actions.GET_TRANSACTION_LIST_REQUEST,
    payload
  }),
  getPointsList: (payload) => ({
    type: actions.GET_POINTS_LIST_REQUEST,
    payload
  }),
  getBankList: () => ({
    type: actions.GET_BANK_LIST_REQUEST
  }),
  getWithdrawalList: (payload) => ({
    type: actions.GET_WITHDRAWAL_LIST_REQUEST,
    payload
  }),
  convertPointsRequest: (payload) => ({
    type: actions.CONVERT_POINTS_REQUEST,
    payload
  }),
  resetTransactionRequest: payload => ({
    type: actions.RESET_TRANSACTION_STORE,
    payload
  }),
  requestBankWithdraw: payload => ({
    type: actions.BANK_WITHDRAW_REQUEST,
    payload
  })
}

export default actions
