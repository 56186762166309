import styled from 'styled-components'

export const TimePickerWrapper = styled.div`
  border-radius: 10px;
  padding: 16px;
  cursor: pointer;
  background-color: ${props => props.theme.white};
  border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.theme.grey}` : 'none'};
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 1%), 0 6px 16px 0 rgb(0 0 0 / 6%);

  margin-bottom: 1rem;
  .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
    content: none;
  }
  
  .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after {
    border: none;
  }

  .mbsc-ios.mbsc-textfield {
    padding: 0;
    height: auto;
    color: ${props => props.theme.black};
    outline: none;
  }
`
