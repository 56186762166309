const actions = {
  GET_PAYMENT_TOKEN_REQUEST: 'GET_PAYMENT_TOKEN_REQUEST',
  GET_PAYMENT_TOKEN_SUCCESS: 'GET_PAYMENT_TOKEN_SUCCESS',
  GET_PAYMENT_TOKEN_FAILURE: 'GET_PAYMENT_TOKEN_FAILURE',

  GET_PAYMENT_DETAIL_REQUEST: 'GET_PAYMENT_DETAIL_REQUEST',
  GET_PAYMENT_DETAIL_SUCCESS: 'GET_PAYMENT_DETAIL_SUCCESS',
  GET_PAYMENT_DETAIL_FAILURE: 'GET_PAYMENT_DETAIL_FAILURE',

  GET_VA_BANK_LIST_REQUEST: 'GET_VA_BANK_LIST_REQUEST',
  GET_VA_BANK_LIST_SUCCESS: 'GET_VA_BANK_LIST_SUCCESS',
  GET_VA_BANK_LIST_FAILURE: 'GET_VA_BANK_LIST_FAILURE',

  GET_PAYMENT_STATUS_REQUEST: 'GET_PAYMENT_STATUS_REQUEST',
  GET_PAYMENT_STATUS_SUCCESS: 'GET_PAYMENT_STATUS_SUCCESS',
  GET_PAYMENT_STATUS_FAILURE: 'GET_PAYMENT_STATUS_FAILURE',

  CALCULATE_SERVICE_CHARGE_REQUEST: 'CALCULATE_SERVICE_CHARGE_REQUEST',
  CALCULATE_SERVICE_CHARGE_SUCCESS: 'CALCULATE_SERVICE_CHARGE_SUCCESS',
  CALCULATE_SERVICE_CHARGE_FAILURE: 'CALCULATE_SERVICE_CHARGE_FAILURE',

  PAY_LATER_REQUESTS: 'PAY_LATER_REQUESTS',
  PAY_LATER_SUCCESS: 'PAY_LATER_SUCCESS',
  PAY_LATER_FAILURE: 'PAY_LATER_FAILURE',

  RESET_PAYMENT_REDUX: 'RESET_PAYMENT_REDUX',

  resetPaymentRedux: payload => ({
    type: actions.RESET_PAYMENT_REDUX,
    payload
  }),
  getPaymentToken: payload => ({
    type: actions.GET_PAYMENT_TOKEN_REQUEST,
    payload
  }),
  getPaymentDetail: payload => ({
    type: actions.GET_PAYMENT_DETAIL_REQUEST,
    payload
  }),
  getVaBankList: payload => ({
    type: actions.GET_VA_BANK_LIST_REQUEST,
    payload
  }),
  calculateServiceCharge: payload => ({
    type: actions.CALCULATE_SERVICE_CHARGE_REQUEST,
    payload
  }),
  getPaymentStatus: payload => ({
    type: actions.GET_PAYMENT_STATUS_REQUEST,
    payload
  }),
  getPayLaterRequest: payload => ({
    type: actions.PAY_LATER_REQUESTS,
    payload
  })
}

export default actions
