import { createContext, useContext, useReducer } from 'react'
import reducer, { INITIAL_STATE } from '../state/reducer'

export const UploadFileContext = createContext()

export const UploadFileContextProvider = ({ children }) => {
  return (
    <UploadFileContext.Provider value={useReducer(reducer, INITIAL_STATE)}>
      {children}
    </UploadFileContext.Provider>
  )
}

export const useUploadFileContextValue = () => useContext(UploadFileContext)
