import { Datepicker } from '@mobiscroll/react'
import moment from 'moment'
import { useCallback, useState } from 'react'
import { TimePickerWrapper } from './index.style'

function TimePickerOld ({
  name, onChange = () => {}, errorField,
  defaultValue = '', error, minTime, maxTime
}) {
  const [selected, setSelected] = useState(defaultValue)
  const handleOnChange = useCallback((e) => {
    const date = new Date(e.value)
    setSelected(date)
    onChange({
      name,
      value: date
    })
  }, [name, onChange])
  return (
    <TimePickerWrapper
      error={errorField === name}
    >
      <Datepicker
        controls={['time']}
        touchUi
        class='timer-label'
        returnFormat='moment'
        defaultValue={selected ? new Date(selected) : ''}
        onChange={handleOnChange}
        min={new Date()}
        max={moment().endOf('day')._d}
      />
    </TimePickerWrapper>
  )
}

export default TimePickerOld
