import styled from 'styled-components'

export const AutoSearchStyle = styled.div`

  .react-autosuggest__container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
    position: relative;
  }

  .react-autosuggest__input {
    margin: 6px 0px;
    padding: 4px 35px 4px 12px;
    border: ${props => props.error ? `2px solid  ${props.theme.errorDark}` : 'none'};
    background-color: ${props => props.theme.white};
    border-radius: 6px;
    height: 36px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
    background-repeat: no-repeat;
    background-position: 97% center;
    background-size: 12px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
    &::-ms-expand {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    left: 0;
    right: 0;
    padding: 5px 16px 10px 16px;
    max-height: 150%;
    overflow: scroll;
    z-index: 10;
    background-color: ${props => props.theme.white};
    box-shadow: 0 12px 12px 0 rgba(0,0,0,0.01), 0 10px 20px 0 rgba(0,0,0,0.06);
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:hover {
        background-color: ${props => props.theme.fontLightGrey};
        cursor: pointer;
      }
    }
  }

  .auto-suggestion-value {
    padding: 8px;
    border-bottom: 1px solid ${props => props.theme.grey_eee};
    font-size: 12px;
  }
`
