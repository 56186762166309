import styled from 'styled-components'

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  .radio-wrapper {
    background-color: ${props => props.disabled ? props.theme.saturatedGrey : props.theme.white};
    padding: 16px 13px;
    width: 100%;
    margin: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 12px;
    color: ${props => props.theme.black};
    box-shadow: ${props => props.boxShadow || '0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06)'};
    cursor: pointer;
  }

  .radio-label {
    font-size: 12px;
    color: ${props => props.theme.black};
    margin-right: 4px;
  }
`

export const RadioBox = styled.div`
  display: inline-block;
  margin: 0;
  cursor: pointer;
  margin-right: ${props => (props.marginRight ? props.marginRight : 0)}px;
  width: ${props => (props.size ? props.size : 20)}px;
  height: ${props => (props.size ? props.size : 20)}px;
  min-width: ${props => (props.size ? props.size : 20)}px;
  min-height: ${props => (props.size ? props.size : 20)}px;
  position: relative;
  label {
    margin-left: 25px;
  }
  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid
      ${props => (props.borderColor ? props.borderColor : '#DDD')};
    background: ${props =>
      props.backgroundColor ? props.backgroundColor : '#FAFAFA'};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`

export const Fill = styled.div`
  background: ${props => (props.fillColor ? props.fillColor : props.theme.black)};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 2px);
    position: absolute;
    height: calc(20px - 2px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid
      ${props => (props.borderActive ? props.borderActive : props.theme.black)};
    border-radius: 100%;
  }
`

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`
