import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MitraCard, Row } from '../../../../commonStyle/index.style'
import { AgentColorPallette } from '../../../../configs/colorPallete'
import { UPLOAD_GREY } from '../../../../images'
import { LANG_STRINGS } from '../../../../lang'
import CustomText from '../../../CustomText'
import { useUploadFileContextValue } from '../../context'
import { resetUploadFileState } from '../../state/actions'
import { useUploadFile } from '../../state/hooks/useUploadFileState'
import ShowSnackbar from '../../../ShowNotification'
import BulkImgGalleryModal from '../components/BulkImgGalleryModal'
import { UploadBulkImgModalStyle, ImgUploadProgress, ImgUploadSuccessProgressBar } from './index.style'

function UploadBulkImgModalContainer ({
  name, fileType, label, errorField,
  onUpload = () => {}, defaultValue
}) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploadedImgCount, setUploadImgCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [isImgUploading, setIsImgUploading] = useState(false)
  const [uploadedImgs, setUploadedImgs] = useState(defaultValue || [])

  const [, dispatchUploadFile] = useUploadFileContextValue()
  const [{ getUploadLinkData }, uploadFileRequest] = useUploadFile()
  const that = useRef({
    uploadCount: 0,
    totalFiles: 0
  })

  const uploadFilePercentage = useMemo(() => {
    return ((uploadedImgCount) * 100) / selectedFiles.length
  }, [uploadedImgCount, selectedFiles.length])

  const uploadFiles = useCallback(() => {
    const file = that.current.files[that.current.uploadCount]
    if (file.size > 11000000) {
      ShowSnackbar.error({
        visible: true,
        text: LANG_STRINGS.fileSizeLarge
      })
      return
    }
    ++that.current.uploadCount
    const formData = new FormData()
    formData.append('file', file)
    uploadFileRequest({
      body: formData
    })
  }, [uploadFileRequest])

  useEffect(() => {
    if (getUploadLinkData?.url) {
      dispatchUploadFile(resetUploadFileState({
        getUploadLinkData: undefined
      }))
      setUploadedImgs((uploadedImgs) => {
        const newData = [...uploadedImgs, getUploadLinkData?.url]
        onUpload(newData)
        return newData
      })
      setUploadImgCount(that.current.uploadCount)
      if ((that.current.uploadCount + 1) <= selectedFiles.length) {
        uploadFiles()
      } else {
        setIsImgUploading(false)
        setUploadImgCount(0)
      }
    }
  }, [
    dispatchUploadFile, getUploadLinkData, uploadFiles,
    selectedFiles.length, onUpload
  ])

  const handleStopUpload = useCallback(() => {
    // to stop the next upload just increase the length of the current
    // count to greater than the images length
    that.current.uploadCount = (selectedFiles.length + 1)
  }, [selectedFiles.length])

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleUpload = useCallback((e) => {
    const file = document.getElementById(name)
    if ('files' in file) {
      that.current.totalFiles = file.files.length
      that.current.files = file.files
      that.current.uploadCount = 0
      setSelectedFiles(file.files)
      setIsImgUploading(true)
      uploadFiles()
      ShowSnackbar.success({
        visible: true,
        text: LANG_STRINGS.uploadStart
      })
    }
  }, [name, uploadFiles])

  const handleToShowGallery = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleDeleteImgs = useCallback((finalImgs) => {
    onUpload(finalImgs)
    setUploadedImgs(finalImgs)
  }, [onUpload])

  const renderProgressContainer = useCallback(() => {
    return (
      <div className='uploaded-file-progress'>
        <ImgUploadProgress>
          <ImgUploadSuccessProgressBar right={`${100 - uploadFilePercentage}%`} />
        </ImgUploadProgress>
        <Row align='center' justify='space-between'>
          <CustomText size='10px' color={AgentColorPallette.fontDarkerGrey}>
            {`${uploadedImgCount} / ${selectedFiles.length}`}
          </CustomText>
          <CustomText
            size='10px'
            color={AgentColorPallette.maroonRed}
            weight={800}
            className='cursor'
            onClick={handleStopUpload}
          >
            {LANG_STRINGS.stopIt}
          </CustomText>
        </Row>
      </div>
    )
  }, [uploadedImgCount, selectedFiles, uploadFilePercentage, handleStopUpload])

  const renderFileUploadBtn = useCallback(() => {
    return (
      <>
        <Row align='center' justify='space-between' className='upload-file-btn-box'>
          <span className='upload-btn-text'>
            {LANG_STRINGS.upload}
          </span>
          <img src={UPLOAD_GREY} alt='UPLOAD_GREY' />
          <input
            type='file'
            id={name}
            name={name}
            onChange={handleUpload}
            className='upload-file-input'
            accept={fileType || 'image/*'}
            multiple
          />
        </Row>
        {
          !!uploadedImgs.length && (
            <Row
              align='center'
              justify='space-between'
              className='upload-file-count'
              onClick={handleToShowGallery}
            >
              <CustomText size='14px' weight={600} color={AgentColorPallette.fontDarkerGrey}>
                {LANG_STRINGS.file}
              </CustomText>
              <CustomText color={AgentColorPallette.black} weight={600}>
                {uploadedImgs.length}&nbsp;&nbsp;
                <i className='fas fa-angle-right' />
              </CustomText>
            </Row>
          )
        }
      </>
    )
  }, [
    handleUpload, handleToShowGallery, fileType,
    name, uploadedImgs
  ])

  return (
    <UploadBulkImgModalStyle error={errorField === name}>
      <MitraCard width='auto' padding='0px' marginTop='0px'>
        {
          isImgUploading ? renderProgressContainer() : renderFileUploadBtn()
        }
      </MitraCard>
      {
        showModal && (
          <BulkImgGalleryModal
            show={showModal}
            onClose={handleCloseModal}
            uploadedImgs={uploadedImgs}
            title={label}
            onDelete={handleDeleteImgs}
          />
        )
      }
      <ShowSnackbar />
    </UploadBulkImgModalStyle>
  )
}

export default UploadBulkImgModalContainer
