import styled from 'styled-components'

export const ContainerWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 450px;
  margin: auto;
  background-color: ${props => props.theme.fontLightGrey};
  position: relative;

  .header-info{
    z-index: 2;
    top: ${props => props.isGreetingHeader ? '56px' : '46px'};
    position: fixed;
    background-color: ${props => props.theme.maroonRed};
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
    padding: 10px 16px;
  }

  .header-info-balancer{
    height: 44px;
  }

  .footer-container {
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    max-width: 418px;
    height: 120px;
    text-align: center;
    padding: 16px;
    background-color: ${props => props.theme.white};
  }

  .ojk-logo {
    padding-top: 5px;
    width: 200px;
    display: inline-block !important;
  }

  @media only screen and (min-width: 450px) {
    border-left: 1px solid ${props => props.theme.white};
    border-right: 1px solid ${props => props.theme.white};
  }

  @media only screen and (max-width: 823px) and (orientation: landscape){
    max-width: 100%;
    margin: none;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) { 
    max-width: 100%;
    margin: none;
  }
`
