import styled from 'styled-components'

export const BottomActionSheetStyle = styled.div`
  position: ${props => props.show ? 'fixed' : 'relative'};
  bottom: ${props => props.bottom || 0};
  top: 0;
  left: 0;
  right: 0;
  z-index: ${props => props.show ? (props.zIndex || 2) : 0};
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100vh;
  background-color: ${props => props.show ? props.theme.lightBlack : 'transparent'};
  transition: all ease 0.7s;

  .help-agent-header {
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
    padding: 10px;
    background-color: ${props => props.backGroundColor ? '#fac800 ': ''};
  }

  .help-title {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    grid-auto-rows: 30px;
    text-align: ${props => props.headerTextAlign || 'center'};
  }

  .close-icon {
    grid-row: 1 / 2;
    grid-column: 2;
    z-index: 2;
    cursor: pointer;
    img {
      width: 24px;
    }
  }
  


  .bottom-sheet-container {
    background-color: ${props => props.theme.fontLightGrey};
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 0%;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-width: 450px;
    transition: max-height 0.5s linear;
  }

  .animation-show {
    max-height: ${props => props.maxHeight || '98%'};
    transition: max-height 0.5s linear;
    overflow-y: auto;
  }
`
