import { Component } from 'react'
import PropTypes from 'prop-types'
import {
  CheckboxContainer, HiddenCheckbox, StyledCheckbox,
  Icon
} from './index.style'

class CustomCheckbox extends Component {
  static propTypes = {
    isChecked: PropTypes.bool,
    isTransparent: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    bgColor: PropTypes.string,
    color: PropTypes.string,
    onChange: PropTypes.func
  }

  render () {
    const { name, id, onChange, className, isChecked, isTransparent, bgColor, color, disabled } = this.props
    return (
      <CheckboxContainer className={className}>
        <HiddenCheckbox
          name={name}
          id={id}
          onChange={e => {
            if (disabled) return
            onChange(e, name)
          }}
          checked={isChecked}
          isTransparent={isTransparent}
          disabled={disabled}
          {...this.props}
        />
        <StyledCheckbox
          checked={isChecked}
          onClick={e => {
            if (disabled) return
            onChange(e, name)
          }}
          isTransparent={isTransparent}
          bgColor={bgColor}
          color={color}
          disabled={disabled}
        >
          <Icon viewBox='0 0 24 24' color={color}>
            <polyline points='20 6 9 17 4 12' />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
    )
  }
}

export default CustomCheckbox
