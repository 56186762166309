export function preCacheImageInIos (imgUrl, callback = () => {}) {
  try {
    /**
     * Caches the image on device.
     * @param {string}   method   Method name called in native iOS.
     * @param {string}   imageUrl Url of image to be cached.
     * @return {void}    void
     * @throws {ReferenceError} if "callbackHandler" was not found on client (with web browsers and Android devices)
     */
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'preCacheImage',
      imageUrl: imgUrl
    })
  } catch (error) {
    // callback call if error
    callback(error, imgUrl)
  }
}

export function shareWithImageInIos (shareData, callback = () => {}) {
  try {
    /**
     * Share the image with message and subject when phone number IS NOT provided.
     * @param {string}   method           Method name called in native iOS.
     * @param {string}   image            image url.
     * @param {string}   subject          subject.
     * @param {string}   message          message.
     * @return {void}    void
     * @throws {ReferenceError} if "callbackHandler" was not found on client (can happen with web browsers and Android devices)
     */
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'shareWithImage',
      image: shareData.imgUrl,
      subject: shareData.subject,
      message: shareData.message
    })
  } catch (error) {
    // callback call if error
    callback(error, shareData)
  }
}

export function shareImageWithMobInIos (shareData, callback = () => {}) {
  try {
    /**
     * Share the image with message and subject when phone number IS provided.
     * @param {string}   method           Method name called in native iOS.
     * @param {string}   imageUrl         Image url.
     * @param {string}   subject          Subject
     * @param {string}   message          Message
     * @param {string}   phoneNumber      Phone number entered by the user.
     * @return {void}    void
     * @throws {ReferenceError} if "callbackHandler" was not found on client (can happen with web browsers and Android devices)
     */
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'shareImageWithMob',
      imageUrl: shareData.imgUrl,
      subject: shareData.subject,
      message: shareData.message,
      phoneNumber: shareData.phoneNumber
    })
  } catch (error) {
    // callback call if error
    callback(error, shareData)
  }
}

export function shareWithMobInIos (shareData, callback = () => {}) {
  try {
    /**
     * Share the message and subject when phone number IS provided.
     * @param {string}   method           Method name called in native iOS.
     * @param {string}   subject          Subject.
     * @param {string}   message          Message.
     * @param {string}   phoneNumber      Phone number entered by the user.]
     * @return {void}    void
     * @throws {ReferenceError} if "callbackHandler" was not found on client (can happen with web browsers and Android devices)
     */
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'shareWithMob',
      subject: shareData.subject,
      message: shareData.message,
      phoneNumber: shareData.phoneNumber
    })
  } catch (error) {
    // callback call if error
    callback(error, shareData)
  }
}

export function downloadFileInIos (url, name = `documents-${Date.now()}.pdf`, callback = () => {}) {
  try {
    /**
     * Share the message and subject when phone number IS provided.
     * @param {string}   method           Method name called in native iOS.
     * @param {string}   url              Download file url
     * @param {string}   fileName         fileName.
     * @return {void}    void
     * @throws {ReferenceError} if "callbackHandler" was not found on client (can happen with web browsers and Android devices)
     */
    window.response = (bridgeMsg) => {
      const msg = JSON.stringify(bridgeMsg)
      const obj = JSON.parse(msg)
      callback(obj, url, name)
    }
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'downloadFile',
      url,
      fileName: name
    })
  } catch (error) {
    // callback call if error
    callback(error, url, name)
  }
}

export function getFCMToken (callback = () => {}) {
  return new Promise((resolve) => {
    window.response = function response (fcmData) {
      resolve(fcmData)
    }
    window.webkit.messageHandlers.callbackHandler.postMessage({
      method: 'getFCMToken'
    })
  })
}

export function getLatestAppVersion (callback = () => {}) {
  return new Promise((resolve) => {
    window.response = function response (data) {
      resolve(data)
    }
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'getVersionCode'
      })
    } else {
      resolve()
    }
  })
}

export function getFCMPayload () {
  return new Promise((resolve) => {
    window.response = function response (bridgeData) {
      resolve(bridgeData)
    }
    if (window?.webkit?.messageHandlers?.callbackHandler?.postMessage) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'getFCMPayload'
      })
    } else {
      resolve({})
    }
  })
}

export function saveFCMToken (token = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'saveFCMToken',
        token
      })
    }
    resolve()
  })
}

export const enableNativePullToRefresh = () => {
  return new Promise((resolve) => {
    if (window?.webkit?.enablePullToRefresh) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'enablePullToRefresh'
      })
    }
    resolve()
  })
}

export const disableNativePullToRefresh = () => {
  return new Promise((resolve) => {
    if (window?.webkit?.disablePullToRefresh) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'disablePullToRefresh'
      })
    }
    resolve()
  })
}

export function webengageLogInEventInIos (userId = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageLogInEvent',
        userID: userId
      })
    }
    resolve()
  })
}

export function webengageLogOutEventInIos () {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageLogOutEvent'
      })
    }
    resolve()
  })
}
export function webengageUserSignedUpInIos () {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageUserSignedUp'
      })
    }
    resolve()
  })
}

export function webengageUserPhoneInIos (phoneNumber = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageUserPhone',
        phoneNumber: phoneNumber
      })
    }
    resolve()
  })
}

export function webengageUserNameInIos (firstName = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageUserName',
        firstName: firstName
      })
    }
    resolve()
  })
}

export function webengageUserEmailInIos (email = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageUserEmail',
        email: email
      })
    }
    resolve()
  })
}

export function webengageScreenVisitedInIos (args = '') {
  return new Promise((resolve) => {
    if (window?.webkit?.messageHandlers) {
      window.webkit.messageHandlers.callbackHandler.postMessage({
        method: 'webengageScreenVisited',
        args: args
      })
    }
    resolve()
  })
}
