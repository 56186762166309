export const gTagForUserId = (id, agentType, source = 'mitra') => {
  // this function fires google tag on click install to tag manager
  window.dataLayer.push({
    event: 'ppUserIdEvent',
    ppUserId: id,
    agentType,
    ppUserSource: source
  })
}

export const gTagOnGojekReferralBanner = () => {
  window.dataLayer.push({
    event: 'mitraGojekBannerEvent'
  })
}

export const gTagOnGojekVideoPlay = () => {
  window.dataLayer.push({
    event: 'mitraVideoEvent'
  })
}

export const gTagOnSendPaymentViaWhatsapp = () => {
  window.dataLayer.push({
    event: 'mitraSendPaymentLinkViaWhatsapp'
  })
}

export const gTagOnSendSendQuotationWA = () => {
  window.dataLayer.push({
    event: 'mitraSendQuotationViaWhatsapp'
  })
}

export const gTagOnLoginSecondScreen = () => {
  window.dataLayer.push({
    event: 'loginScreenEvent'
  })
}

export const gTagOnQuoteCreatedBy = (createdBy, id, type) => {
  window.dataLayer.push({
    event: 'quoteCreatedBy',
    quoteCreatedBy: createdBy,
    quoteProductType: type,
    ppUserId: id
  })
}

export const gTagOnBottomTabsClick = (tab) => {
  window.dataLayer.push({
    event: 'Mitra Bottom Tabs Click',
    tab
  })
}
