const actions = {
  GET_LEAD_LIST_REQUEST: 'GET_LEAD_LIST_REQUEST',
  GET_LEAD_LIST_SUCCESS: 'GET_LEAD_LIST_SUCCESS',
  GET_LEAD_LIST_FAILURE: 'GET_LEAD_LIST_FAILURE',

  UPDATE_LEAD_REQUEST: 'UPDATE_LEAD_REQUEST',
  UPDATE_LEAD_SUCCESS: 'UPDATE_LEAD_SUCCESS',
  UPDATE_LEAD_FAILURE: 'UPDATE_LEAD_FAILURE',

  REMOVE_LEAD_REQUEST: 'REMOVE_LEAD_REQUEST',
  REMOVE_LEAD_SUCCESS: 'REMOVE_LEAD_SUCCESS',
  REMOVE_LEAD_FAILURE: 'REMOVE_LEAD_FAILURE',

  OPEN_LEAD_REQUEST: 'OPEN_LEAD_REQUEST',
  OPEN_LEAD_SUCCESS: 'OPEN_LEAD_SUCCESS',
  OPEN_LEAD_FAILURE: 'OPEN_LEAD_FAILURE',

  RESET_LEAD_STORE: 'RESET_LEAD_STORE',

  getLeadList: payload => ({
    type: actions.GET_LEAD_LIST_REQUEST,
    payload
  }),
  updateLeadRequest: payload => ({
    type: actions.UPDATE_LEAD_REQUEST,
    payload
  }),
  removeLeadRequest: payload => ({
    type: actions.REMOVE_LEAD_REQUEST,
    payload
  }),
  openLeadRequest: payload => ({
    type: actions.OPEN_LEAD_REQUEST,
    payload
  }),
  resetLeadStore: payload => ({
    type: actions.RESET_LEAD_STORE,
    payload
  })
}

export default actions
