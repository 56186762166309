import styled from 'styled-components'
import { INPUT_CALENDAR_SVG } from '../../images'

export const DobInputStyle = styled.div`
  margin-bottom: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .formate-dob-input {
    border-radius: 10px;
    padding: 16px;
    flex: 1;
    outline: none;
    background-color: ${props => props.disabled ? props.theme.saturatedGrey : props.theme.white};
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
    border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.borderColor || props.theme.black}` : 'none'};

    ::placeholder {
      color: ${props => props.theme.fontGrey};
      font-size: 12px;
      letter-spacing: normal;
    }
  }

  .input-calendar-icon {
    background-image: url(${INPUT_CALENDAR_SVG});
    background-repeat: no-repeat;
    background-position: 97% center;
    background-color: ${props => props.disabled ? props.theme.saturatedGrey : props.theme.white};
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  }

  .agent-calendar {
    position: absolute;
    top: 33px;
    border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.theme.black}` : 'none'};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 2;

    .react-calendar__tile--active {
      background-color: ${props => props.theme.primary};
      color: ${props => props.theme.black};
    }

    .react-calendar__tile--now {
      background-color: ${props => props.theme.darkGold};
    }
  }
`
