import { Component } from 'react'
import PropTypes from 'prop-types'
import { FormWrapper, CheckBoxStyle } from './index.style'
import Input from '../Input'
import Button from '../Button'
import { checkEmpty, typeValid } from '../../helpers/validations'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import CustomCheckbox from '../CustomCheckbox'
import NumericInput from '../NumericInput'
import { ERROR_LANG } from '../../lang'
import Select from '../Select'
import { getNameErrorMsg } from '../../helpers/utility'

class Form extends Component {
  // form Data
  static propTypes = {
    formName: PropTypes.string,
    formData: PropTypes.object,
    backgroundColor: PropTypes.string,
    onGTagFunction: PropTypes.func,
    onFormChange: PropTypes.func,
    className: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      errorField: '',
      isChecked: false,
      isBtnDisable: true,
      errorValue: ''
    }
  }

  componentDidMount () {
    this.props.formData.fields.forEach((field) => {
      if (field.type === 'radio') {
        this.setState({ [field.name]: field.value[0] })
      }
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    const { formData } = this.props
    let error = false
    await formData.fields.forEach((field) => {
      if (!error) {
        let firstError = false
        if (field.required && checkEmpty(this.state[field.name])) {
          error = true
          firstError = true
          const errorText = field.error || 'Data tidak boleh kosong'
          NotificationManager.error(errorText)
          this.setState({ errorField: field.name, errorValue: errorText })
        }
        if (field.required && !firstError && !typeValid(field.type, this.state[field.name])) {
          const value = this.state[field.name]
          error = true
          let errorText = field.error || ERROR_LANG.requiredField
          if (field.type === 'name') {
            errorText = getNameErrorMsg(value)
          }
          NotificationManager.error(errorText)
          this.setState({ errorField: field.name, errorValue: errorText })
        } else if (!firstError) {
          this.setState({ errorField: '', errorValue: '' })
        }
      }
    })
    if (error && this.props.onGTagFunction) {
      setTimeout(() => {
        if (this.state.errorField) {
          this.props.onGTagFunction(this.state.errorField)
        }
      }, 100)
    }
    if (!error) {
      const formValues = this.state
      delete formValues.error
      delete formValues.errorField
      delete formValues.errorValue
      if (this.props.onSubmit) {
        this.props.onSubmit(formValues)
      } else return formValues
    }
  }

  handleCheckBox = (e, name) => {
    this.setState({
      isChecked: !this.state.isChecked,
      [name]: !this.state.isChecked
    })
  }

  renderButton = ({ type, index, text, color, id }) => {
    const { isBtnDisable } = this.state
    return (
      <Button
        type={type}
        color={color}
        key={index}
        id={isBtnDisable ? undefined : id}
        disabled={isBtnDisable}
      >
        {text}
      </Button>
    )
  }

  handleFormItemStateChanges = (value, name, action) => {
    this.setState((prevState) => {
      let mapState = {
        error: false,
        [name]: value?.trim ? value.trim() : value
      }
      if (action === 'checkbox') {
        mapState = {
          ...mapState,
          isChecked: !prevState.isChecked,
          [name]: !prevState.isChecked
        }
      } else if (action === 'select') {
        mapState.errorField = { ...prevState.errorField, [name]: '' }
      }

      return mapState
    }, () => {
      if (action === 'select') {
        this.props.onChangeSelect && this.props.onChangeSelect({ [name]: value })
      }
      const { formData = {}, onFormChange } = this.props
      let formValid = true
      const formValues = {}
      formData.fields.forEach(field => {
        if (field.required) {
          formValid = formValid && this.state[field.name]
        }
        formValues[field.name] = this.state[field.name]
      })
      // we checking that all values data present or not
      onFormChange && onFormChange(formValues, formValid)
    })
  }

  renderItem = ({
    type, name, defaultValue, placeholder, data, dataKey, displayKey,
    backgroundColor, opacity, label, autoFocus, className
  }) => {
    // TODO why adding to much attributes
    const { errorField, errorValue } = this.state
    if (this.state[name] && this.state.isBtnDisable) {
      this.setState({
        isBtnDisable: false
      })
    }

    const { loading } = this.props
    switch (type) {
      case 'input':
      case 'number':
      case 'email':
        return (
          <Input
            errorField={errorField}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            opacity={opacity}
            label={label}
            name={name}
            className={className}
            autoFocus={autoFocus}
            onChange={(e) => this.handleFormItemStateChanges(e.target.value, name)}
          />
        )
      case 'select':
        return (
          <Select
            options={data}
            errorField={errorField[name] ? name : ''}
            loading={loading}
            name={name}
            value={this.state[name] || defaultValue}
            defaultValue={defaultValue}
            displayKey={displayKey}
            className={className}
            placeholder={placeholder}
            onChange={() => this.handleFormItemStateChanges(this.state[name], name, 'select')}
          />
        )
      case 'ktp':
      case 'npwp':
      case 'phone_number':
        return (
          <NumericInput
            errorField={errorField}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            opacity={opacity}
            name={name}
            type={type}
            className={className}
            autoFocus={autoFocus}
            value={this.state[name]}
            onChange={(value) => this.handleFormItemStateChanges(value, name)}
          />
        )
      case 'disclaimer':
        return (
          <CheckBoxStyle>
            <CustomCheckbox
              name='disclaimer'
              id='disclaimer'
              isChecked={this.state.isChecked}
              placeholder={placeholder}
              isTransparent
              onChange={(e) => this.handleFormItemStateChanges(e.target.value, 'disclaimer', 'checkbox')}
            />
            {placeholder}
          </CheckBoxStyle>
        )
      default:
        return (
          <Input
            errorField={errorField}
            errorValue={errorValue}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            opacity={opacity}
            label={label}
            className={className}
            name={name}
            defaultValue={defaultValue}
            onChange={(e) => this.handleFormItemStateChanges(e.target.value, name)}
          />
        )
    }
  }

  render () {
    const {
      formData, backgroundColor, opacity, className,
      formName, wrapperClassName
    } = this.props
    return (
      <FormWrapper
        backgroundColor={backgroundColor}
        opacity={opacity}
        className={wrapperClassName}
      >
        <form onSubmit={this.handleSubmit} className={className} name={formName}>
          {
            formData.fields.map((item, index) => (
              <div key={index}>
                {
                  item.label && (
                    <CustomText
                      size='12px'
                      color={AgentColorPallette.fontSecondary}
                      top='4px'
                      bottom='4px'
                    >
                      {item.label}
                    </CustomText>
                  )
                }
                {
                  this.renderItem({
                    ...item,
                    backgroundColor,
                    opacity
                  })
                }
              </div>
            ))
          }
          {
            formData.buttons && formData.buttons.map(
              (button, index) => this.renderButton({
                ...button,
                index
              })
            )
          }
        </form>
        <NotificationContainer />
      </FormWrapper>
    )
  }
}

export default Form
