import actions from './actions'

const INITIAL_STATE = {
  leadList: [],
  leadListStatus: '',
  leadUpdateData: {},
  leadUpdateStatus: '',
  leadRemoveData: {},
  leadRemoveStatus: ''
}

function leadRudcer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.GET_LEAD_LIST_SUCCESS:
    case actions.GET_LEAD_LIST_FAILURE:
      return {
        ...state,
        leadList: action.leadListData,
        leadListStatus: action.message
      }
    case actions.UPDATE_LEAD_SUCCESS:
    case actions.UPDATE_LEAD_FAILURE:
      return {
        ...state,
        leadUpdateData: action.data,
        leadUpdateStatus: action.message
      }
    case actions.REMOVE_LEAD_SUCCESS:
    case actions.REMOVE_LEAD_FAILURE:
      return {
        ...state,
        leadRemoveData: action.data,
        leadRemoveStatus: action.message
      }
    case actions.OPEN_LEAD_SUCCESS:
    case actions.OPEN_LEAD_FAILURE:
      return {
        ...state,
        leadOpenData: action.data,
        leadOpenStatus: action.message
      }
    case actions.RESET_LEAD_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    default:
      return state
  }
}

export default leadRudcer
