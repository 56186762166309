import styled from 'styled-components'

export const BullImgGalleryStyle = styled.div`
  padding: 0 20px;

  .img-flex-wrap {
    flex-wrap: wrap;
  }

  .file-view-container {
    position: relative;
    width: 96px;
    height: 100px;
    border-radius: 4px;
    padding: 4px;
    margin: 10px 4px;
    border: 1px solid ${props => props.theme.borderGrey};

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .selected-icon {
    position: absolute;
    top: 4%;
    right: 4%;
  }

  .bg-black {
    background-color: ${props => props.theme.darkCharcoal};
  }

  .selected-img {
    color: ${props => props.theme.white};
    font-size: 14px;
  }

  .delete-selected-btn {
    background-color: ${props => props.theme.maroonRed};

    .btn-text {
      color: ${props => props.theme.white};
    }

    img {
      filter: brightness(2);
    }
  }
`
