import { LoaderSvgStyle } from '../index.style'
import { ReactComponent as Loader } from '../../../images/loader.svg'

function LoaderSvg ({ size = '50px', isLoader }) {
  return isLoader ? (
    <LoaderSvgStyle size={size}>
      <Loader title='Loader' />
    </LoaderSvgStyle>
  ) : <div />
}

export default LoaderSvg
