import { ServiceContextProvider } from '@pasarpolis/common-react-hooks'
import { getHeader, getErrorMessage } from '../../helpers/utility'
import { useUpdateGlobal } from '../../state/globalState/hooks/useGlobalContext'
import RoutesContainer from '../routesContainer'
import { BrowserRouter as Router } from 'react-router-dom'

function ServiceContainer (props) {
  const [{ headers }] = useUpdateGlobal()

  return (
    <ServiceContextProvider
      headers={headers || getHeader()}
      getGlobalErrorMessages={(status, error) => getErrorMessage(status, error && error.error_code)}
    >
      <Router history={props.history}>
        <RoutesContainer history={props.history} />
      </Router>
    </ServiceContextProvider>
  )
}

export default ServiceContainer
