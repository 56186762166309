import { withRouter } from 'react-router-dom'
import { HeaderWrapper } from './index.style'

function Header ({
  heading, backgroundColor, titleColor, backBtnColor,
  headerPosition, backBtnId, history
}) {
  return (
    <HeaderWrapper
      backgroundColor={backgroundColor}
      titleColor={titleColor}
      backBtnColor={backBtnColor}
      headerPosition={headerPosition}
    >
      <div className='header-container'>
        <div className='back' onClick={() => history.goBack()} id={backBtnId}>
          <i className='fas fa-arrow-left' />
        </div>
        <div className='heading'>{heading.toUpperCase()}</div>
      </div>
    </HeaderWrapper>
  )
}

export default withRouter(Header)
