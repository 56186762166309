import styled from 'styled-components'
import { DisplayFlexRCSB } from '../../commonStyle/index.style'

export const FullPageModalStyle = styled.div`
  background-color: ${props => props.theme.fontLightGrey};
  height: 100%;
  max-width: 450px;
`

export const PageModalHeader = styled.div`
  background-color: ${props => props.isTransparent ? 'transparent' : props.theme.primary};
  position: fixed;
  max-width: 450px;
  width: 100%;
  z-index: 10;
`

export const FullPageModalHeaderStyle = styled(DisplayFlexRCSB)`
  padding: 12px;
  width: auto;
  max-width: 100%;
  text-align: center;
  font-weight: 700;

  .header-title {
    flex: 1;
  }

  .header-img {
    height: 18px;
    width: auto;
  }

  .back {
    width: 20px;
    filter: invert(1);
    width: 25px;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`

export const FullPageModalBody = styled.div`
  padding-top: 50px;
  overflow-y: scroll;
`
