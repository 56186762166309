import styled from 'styled-components'
import { DisplayFlexCCC } from '../../commonStyle/index.style'

export const CircularLoaderStyle = styled.div`
  position: relative;
  width: 100%;
`

export const LoadingContainer = styled(DisplayFlexCCC)`
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: ${props => props.bgColor || 'rgba(0,0,0,0.5)'};
  z-index: 1000;
  width: 100%;
`

export const LoaderSvgStyle = styled.div`
  width: ${props => props.size};
  display: inline-block;

  #eGhaJjprqD13_tr {
    animation: eGhaJjprqD13_tr__tr 3000ms linear infinite normal forwards
  }
  @keyframes eGhaJjprqD13_tr__tr {
    0% {transform: translate(12px,12px) rotate(0deg)}
    100% {transform: translate(12px,12px) rotate(360.000002deg)}
  }
`
