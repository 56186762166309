import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './action'
import getApi from '../../methods/get'
import postApi from '../../methods/post'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import { get } from 'lodash'

export function * getPaymentToken () {
  yield takeEvery(actions.GET_PAYMENT_TOKEN_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postApi, { payload, url: ConfigUrl.generatePaymentToken })
      if (res) {
        yield put({
          type: actions.GET_PAYMENT_TOKEN_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PAYMENT_TOKEN_FAILURE,
          message: getErrorMessage(res.status, get(res, 'data.error_code', '')),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PAYMENT_TOKEN_FAILURE,
        message: getErrorMessage(res.status, get(res, 'data.error_code', '')),
        redirectPayment: res.status && res.status === 409,
        data: {}
      })
    }
  })
}

export function * getPaymentDetail () {
  yield takeEvery(actions.GET_PAYMENT_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getApi, { ...payload, url: ConfigUrl.getPaymentDetail })
      if (res) {
        yield put({
          type: actions.GET_PAYMENT_DETAIL_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PAYMENT_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: {},
          error_code: get(res, 'data.error_code', 'PP-403')
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PAYMENT_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: {},
        error_code: get(res, 'data.error_code', 'PP-403')
      })
    }
  })
}

export function * getVaBankList () {
  yield takeEvery(actions.GET_VA_BANK_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getApi, {
        url: ConfigUrl.getVaBankList,
        query: payload
        // params: { product_code: payload.product_code }
      })
      if (res) {
        yield put({
          type: actions.GET_VA_BANK_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_VA_BANK_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_VA_BANK_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getPaymentStatus () {
  yield takeEvery(actions.GET_PAYMENT_STATUS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getApi, { ...payload, url: ConfigUrl.getPaymentStatusApi(payload.transactionId) })
      if (res) {
        yield put({
          type: actions.GET_PAYMENT_STATUS_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PAYMENT_STATUS_FAILURE,
          message: getErrorMessage(res.status),
          data: { payment_status: 'NOT_FOUND' }
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PAYMENT_STATUS_FAILURE,
        message: getErrorMessage(res.status),
        data: { payment_status: 'NOT_FOUND' }
      })
    }
  })
}

export function * calculateServiceCharge () {
  yield takeEvery(actions.CALCULATE_SERVICE_CHARGE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postApi, { body: payload, url: ConfigUrl.serviceChargeApi })
      if (res) {
        yield put({
          type: actions.CALCULATE_SERVICE_CHARGE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CALCULATE_SERVICE_CHARGE_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.CALCULATE_SERVICE_CHARGE_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getPayLaterRequest () {
  yield takeEvery(actions.PAY_LATER_REQUESTS, function * ({ payload }) {
    try {
      const res = yield call(postApi, { body: payload.body, url: ConfigUrl.payLaterApi(payload.id) })
      if (res) {
        yield put({
          type: actions.PAY_LATER_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.PAY_LATER_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.PAY_LATER_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getPaymentToken),
    fork(getPaymentDetail),
    fork(calculateServiceCharge),
    fork(getPaymentStatus),
    fork(getVaBankList),
    fork(getPayLaterRequest)
  ])
}
