import { get as lodashGet } from 'lodash/object'
import { find as lodashFind } from 'lodash/collection'
import { GOJEK_CODE } from '../constants/pkgCode'

export const checkIsGojekDriver = (agentData) => {
  const agentTags = lodashGet(agentData, 'agent_tags', [])
  return lodashFind(agentTags, { code: GOJEK_CODE })
}

export const checkIsStaffAgent = (agentData) => {
  const agentTags = lodashGet(agentData, 'agent_tags', [])
  return lodashFind(agentTags, { tag_type: 'staff' })
}
