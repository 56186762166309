import { AgentColorPallette } from '../configs/colorPallete'
import {
  NEW_POLICY_NEW_CAR, RENEW_POLICY_WITH_SAME_PROVIDER,
  NEW_POLICY_USED_CAR, RENEW_POLICY_WITH_DIFFERENT_PROVIDER
} from '../containers/Private/Policy/constants'
import {
  SILVER_STAR, GOLD_STAR, PLATINUM_STAR, SILVER_POLYGON,
  GOLD_POLYGON, PLATINUM_POLYGON, BRONZE_POLYGON
} from '../images'
import { LANG_STRINGS } from '../lang'

export const CAR_PROTECTION = 'car-protection'
export const PROPERTY_PROTECTION = 'property-protection'
export const COVID_PROTECTION = 'covid-protection'
export const JIWA_PROTECTION = 'jiwa-protection'
export const BIKE_PROTECTION_LITE = 'bike-protection-lite'
export const HEALTH_PROTECTION = 'health-protection'
export const OFFLINE_PACKAGE_CODE = 'offline-protection-all'
export const PROPERTY_PROTECTION_BASIC = 'property-protection-basic'
export const MARINE_CARGO_PROTECTION = 'cargo-protection-basic'
export const MARINE_CARGO = 'marine-cargo-protection'
export const TRAVEL_PROTECTION = 'travel-protection'
export const EXTENDED_WARRANTY_PROTECTION = 'extended-warranty-protection'
export const OFFLINE_CAR_PROTECTION_TLO = 'offline-car-protection-tlo'

// Policy Type
export const FLEET_POLICY = 'fleet-policy'
export const MANUAL_ORDER = 'manual-order'

// Micro Products
export const MICRO_PRODUCTS = {
  [COVID_PROTECTION]: true,
  [JIWA_PROTECTION]: true,
  [HEALTH_PROTECTION]: true,
  [BIKE_PROTECTION_LITE]: true
}

export const HEALTH_FULL_PROTECTION = 'health-full-protection'
export const HEALTH_BENIFIT = 'health-full-protection'
export const GOJEK_CODE = 'GOJEK'
export const SUN_LIFE_SILVER_CODE = 'sunlife-covid-protection-silver'
export const SUN_LIFE_GOLD_CODE = 'sunlife-covid-protection-gold'
export const SUN_LIFE_PLATINUM_CODE = 'sunlife-covid-protection-platinum'
export const ADIRA_BIKE_PROTECTION_LITE = 'adira-bike-protection-lite-basic'

export const JIWA_BRONZE_CODE = 'allianz-jiwa-protection-bronze'
export const JIWA_SILVER_CODE = 'allianz-jiwa-protection-silver'
export const JIWA_GOLD_CODE = 'allianz-jiwa-protection-gold'
export const JIWA_PLATINUM_CODE = 'allianz-jiwa-protection-platinum'

export const LIPPO_HEALTH_PROTECTION_BRONZE_LITE = 'lippo-health-protection-bronze-lite'
export const LIPPO_HEALTH_PROTECTION_SILVER_LITE = 'lippo-health-protection-silver-lite'
export const LIPPO_HEALTH_PROTECTION_GOLD_LITE = 'lippo-health-protection-gold-lite'
export const LIPPO_HEALTH_PROTECTION_BRONZE = 'lippo-health-protection-bronze'
export const LIPPO_HEALTH_PROTECTION_SILVER = 'lippo-health-protection-silver'
export const LIPPO_HEALTH_PROTECTION_GOLD = 'lippo-health-protection-gold'

export const NEW_PURCHASE = 'new-purchase'
export const RENEW_PURCHASE = 'renew-purchase'
export const RENEW_OTHER_PURCHASE = 'renew-from-other'

export const OFFLINE_POLICY_PURCHASE_TYPE = {
  [NEW_POLICY_NEW_CAR]: NEW_PURCHASE,
  [NEW_POLICY_USED_CAR]: NEW_PURCHASE,
  [RENEW_POLICY_WITH_DIFFERENT_PROVIDER]: RENEW_OTHER_PURCHASE,
  [RENEW_POLICY_WITH_SAME_PROVIDER]: RENEW_OTHER_PURCHASE
}

export const GOJECK_APPLICATION_FILTER = 'PAID,POLICY_CREATED,POLICY_CREATION_FAILED'

export const SUN_LIFE_POLICY_CONFIG = {
  [SUN_LIFE_SILVER_CODE]: {
    backgroundColor: AgentColorPallette.fontGrey,
    type: 'SILVER',
    pkgName: LANG_STRINGS.covidTitle,
    polygon: SILVER_POLYGON,
    icon: SILVER_STAR
  },
  [SUN_LIFE_GOLD_CODE]: {
    backgroundColor: AgentColorPallette.shineGold,
    type: 'GOLD',
    pkgName: LANG_STRINGS.covidTitle,
    polygon: GOLD_POLYGON,
    icon: GOLD_STAR
  },
  [SUN_LIFE_PLATINUM_CODE]: {
    backgroundColor: AgentColorPallette.black,
    type: 'PLATINUM',
    pkgName: LANG_STRINGS.covidTitle,
    polygon: PLATINUM_POLYGON,
    icon: PLATINUM_STAR
  }
}

export const JIWA_POLICY_CONFIG = {
  [JIWA_BRONZE_CODE]: {
    backgroundColor: AgentColorPallette.bronze,
    type: 'BRONZE',
    pkgName: 'JIWA',
    polygon: BRONZE_POLYGON
  },
  [JIWA_SILVER_CODE]: {
    backgroundColor: AgentColorPallette.fontGrey,
    type: 'SILVER',
    pkgName: 'JIWA',
    polygon: SILVER_POLYGON
  },
  [JIWA_GOLD_CODE]: {
    backgroundColor: AgentColorPallette.shineGold,
    type: 'GOLD',
    pkgName: 'JIWA',
    polygon: GOLD_POLYGON
  },
  [JIWA_PLATINUM_CODE]: {
    backgroundColor: AgentColorPallette.black,
    type: 'PLATINUM',
    pkgName: 'JIWA',
    polygon: PLATINUM_POLYGON
  }
}

export const getHealthProtectPkgConfig = (pkgCode) => {
  // this function return the package config according to the
  // pkg code basis
  switch (pkgCode) {
    case LIPPO_HEALTH_PROTECTION_BRONZE_LITE:
    case LIPPO_HEALTH_PROTECTION_BRONZE:
      return {
        backgroundColor: AgentColorPallette.bronze,
        type: 'BRONZE',
        pkgName: 'lippo'
      }
    case LIPPO_HEALTH_PROTECTION_SILVER_LITE:
    case LIPPO_HEALTH_PROTECTION_SILVER:
      return {
        backgroundColor: AgentColorPallette.fontGrey,
        type: 'SILVER',
        pkgName: 'lippo'
      }
    case LIPPO_HEALTH_PROTECTION_GOLD_LITE:
    case LIPPO_HEALTH_PROTECTION_GOLD:
      return {
        backgroundColor: AgentColorPallette.shineGold,
        type: 'GOLD',
        pkgName: 'lippo'
      }
    default:
  }
}

export const SUMMARY_PAGE_MAPPING = {
  [JIWA_PROTECTION]: 'jiwa',
  [COVID_PROTECTION]: 'covid'
}

export const BLOCK_TRANSACTION_DETAIL_CODE = [
  JIWA_BRONZE_CODE,
  JIWA_GOLD_CODE,
  JIWA_PLATINUM_CODE,
  JIWA_SILVER_CODE,
  JIWA_PROTECTION,
  COVID_PROTECTION,
  SUN_LIFE_PLATINUM_CODE,
  SUN_LIFE_GOLD_CODE,
  ADIRA_BIKE_PROTECTION_LITE,
  LIPPO_HEALTH_PROTECTION_BRONZE,
  LIPPO_HEALTH_PROTECTION_BRONZE_LITE,
  LIPPO_HEALTH_PROTECTION_GOLD,
  LIPPO_HEALTH_PROTECTION_GOLD_LITE,
  LIPPO_HEALTH_PROTECTION_SILVER,
  LIPPO_HEALTH_PROTECTION_SILVER_LITE,
  SUN_LIFE_SILVER_CODE

]

export const SUBMITTED = 'SUBMITTED'
export const COMPLETED = 'COMPLETED'
export const DOCUMENT_INCOMPLETE = 'DOCUMENT_INCOMPLETE'
export const INFORMATION_IN_VERIFICATION = 'INFORMATION_IN_VERIFICATION'
export const DOCUMENT_VERIFIED = 'DOCUMENT_VERIFIED'
export const QUOTE_IN_PROGRESS = 'QUOTE_IN_PROGRESS'
export const QUOTE_ACCEPTED = 'QUOTE_ACCEPTED'
export const INFORMATION_COMPLETED = 'INFORMATION_COMPLETED'
export const CLOSING_SLIP_GENERATED = 'CLOSING_SLIP_GENERATED'
export const POLICY_ISSUANCE_PENDING = 'POLICY_ISSUANCE_PENDING'
export const POLICY_ISSUED = 'POLICY_ISSUED'
export const POLICY_COMPLETED = 'POLICY_COMPLETED'
export const QUOTE_REJECTED = 'QUOTE_REJECTED'
export const QUOTE_ISSUED = 'QUOTE_ISSUED'
export const QUOTE_EXPIRED = 'QUOTE_EXPIRED'
export const ADDITIONAL_INFORMATION_REQUIRED = 'ADDITIONAL_INFORMATION_REQUIRED'

export const MV_QUOTE_STEPS_SECTIONS = {
  protection_type: {
    step: 1,
    section: 'Tipe Proteksi',
    tabsData: {
      protection: true
    },
    getPrevPath: () => ({
      prevPath: '/dashboard'
    }),
    getPath: (draftId) => `/motor_vehicle/v2/?draftId=${draftId}`,
    sub_sections: {
      insurance_specifications: LANG_STRINGS.insuranceSpecifications,
      vehicle_criteria: LANG_STRINGS.vehicleCriteria,
      vehicle_modifications: LANG_STRINGS.vehicleModification
    }
  },
  package_insurance: {
    step: 2,
    section: 'Paket Asuransi',
    tabsData: {
      protection: true,
      pkgInsurance: true
    },
    getPath: (action, draftId) => {
      if (action === 'package_list') {
        return `/motor_vehicle/packages/v2/?draftId=${draftId}`
      } else {
        return `/motor_vehicle/packages/details/v2/${draftId}`
      }
    },
    getPrevPath: (action, draftId) => {
      if (action === 'package_list') {
        return {
          draftId,
          prevPath: `/motor_vehicle/v2/?draftId=${draftId}`,
          prevSection: 'protection_type'
        }
      } else {
        return {
          draftId,
          prevPath: `/motor_vehicle/packages/v2/?draftId=${draftId}`,
          prevSection: 'package_insurance',
          prevSubSection: 'package_list'
        }
      }
    },
    sub_sections: {
      package_list: LANG_STRINGS.packageInsurance,
      package_details: LANG_STRINGS.pkgDetails
    }
  },
  insured_object: {
    step: 3,
    section: 'Objek Tertanggung',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true
    },
    getPath: (draftId) => `/motor_vehicle/form/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/motor_vehicle/packages/details/v2/${draftId}`,
        prevSection: 'package_insurance',
        prevSubSection: 'package_details'
      }
    },
    sub_sections: {
      vehicle_document: LANG_STRINGS.document,
      vehicle_detail_object: LANG_STRINGS.detailObject,
      vehicle_photos: LANG_STRINGS.vehiclePhoto,
      vehicle_modification_photos: LANG_STRINGS.vehicleModificationPhotos,
      vehicle_damage: LANG_STRINGS.vehicleDamage
    }
  },
  customer_data: {
    step: 4,
    section: 'Data Pelanggan',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true,
      customerData: true
    },
    getPath: (draftId) => `/motor_vehicle/customer/data/form/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/motor_vehicle/form/${draftId}`,
        prevSection: 'insured_object',
        prevSubSection: 'vehicle_document'
      }
    },
    sub_sections: {
      customer_document: LANG_STRINGS.document,
      customer_information: LANG_STRINGS.nameAndContactInfo,
      customer_address: LANG_STRINGS.residenceAddress,
      hard_copy: LANG_STRINGS.printPolicy,
      vehicle_survey: LANG_STRINGS.vehicleSurvey
    }
  },
  mv_quote_summary: {
    step: 4,
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true,
      customerData: true
    },
    getPath: (draftId) => `/motor_vehicle/customer/data/form/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/motor_vehicle/form/${draftId}`,
        prevSection: 'insured_object',
        prevSubSection: 'vehicle_document'
      }
    },
    section: 'Data Pelanggan',
    sub_sections: {
      mv_quote_summary: LANG_STRINGS.overview
    }
  }
}

export const TAP_TRAVEL_QUOTE_STEPS_SECTIONS = {
  protection_type: {
    step: 2,
    section: 'Daftar Paket',
    tabsData: {
      protection: true
    },
    getPrevPath: () => ({
      prevPath: '/dashboard'
    }),
    getPath: (draftId) => `/travel/insured_package/${draftId}`,
    sub_sections: {
      insurance_specifications: LANG_STRINGS.insuranceSpecifications,
      vehicle_criteria: LANG_STRINGS.vehicleCriteria,
      vehicle_modifications: LANG_STRINGS.vehicleModification
    }
  },
  package_insurance: {
    step: 2,
    section: 'Pilih Paket',
    tabsData: {
      protection: true,
      pkgInsurance: true
    },
    getPath: (draftId) => `/travel/package_detail/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/travel/insured_package/${draftId}`,
        prevSection: 'package_insurance',
        prevSubSection: 'package_list'
      }
    },
    sub_sections: {
      // package_list: LANG_STRINGS.packageList,
      // package_details: LANG_STRINGS.pkgDetails
    }
  },
  customer_data: {
    step: 3,
    section: 'Pendaftaran',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true
    },
    getPath: (draftId) => `/travel/registration/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/travel/package_detail/${draftId}`,
        prevSection: 'package_insurance',
        prevSubSection: 'package_details'
      }
    },
    sub_sections: {
      vehicle_document: LANG_STRINGS.document
    }
  },
  mv_quote_summary: {
    step: 4,
    section: 'Konfirmasi',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true,
      customerData: true
    },
    getPath: (draftId) => `/travel/confirmation/${draftId}`,

    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/travel/registration/${draftId}`,
        prevSection: 'customer_data',
        prevSubSection: 'customer_document'
      }
    },
    sub_sections: {
      mv_quote_summary: LANG_STRINGS.overview
    }
  }
}

export const EW_QUOTE_STEPS_SECTIONS = {
  protection_type: {
    step: 2,
    section: 'Daftar Paket',
    tabsData: {
      protection: true
    },
    getPrevPath: () => ({
      prevPath: '/dashboard'
    }),
    getPath: (draftId) => `/extended_warranty/insured_package/${draftId}`,
    sub_sections: {
      insurance_specifications: LANG_STRINGS.insuranceSpecifications,
      vehicle_criteria: LANG_STRINGS.vehicleCriteria,
      vehicle_modifications: LANG_STRINGS.vehicleModification
    }
  },
  package_insurance: {
    step: 2,
    section: 'Pilih Paket',
    tabsData: {
      protection: true,
      pkgInsurance: true
    },
    getPath: (draftId) => `/extended_warranty/package_detail/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/extended_warranty/insured_package/${draftId}`,
        prevSection: 'package_insurance',
        prevSubSection: 'package_list'
      }
    },  
    sub_sections: {
      // package_list: LANG_STRINGS.packageList,
      // package_details: LANG_STRINGS.pkgDetails
    }
  },
  insured_object: {
    step: 3,
    section: 'Pendaftaran',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      // insuredObject: true
    },
    getPath: (draftId) => `/extended_warranty/registration/${draftId}`,
    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/extended_warranty/package_detail/${draftId}`,
        prevSection: 'package_insurance',
        prevSubSection: 'package_details'
      }
    },
    sub_sections: {
      // vehicle_document: LANG_STRINGS.document
    }
  },
  mv_quote_summary: {
    step: 4,
    section: 'Konfirmasi',
    tabsData: {
      protection: true,
      pkgInsurance: true,
      insuredObject: true,
      customerData: true
    },
    getPath: (draftId) => `/extended_warranty/confirmation/${draftId}`,

    getPrevPath: (draftId) => {
      return {
        draftId,
        prevPath: `/extended_warranty/registration/${draftId}`,
        prevSection: 'insured_object',
        prevSubSection: 'vehicle_photos'
      }
    },
    sub_sections: {
      mv_quote_summary: LANG_STRINGS.overview
    }
  }
}
