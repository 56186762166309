import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import motorActions from './actions'
import get from '../../methods/get'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'

export function * getMotorBrandListSaga () {
  yield takeEvery(motorActions.GET_MOTOR_BRAND_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(get, { url: ConfigUrl.bikeBrandListApi })
      if (res) {
        yield put({
          type: motorActions.GET_MOTOR_BRAND_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: motorActions.GET_MOTOR_BRAND_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (error) {
      yield put({
        type: motorActions.GET_CUSTOMER_POLICY_FAILURE,
        message: error.data ? error.data.message : getErrorMessage(error.status),
        data: {}
      })
    }
  })
}

export function * getMotorModelListSaga () {
  yield takeEvery(motorActions.GET_MOTOR_MODEL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(get, { query: payload, url: ConfigUrl.bikeModelListApi })
      if (res) {
        yield put({
          type: motorActions.GET_MOTOR_MODEL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: motorActions.GET_MOTOR_MODEL_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (error) {
      yield put({
        type: motorActions.GET_MOTOR_MODEL_FAILURE,
        message: error.data ? error.data.message : getErrorMessage(error.status),
        data: {}
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getMotorBrandListSaga),
    fork(getMotorModelListSaga)
  ])
}
