import { useCallback, useState } from 'react'
import { supportedVideoFormat } from '../../constants/appConstants'
import { ZoomImgStyle, ZoomImgContainer } from './index.style'

function ZoomImg ({ imgContainer, src = '', imgClassName = '' }) {
  const [showZoomImg, setShowZoomImg] = useState(false)
  const [showAnimation, setShowAnimation] = useState(false)

  const handleSetZoomImg = useCallback(() => {
    setShowZoomImg(showZoomImg => !showZoomImg)
    setTimeout(() => {
      setShowAnimation(showAnimation => !showAnimation)
    })
  }, [])

  return (
    <>
      <ZoomImgContainer className={imgClassName} onClick={handleSetZoomImg}>
        {imgContainer || <img src={src} alt='' />}
      </ZoomImgContainer>
      {
        showZoomImg && (
          <ZoomImgStyle>
            <div className={`zoom-img-wrapper ${showAnimation && 'zoom-img-wrapper-target'}`}>
              <div className='zoom-img-parent'>
                {
                  supportedVideoFormat.includes(src.split('.').pop()) ? (
                    <video className={`img-zoom ${showAnimation && 'zoom-img-target'}`} controls>
                      <source src={src} />
                    </video>
                  ) : (
                    <img className={`img-zoom ${showAnimation && 'zoom-img-target'}`} src={src} alt='' />
                  )
                }
              </div>
              <span className={`zoom-img-close ${showAnimation && 'zoom-img-target-close'}`} onClick={handleSetZoomImg} />
            </div>
          </ZoomImgStyle>
        )
      }
    </>
  )
}

export default ZoomImg
