import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const ButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${props => props.padLt || '0'};
  padding-right: ${props => props.padRt || '0'};
  padding-top: ${props => props.padTop || '12px'};
  padding-bottom: ${props => props.padBtm || '12px'};
  border-radius: ${props => props.borderRadius || '24px'};
  font-weight: 800;
  font-size: ${props => props.fontSize || '14px'};
  cursor: pointer;
  width: ${props => props.width || '100%'};
  border: ${props => props.border || 'none'};
  color: ${props => props.textColor || AgentColorPallette.fontDarkGrey};
  background-color: ${props => props.backgroundColor || AgentColorPallette.primary};
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${props => props.theme.fontSemiGrey};
    color: ${props => props.theme.white};
  }
`
