const propertyActions = {
  GET_PROPERTY_PROVINCE_REQUEST: 'GET_PROPERTY_PROVINCE_REQUEST',
  GET_PROPERTY_PROVINCE_SUCCESS: 'GET_PROPERTY_PROVINCE_SUCCESS',
  GET_PROPERTY_PROVINCE_FAILURE: 'GET_PROPERTY_PROVINCE_FAILURE',

  GET_PROPERTY_CITIES_REQUEST: 'GET_PROPERTY_CITIES_REQUEST',
  GET_PROPERTY_CITIES_SUCCESS: 'GET_PROPERTY_CITIES_SUCCESS',
  GET_PROPERTY_CITIES_FAILURE: 'GET_PROPERTY_CITIES_FAILURE',

  GET_PROPERTY_OCCUPATION_REQUEST: 'GET_PROPERTY_OCCUPATION_REQUEST',
  GET_PROPERTY_OCCUPATION_SUCCESS: 'GET_PROPERTY_OCCUPATION_SUCCESS',
  GET_PROPERTY_OCCUPATION_FAILURE: 'GET_PROPERTY_OCCUPATION_FAILURE',

  GET_PROPERTY_CONSTRUCTION_REQUEST: 'GET_PROPERTY_CONSTRUCTION_REQUEST',
  GET_PROPERTY_CONSTRUCTION_SUCCESS: 'GET_PROPERTY_CONSTRUCTION_SUCCESS',
  GET_PROPERTY_CONSTRUCTION_FAILURE: 'GET_PROPERTY_CONSTRUCTION_FAILURE',

  GET_PROPERTY_ADDONS_REQUEST: 'GET_PROPERTY_ADDONS_REQUEST',
  GET_PROPERTY_ADDONS_SUCCESS: 'GET_PROPERTY_ADDONS_SUCCESS',
  GET_PROPERTY_ADDONS_FAILURE: 'GET_PROPERTY_ADDONS_FAILURE',

  RESET_PROPERTY_STORE: 'RESET_PROPERTY_STORE',

  getPropertyProvince: () => ({
    type: propertyActions.GET_PROPERTY_PROVINCE_REQUEST
  }),
  getPropertyCities: (payload) => ({
    type: propertyActions.GET_PROPERTY_CITIES_REQUEST,
    payload
  }),
  getPropertyOccupation: () => ({
    type: propertyActions.GET_PROPERTY_OCCUPATION_REQUEST
  }),
  getPropertyConstructionList: () => ({
    type: propertyActions.GET_PROPERTY_CONSTRUCTION_REQUEST
  }),
  getPropertyAddons: payload => ({
    type: propertyActions.GET_PROPERTY_ADDONS_REQUEST,
    payload
  }),
  resetPropertyState: (payload) => ({
    type: propertyActions.RESET_PROPERTY_STORE,
    payload
  })
}

export default propertyActions
