import styled from 'styled-components'

export const ContentBoxWrapper = styled.div`
  padding: 16px 16px 0px 16px;
  background-color: ${props => props.theme.white};
  border-radius: 18px;
  margin-bottom: 24px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  .first-row {
    display: flex;
    justify-content: space-between;
  }
  .border-bottom {
    padding-bottom: 16px;
    font-style: italic;
    border-bottom: 3px solid ${props => props.color};
  }
  .row {
    display: flex;
    align-items: center;
  }
`

export const MiniContentWrapper = styled.div`
  padding: 16px;
  background-color: ${props => props.theme.white};
  border-radius: 1rem;
  margin-bottom: 1rem;
  border:1px solid ${props => props.theme.Gray90};
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 1.4rem;

  .provider-logo-box {
    width: 36px;
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .provider-logo {
    max-height: 100%;
    max-width: 100%;
  }
  .border-bottom {
    padding-bottom: 16px;
    font-style: italic;
    border-bottom: ${props => `3px solid ${props.color}`} ;
  }
  hr {
    border: 0.5px solid ${props => props.theme.grey};
  }
`

export const DetailContentWrapper = styled.div`
  background-color: ${props => props.theme.white};
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  border-radius: 10px;
  margin: 10px 20px 20px;  
  position: relative;

  .policy-title-section {
    padding: 12px;
  }

  .content-row {
    padding: 2px 12px;
  }

  .row-btm-pad {
    padding: 2px 12px 12px;
  }

  hr {
    border: 0.5px solid ${props => props.theme.grey};
  }

  .separator {
    border: 1.5px solid ${props => props.theme.grey};
  }

  .row-content {
    display: flex;
    justify-content: space-between;
  }

  .policy-status-strip {
    padding: 10px;
    margin-top: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .provider-logo {
    width: 80px;
  }
  .logo-image {
    max-width: 100%;
    margin-right: 4px;
  }
`

export const PaylaterBadge = styled.div`
  background-color: ${props => props.theme.errorDark};
  color: ${props => props.theme.white};
  font-size: 8px;
  font-weight: 800;
  padding: 8px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
`
