import { useState, useCallback } from 'react'
import { CustomText } from '..'
import { AgentColorPallette } from '../../configs/colorPallete'
import TitleError from '../TitleError'
import VerticalRadioGroup from '../VerticalRadioGroup'
import { RadioInputCardStyle } from './index.style'

function RadioInputCard ({
  data = [], title, name, required, onChange = () => {},
  defaultSelected
}) {
  const [selected, setSelected] = useState(defaultSelected)

  const handleOnChange = useCallback((item) => {
    setSelected(item.id)
    // pass the selected item to parent
    if (onChange) {
      onChange(item)
    }
  }, [onChange])

  return (
    <>
      {
        title && <TitleError title={title} required={required} />
      }
      <RadioInputCardStyle>
        {
          data.map((item, index) => (
            <VerticalRadioGroup
              key={`${item.id}_${index}`}
              selected={selected}
              name={name}
              data={item}
              label={
                <CustomText
                  color={AgentColorPallette.darkCharcoal}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOnChange(item)
                  }}
                  className='radio-level-margin-left'
                  lineHeight={2}
                >
                  {item.title}
                </CustomText>
              }
              onClick={handleOnChange}
              className={`vertical-group-pad-top ${(index + 1 !== data.length) ? 'radio-border-btm' : ''}`}
              levelRightPosition
            />
          ))
        }
      </RadioInputCardStyle>
    </>
  )
}

export default RadioInputCard
