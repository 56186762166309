import { useCallback, useState } from 'react'
import { Row, DisplayFlexCCC, DisplayCircleIcon } from '../../../../../commonStyle/index.style'
import { AgentColorPallette } from '../../../../../configs/colorPallete'
import { getFileExtension } from '../../../../../helpers/utility'
import { DELETE } from '../../../../../images'
import { LANG_STRINGS } from '../../../../../lang'
import BottomBtn from '../../../../BottomBtn'
import ConfirmationModal from '../../../../ConfirmationModal'
import CustomText from '../../../../CustomText'
import FullPageModal from '../../../../FullPageModal'
import ShowSnackbar from '../../../../ShowNotification'
import ZoomImg from '../../../../zoomImg'
import { BullImgGalleryStyle } from './index.style'

function BulkImgGalleryModal ({ show, onClose, hideDelete, uploadedImgs = [], onDelete = () => {} }) {
  const [images, setImages] = useState(uploadedImgs)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isSelectView, setIsSelectView] = useState(false)
  const [selected, setSelected] = useState({})

  const handleSelect = useCallback(() => {
    // handle Select Enable
    setIsSelectView(isSelectView => !isSelectView)
    setSelected({})
  }, [])

  const handleSelectAll = useCallback(() => {
    // select all imgs in the uploaded
    setSelected({
      all: true
    })
  }, [])

  const handleImgClick = useCallback((index) => {
    if (!isSelectView) return
    setSelected(selected => {
      const key = `index${index}`
      if (selected[key]) {
        delete selected[key]
      } else {
        selected[key] = true
      }
      return {
        ...selected
      }
    })
  }, [isSelectView])

  const handleConfirmModalToggle = useCallback(() => {
    setShowConfirmModal(showConfirmModal => !showConfirmModal)
  }, [])

  const handleAgreeDelete = useCallback(() => {
    let finalImgs = []
    if (selected.all) {
      finalImgs = []
    } else {
      for (const key in selected) {
        const index = parseInt(key.replace('index', ''))
        images[index] = null
      }
      finalImgs = images.filter(url => !!url)
    }
    ShowSnackbar.success({
      visible: true,
      text: LANG_STRINGS.photoWasSuccessfullyDeleted
    })
    setImages(finalImgs)
    handleSelect()
    // notify the parent if any img deleted
    onDelete(finalImgs)
    setShowConfirmModal(false)
  }, [selected, images, onDelete, handleSelect])

  const renderImgFileContainer = useCallback((fileUrl, index) => {
    const ext = getFileExtension(fileUrl).toLowerCase()
    const isSelected = selected.all || selected[`index${index}`]
    let ChildElement = null
    // {/* <img src={fileUrl} alt='' /> */}

    if (['png', 'jpg', 'jpeg', 'webp'].includes(ext)) {
      ChildElement = <ZoomImg src={fileUrl} />
    } else if (ext === 'pdf') {
      ChildElement = (
        <iframe
          className='pdf-object'
          type='application/pdf'
          src={fileUrl}
          width='100%'
          height='100%'
          aria-label='pdf'
          title='PDF'
        />
      )
    }
    return (
      <DisplayFlexCCC
        className='file-view-container'
        onClick={() => handleImgClick(index)}
        key={`gallery-${index}`}
      >
        {
          isSelectView && (
            <DisplayCircleIcon
              bgColor={AgentColorPallette.lightBlack}
              className={`selected-icon ${isSelected && 'bg-black'}`}
            >
              {
                isSelected && (<i className='fa fa-check selected-img' />)
              }
            </DisplayCircleIcon>
          )
        }
        {ChildElement}
      </DisplayFlexCCC>
    )
  }, [isSelectView, selected, handleImgClick])

  const renderGalleryView = useCallback(() => {
    return (
      <Row align='center' className='img-flex-wrap'>
        {
          images.map((img, index) => renderImgFileContainer(img, index))
        }
      </Row>
    )
  }, [images, renderImgFileContainer])

  return (
    <FullPageModal
      onCloseModal={onClose}
      showModal={show}
      header={LANG_STRINGS.gallery}
      hideCloseIcon
      leftIcon={isSelectView && (
        <CustomText
          size='14px'
          color={AgentColorPallette.black}
          onClick={handleSelectAll}
          weight={600}
          className='cursor'
        >
          {LANG_STRINGS.selectAll}
        </CustomText>
      )}
      rightIcon={!hideDelete && (
        <CustomText
          size='14px'
          color={AgentColorPallette.black}
          onClick={handleSelect}
          weight={600}
          className='cursor'
        >
          {isSelectView ? LANG_STRINGS.okay : LANG_STRINGS.select}
        </CustomText>
      )}
    >
      <BullImgGalleryStyle>
        {
          renderGalleryView()
        }
        {
          showConfirmModal && (
            <ConfirmationModal
              showModal={showConfirmModal}
              onClose={handleConfirmModalToggle}
              onAgree={handleAgreeDelete}
              agreeText={LANG_STRINGS.yes}
              cancelText={LANG_STRINGS.no}
              confirmText={LANG_STRINGS.deletedSelectedPhotos}
            />
          )
        }
        {
          !hideDelete && (
            <BottomBtn
              title={LANG_STRINGS.remove}
              icon={DELETE}
              className={isSelectView ? 'delete-selected-btn' : ''}
              disabled={!isSelectView}
              iconRight
              onClick={handleConfirmModalToggle}
            />
          )
        }
        <ShowSnackbar />
      </BullImgGalleryStyle>
    </FullPageModal>
  )
}

export default BulkImgGalleryModal
