import styled from 'styled-components'

export const NumericInputStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .numeric-input {
    padding: 12px;
    margin-bottom: 12px;
    background-color: ${props => props.backgroundColor};
    opacity: ${props => props.opacity || 1};
    border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.theme.grey}` : 'none'};

    &:focus {
      outline: currentColor;
    }

    ::placeholder {
      color: ${props => props.theme.fontGrey};
      font-size: 12px;
      letter-spacing: normal;
    }
  }

  .number-box-shadow {
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;
    background-color: ${props => props.theme.white};
    border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.theme.grey}` : 'none'};
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 1%), 0 6px 16px 0 rgb(0 0 0 / 6%);
  }

  .number-error {
    color: ${props => props.theme.errorDark};
    font-size: 0.8em;
    margin-top: -12px;
    margin-bottom: 4px;
  }

`
