const addressActions = {
  GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_FAILURE: 'GET_CITIES_FAILURE',

  RESET_ADDRESS_STORE: 'RESET_ADDRESS_STORE',
  getCities: () => ({
    type: addressActions.GET_CITIES_REQUEST
  }),
  resetMotorStore: (payload) => ({
    type: addressActions.RESET_ADDRESS_STORE,
    payload
  })

}

export default addressActions
