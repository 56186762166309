import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import addressActions from './actions'
import get from '../../methods/get'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'

export function * getCitiesListSaga () {
  yield takeEvery(addressActions.GET_CITIES_REQUEST, function * () {
    try {
      const res = yield call(get, { url: ConfigUrl.citiesApi })
      if (res) {
        yield put({
          type: addressActions.GET_CITIES_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: addressActions.GET_CITIES_FAILURE,
          message: res.data ? res.data.message : getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (error) {
      yield put({
        type: addressActions.GET_CITIES_FAILURE,
        message: error.data ? error.data.message : getErrorMessage(error.status),
        data: {}
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getCitiesListSaga)
  ])
}
