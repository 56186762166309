// this file use to store the data in localstorage

export const setToLocalStorage = (key, data) => {
  // this is used to store data in localstorage
  const storage = window.localStorage || localStorage

  storage.setItem(key, JSON.stringify(data))
}

export const getFromLocalStorageData = (key) => {
  // retrieve data from localstorage
  const storage = window.localStorage || localStorage

  return JSON.parse(storage.getItem(key) || '{}')
}

export const removeFromLocalStorageData = (key) => {
  // remove data from localstorage
  const storage = window.localStorage || localStorage

  return storage.removeItem(key)
}

export const clearLocalStorage = () => {
  // clear all localstorage (helpful in case if user logout)
  const storage = window.localStorage || localStorage

  return storage.clear()
}
