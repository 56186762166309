import styled from 'styled-components'

export const InnerInputWrapper = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${props => props.backgroundColor || props.theme.font};
  opacity: ${props => props.opacity || 1};
  margin-bottom: 12px;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 12px;
  color: ${props => props.theme.black};
  border: ${props => props.error ? `2px solid ${props.theme.errorDark} !important` : props.displayBorder ? `1px solid ${props.theme.grey}` : 'none'};
  padding: 16px;
  font-weight: 600;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${props => props.theme.fontGrey};
    font-size: 12px;
  }
  &:disabled {
    background-color: ${props => props.theme.saturatedGrey};
    pointer-events: none;
    color: ${props => props.theme.black};
  }
`
