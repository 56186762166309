import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './action'
import getAuthApi from '../../methods/authGet'
import postAuthApi from '../../methods/authPost'
import authPostFormData from '../../methods/authPostFormData'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import { getFromLocalStorageData } from '../../helpers/localstorage'
import { CUSTOMER_FLOW } from '../../constants/cookies'
import postFormData from '../../methods/postFormData'
import get from 'lodash/get'

export function * getPackages () {
  yield takeEvery(actions.GET_PACKAGES_REQUEST, function * ({ payload }) {
    try {
      // ConfigUrl.getPackages(payload.code)
      const res = yield call(postAuthApi, { body: payload, url: ConfigUrl.getPackages(payload.code) })
      if (res) {
        yield put({
          type: actions.GET_PACKAGES_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PACKAGES_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PACKAGES_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getRegionCode () {
  yield takeEvery(actions.GET_REGION_CODE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getRegionCode })
      if (res) {
        yield put({
          type: actions.GET_REGION_CODE_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_REGION_CODE_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_REGION_CODE_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getTaxSlabSaga () {
  yield takeEvery(actions.GET_TAX_SLAB_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.taxSlabApi })
      if (res) {
        yield put({
          type: actions.GET_TAX_SLAB_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_TAX_SLAB_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_TAX_SLAB_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getDownloadPolicyPdfSaga () {
  yield takeEvery(actions.GET_DOWNLOAD_PDF_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.downloadPdfApi(payload) })
      if (res) {
        yield put({
          type: actions.GET_DOWNLOAD_PDF_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_DOWNLOAD_PDF_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_DOWNLOAD_PDF_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getDownloadDraftPdfSafa () {
  yield takeEvery(actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.downloadDraftPdfApi(payload) })
      if (res) {
        yield put({
          type: actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getPackageDetails () {
  yield takeEvery(actions.GET_PACKAGE_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload.body,
        url: ConfigUrl.getPkgDetailV2(payload.packageCode)
      })

      if (res) {
        yield put({
          type: actions.GET_PACKAGE_DETAIL_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_PACKAGE_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_PACKAGE_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * validateChassisNumberSaga () {
  yield takeEvery(actions.VALIDATE_CHASSIS_NO_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload.body,
        url: ConfigUrl.validateChassisNumberApi
      })

      if (res) {
        yield put({
          type: actions.VALIDATE_CHASSIS_NO_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.VALIDATE_CHASSIS_NO_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.VALIDATE_CHASSIS_NO_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getRenewalPackageDetailSaga () {
  yield takeEvery(actions.GET_RENEWAL_PACKAGE_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.getRenewalPkgDataAPi(payload.policyId.replace(/-/g, ''))
      })

      if (res) {
        yield put({
          type: actions.GET_RENEWAL_PACKAGE_DETAIL_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_RENEWAL_PACKAGE_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_RENEWAL_PACKAGE_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getVaDetail () {
  yield takeEvery(actions.GET_VA_DETAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi,
        {
          url: ConfigUrl.getVaDetail(payload.transactionId)
        }
      )
      if (res) {
        yield put({
          type: actions.GET_VA_DETAIL_SUCCESS,
          message: 'success',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_VA_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_VA_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * downloadPaymentInstructions () {
  yield takeEvery(actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi,
        {
          url: ConfigUrl.downloadPaymentInstructionsApi(payload.transactionId)
        }
      )
      if (res) {
        yield put({
          type: actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_SUCCESS,
          message: 'success',
          data: res.data
        })
      } else {
        yield put({
          type: actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getAddonsList () {
  yield takeEvery(actions.GET_ADDONS_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: payload.policyData, url: ConfigUrl.getAddonsApi(payload.apiId) })
      if (res) {
        yield put({
          type: actions.GET_ADDONS_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_ADDONS_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_ADDONS_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getWorkShopList () {
  yield takeEvery(actions.GET_WORK_SHOP_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi,
        {
          query: payload,
          url: ConfigUrl.getWorkShopList(payload.code)
        }
      )
      if (res) {
        yield put({
          type: actions.GET_WORK_SHOP_LIST_SUCCESS,
          message: 'success',
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_WORK_SHOP_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_WORK_SHOP_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * createPolicy () {
  yield takeEvery(actions.CREATE_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: payload, url: ConfigUrl.createPolicy })
      if (res) {
        yield put({
          type: actions.CREATE_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CREATE_POLICY_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.CREATE_POLICY_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * updateCreatePolicyQuotation () {
  yield takeEvery(actions.UPDATE_POLICY_QUOTE_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.updateCreatePolicyQuote(payload.token)
      })
      if (res) {
        yield put({
          type: actions.UPDATE_POLICY_QUOTE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_POLICY_QUOTE_FAILURE,
          message: getErrorMessage(res.status, res.data && res.data.error_code),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_POLICY_QUOTE_FAILURE,
        message: getErrorMessage(res.status, res.data && res.data.error_code),
        data: {}
      })
    }
  })
}

export function * updatePolicyQuotationDiscount () {
  yield takeEvery(actions.UPDATE_POLICY_QUOTE_DISCOUNT_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: { discount: payload.discount, user_id: '' },
        url: ConfigUrl.updateCreatePolicyQuoteDiscount(payload.token)
      })
      if (res) {
        yield put({
          type: actions.UPDATE_POLICY_QUOTE_DISCOUNT_SUCCESS,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_POLICY_QUOTE_DISCOUNT_FAILURE,
          message: getErrorMessage(res.status)
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_POLICY_QUOTE_DISCOUNT_FAILURE,
        message: getErrorMessage(res.status)
      })
    }
  })
}

export function * getUploadLink () {
  yield takeEvery(actions.GET_UPLOAD_LINK_REQUEST, function * ({ payload }) {
    try {
      const isCustomerFlow = getFromLocalStorageData(CUSTOMER_FLOW) === 'true'
      const res = yield call(
        (isCustomerFlow ? postFormData : authPostFormData),
        {
          body: payload,
          url: isCustomerFlow ? ConfigUrl.customerUploadUrl : ConfigUrl.getUploadLink
        }
      )
      if (res) {
        yield put({
          type: actions.GET_UPLOAD_LINK_SUCCESS,
          data: { result: res.data, name: payload.name }
        })
      } else {
        yield put({
          type: actions.GET_UPLOAD_LINK_FAILURE,
          message: res.data ? res.data.detail : getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_UPLOAD_LINK_FAILURE,
        message: res.data ? res.data.detail : getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getApplicationDetail () {
  yield takeEvery(actions.GET_APPLICATION_DETAIL_REQUEST, function * ({ payload }) {
    try {
      let policyId; const query = {}
      if (typeof payload === 'object' && payload !== null) {
        policyId = payload.policyId
        if (payload.includeInsuranceDetail) {
          query.include_insurance_details = true
        }
      } else {
        policyId = payload
      }

      const res = yield call(
        getAuthApi, {
          url: ConfigUrl.getApplicationDetail(policyId),
          query: query
        })
      if (res) {
        yield put({
          type: actions.GET_APPLICATION_DETAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_APPLICATION_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_APPLICATION_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getRenewalQuotationDataSaga () {
  yield takeEvery(actions.GET_RENEWAL_QUOTATION_DATA_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi, {
          url: ConfigUrl.getRenewalQuotationDataAPi(payload.policyId.replace(/-/g, ''))
        })
      if (res) {
        yield put({
          type: actions.GET_RENEWAL_QUOTATION_DATA_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_RENEWAL_QUOTATION_DATA_FAILURE,
          message: getErrorMessage(res.status, res.data?.error_code),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_RENEWAL_QUOTATION_DATA_FAILURE,
        message: getErrorMessage(res.status, res.data?.error_code),
        data: []
      })
    }
  })
}

export function * getRenewalPolicySaga () {
  yield takeEvery(actions.GET_RENEWAL_POLICY_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi, {
          url: ConfigUrl.getRenewalPolicyDepreciatedDataApi(payload.policyId.replace(/-/g, ''))
        })
      if (res) {
        yield put({
          type: actions.GET_RENEWAL_POLICY_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_RENEWAL_POLICY_FAILURE,
          message: getErrorMessage(res.status, res.data?.error_code),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_RENEWAL_POLICY_FAILURE,
        message: getErrorMessage(res.status, res.data?.error_code),
        data: []
      })
    }
  })
}

export function * updateEmail () {
  yield takeEvery(actions.UPDATE_EMAIL_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: { email: payload.email }, url: ConfigUrl.updateEmail(payload.applicationId) })
      if (res) {
        yield put({
          type: actions.UPDATE_EMAIL_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.UPDATE_EMAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_EMAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * submitApplication () {
  yield takeEvery(actions.SUBMIT_APPLICATION_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { url: ConfigUrl.submitApplication(payload.applicationId) })
      if (res) {
        yield put({
          type: actions.SUBMIT_APPLICATION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_APPLICATION_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SUBMIT_APPLICATION_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * sendPaymentLink () {
  yield takeEvery(actions.SEND_PAYMENT_LINK_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { url: ConfigUrl.sendPaymentLink(payload) })
      if (res) {
        yield put({
          type: actions.SEND_PAYMENT_LINK_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SEND_PAYMENT_LINK_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SEND_PAYMENT_LINK_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * resendPaymentLink () {
  yield takeEvery(actions.RESEND_PAYMENT_LINK_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { url: ConfigUrl.resendPaymentLink(payload) })
      if (res) {
        yield put({
          type: actions.RESEND_PAYMENT_LINK_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.RESEND_PAYMENT_LINK_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.RESEND_PAYMENT_LINK_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getApplicationList () {
  yield takeEvery(actions.GET_APPLICATION_LIST_REQUEST, function * ({ payload }) {
    try {
      if (!payload) {
        payload = { page_size: 5 }
      }
      const res = yield call(getAuthApi, { url: ConfigUrl.getApplicationList, query: payload })
      if (res) {
        yield put({
          type: actions.GET_APPLICATION_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_APPLICATION_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_APPLICATION_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: {}
      })
    }
  })
}

export function * getBrandList () {
  yield takeEvery(actions.GET_BRAND_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(
        getAuthApi, {
          url: ConfigUrl.getBrandList(payload.year),
          query: payload,
          params: { year: payload.year }
        })
      if (res) {
        yield put({
          type: actions.GET_BRAND_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_BRAND_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_BRAND_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getModelList () {
  yield takeEvery(actions.GET_MODEL_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getModelList, params: { brand: payload.brand }, query: { category: payload.category } })
      if (res) {
        yield put({
          type: actions.GET_MODEL_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_MODEL_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_MODEL_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getVariantList () {
  yield takeEvery(actions.GET_VARIANT_LIST_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, {
        url: ConfigUrl.getBrandVariant,
        params: {
          brand: payload.brand,
          year: payload.year
        },
        query: {
          category: payload.category,
          package_type: payload.package_type
        }
      })
      if (res) {
        yield put({
          type: actions.GET_VARIANT_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_VARIANT_LIST_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_VARIANT_LIST_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getVariantYear () {
  yield takeEvery(actions.GET_VEHICLE_YEAR_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, {
        url: ConfigUrl.getVariantYear
      })
      if (res) {
        yield put({
          type: actions.GET_VEHICLE_YEAR_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_VEHICLE_YEAR_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_VEHICLE_YEAR_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * sendQuotation () {
  yield takeEvery(actions.SEND_QUOTATION_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, { body: payload, url: ConfigUrl.sendQuotation })
      if (res) {
        yield put({
          type: actions.SEND_QUOTATION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SEND_QUOTATION_FAILURE,
          message: getErrorMessage(res.status, get(res, 'data.error.error_code', '') || get(res, 'data.error_code', '')),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SEND_QUOTATION_FAILURE,
        message: getErrorMessage(res.status, get(res, 'data.error.error_code', '') || get(res, 'data.error_code', '')),
        data: []
      })
    }
  })
}

export function * agentPayingRequestCall () {
  yield takeEvery(actions.AGENT_PAYING_REQUEST, function * ({ payload }) {
    try {
      // agent paying request and make payment
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.agentPayingApi(payload.token)
      })
      if (res) {
        yield put({
          type: actions.AGENT_PAYING_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.AGENT_PAYING_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.AGENT_PAYING_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * agentPayLaterRequestCall () {
  yield takeEvery(actions.PAY_LATER_REQUEST, function * ({ payload }) {
    try {
      // agent paying request and make payment
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.payLaterApi(payload.policyId)
      })
      if (res) {
        yield put({
          type: actions.PAY_LATER_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.PAY_LATER_FAILURE,
          message: (res.data && res.data.error_code) ? getErrorMessage(res.status, res.data.error_code) : res.data.error,
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.PAY_LATER_FAILURE,
        message: (res.data && res.data.error_code) ? getErrorMessage(res.status, res.data.error_code) : res.data.error,
        data: []
      })
    }
  })
}

export function * calculateAddonPremium () {
  yield takeEvery(actions.CALCULATE_ADDON_PREMIUM_REQUEST, function * ({ payload }) {
    try {
      // agent paying request and make payment
      const res = yield call(postAuthApi, {
        body: payload,
        url: ConfigUrl.calculateAddonPremium(payload.code)
      })
      if (res) {
        yield put({
          type: actions.CALCULATE_ADDON_PREMIUM_SUCCESS,
          data: {
            ...res.data,
            add_on_code: payload.addon_code
          },
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CALCULATE_ADDON_PREMIUM_FAILURE,
          message: res.data ? res.data.error : getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.CALCULATE_ADDON_PREMIUM_FAILURE,
        message: res.data ? res.data.error : getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * submitRenewalPolicyQuotationSaga () {
  yield takeEvery(actions.SUBMIT_RENEWAL_QUOTATION_DATA_REQUEST, function * ({ payload }) {
    try {
      // ConfigUrl.getPackages(payload.code)
      const res = yield call(postAuthApi, {
        body: {
          ...payload
        },
        url: ConfigUrl.submitRenewalPolicyQuoatationDataApi(payload.policyId.replace(/-/g, ''))
      })
      if (res) {
        yield put({
          type: actions.SUBMIT_RENEWAL_QUOTATION_DATA_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.SUBMIT_RENEWAL_QUOTATION_DATA_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.SUBMIT_RENEWAL_QUOTATION_DATA_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * updateTransactionTypeSaga () {
  yield takeEvery(actions.UPDATE_TRANSACTION_TYPE_REQUEST, function * ({ payload }) {
    try {
      // ConfigUrl.getPackages(payload.code)
      const res = yield call(postAuthApi, { url: ConfigUrl.convertPayLaterApi(payload) })
      if (res) {
        yield put({
          type: actions.UPDATE_TRANSACTION_TYPE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_TRANSACTION_TYPE_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.UPDATE_TRANSACTION_TYPE_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getInsuranceDocsSaga () {
  yield takeEvery(actions.GET_INSURANCE_DOCS_REQUEST, function * ({ payload }) {
    try {
      // ConfigUrl.getPackages(payload.code)
      const res = yield call(getAuthApi, { url: ConfigUrl.getInsuranceDocsApi(payload) })
      if (res) {
        yield put({
          type: actions.GET_INSURANCE_DOCS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_INSURANCE_DOCS_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_INSURANCE_DOCS_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getInstantPayoutDetailSaga () {
  yield takeEvery(actions.GET_INSTANT_PAYOUT_DETAIL_REQUEST, function * () {
    try {
      const res = yield call(getAuthApi, {
        url: ConfigUrl.instantPayoutBalanceApi
      })
      if (res) {
        yield put({
          type: actions.GET_INSTANT_PAYOUT_DETAIL_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_INSTANT_PAYOUT_DETAIL_FAILURE,
          message: getErrorMessage(res.status),
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_INSTANT_PAYOUT_DETAIL_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getPackages),
    fork(getRegionCode),
    fork(getTaxSlabSaga),
    fork(getPackageDetails),
    fork(getRenewalPackageDetailSaga),
    fork(getRenewalQuotationDataSaga),
    fork(submitRenewalPolicyQuotationSaga),
    fork(getWorkShopList),
    fork(getApplicationDetail),
    fork(getRenewalPolicySaga),
    fork(createPolicy),
    fork(updateEmail),
    fork(getUploadLink),
    fork(submitApplication),
    fork(sendPaymentLink),
    fork(resendPaymentLink),
    fork(getApplicationList),
    fork(getBrandList),
    fork(getModelList),
    fork(getVariantList),
    fork(getAddonsList),
    fork(getVaDetail),
    fork(downloadPaymentInstructions),
    fork(updateCreatePolicyQuotation),
    fork(updatePolicyQuotationDiscount),
    fork(sendQuotation),
    fork(agentPayingRequestCall),
    fork(calculateAddonPremium),
    fork(agentPayLaterRequestCall),
    fork(getDownloadPolicyPdfSaga),
    fork(getVariantYear),
    fork(getInsuranceDocsSaga),
    fork(getInstantPayoutDetailSaga),
    fork(updateTransactionTypeSaga),
    fork(validateChassisNumberSaga),
    fork(getDownloadDraftPdfSafa)
  ])
}
