import Button from '../Button'
import { ErrorBoundaryStyle } from './index.style'

function ErrorBoundary (props) {
  return (
    <ErrorBoundaryStyle>
      <div className='error-text'>
        <i className='fas fa-exclamation-triangle warning-error' /><br />
        Anda menemui kesalahan
      </div>
      <Button
        className='reload-btn' onClick={() => {
          window.location.reload()
          props.resetError()
        }}
      >
        Klik di sini untuk memuat ulang!
      </Button>
    </ErrorBoundaryStyle>
  )
}

export default ErrorBoundary
