import { Component } from 'react'
import PropTypes from 'prop-types'
import { VerticalRadioStyle } from './index.style'
import { Row } from '../../commonStyle/index.style'
import { RadioBox, RadioInput, Fill } from '../Radio/index.style'

class VerticalRadioGroup extends Component {
  static propTypes = {
    selected: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.object,
    disabled: PropTypes.bool,
    levelRightPosition: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func
  }

  render () {
    const {
      name, data, selected, children, label, id, align,
      disabled, className, levelRightPosition, onChange, // right and left
      onClick = () => {}
    } = this.props
    return (
      <VerticalRadioStyle
        className={className} key={id} onClick={() => {
          onChange && onChange(data, disabled)
          onClick && onClick(data, disabled)
        }}
      >
        <Row justify={levelRightPosition ? 'start' : 'space-between'} align={align || 'center'}>
          {!levelRightPosition && label}
          <RadioBox
            key={id}
            onClick={(e) => {
              e.stopPropagation()
              onClick && onClick(data, disabled)
              onChange && onChange(data, disabled)
            }}
          >
            <RadioInput
              value={data.id}
              name={name}
              checked={data.id === selected}
              disabled={disabled}
              aria-checked={data.id === selected}
              onChange={e => {}}
            />
            <Fill />
          </RadioBox>
          {levelRightPosition && label}
        </Row>
        {data.id === selected && children}
      </VerticalRadioStyle>
    )
  }
}

export default VerticalRadioGroup
