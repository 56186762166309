import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const InnerTextareaWrapper = styled.textarea.attrs(props => props.rows ? { rows: props.rows } : {})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.backgroundColor || AgentColorPallette.font};
  opacity: ${props => props.opacity || 1};
  margin-bottom: 12px;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 12px;
  color: ${props => props.error ? AgentColorPallette.errorDark : AgentColorPallette.black};
  border: ${props => props.error ? `2px solid ${AgentColorPallette.errorDark} !important` : props.displayBorder ? `1px solid ${AgentColorPallette.grey}` : 'none'};
  padding: 12px;
  font-weight: 600;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  resize: ${props => props.resize || 'both'};
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${AgentColorPallette.fontGrey};
    font-size: 12px;
  }
`
