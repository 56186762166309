import { isIos } from '../helpers/utility'
import { ANDROID_BRIDGE } from './android'
import { saveFCMToken } from './iOS'

export const saveFCMTokenBridge = (token = '') => {
  if (isIos()) {
    // unhandled error
    saveFCMToken(token).catch((e) => {})
  } else if (ANDROID_BRIDGE.saveFCMToken) {
    ANDROID_BRIDGE.saveFCMToken(token).catch((e) => {})
  }
}
