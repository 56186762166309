import { Component } from 'react'
import ReactModal from 'react-responsive-modal'
import { ModalWrapper } from './index.style'

class Modal extends Component {
  render () {
    const { isOpen, heading, onClose, children, className } = this.props
    const modalStyle = {
      modal: {
        padding: 0,
        width: '100%',
        minWidth: '250px',
        maxWidth: '350px',
        borderRadius: '24px'
      },
      overlay: {
        padding: 0,
        width: '100%'
        // maxWidth: 400
      },
      closeButton: {
        padding: '8px',
        top: 0
      }
    }

    return (
      <ModalWrapper className={className}>
        <ReactModal
          open={isOpen}
          styles={modalStyle}
          onClose={onClose}
          classNames={{
            closeButton: 'closeButton'
          }}
          center
        >
          <ModalWrapper className={className}>
            {
              heading && (
                <div className='modal-header'>
                  {heading || 'Title'}
                </div>
              )
            }
            <div className='modal-content'>
              {children}
            </div>
          </ModalWrapper>
        </ReactModal>
      </ModalWrapper>
    )
  }
}

export default Modal
