import styled from 'styled-components'

export const SearchBarStyle = styled.div`
  .search-icon{
    height: 20px;
  }
  .cross-icon{
    cursor: pointer;
    display: inline-block;
  }
  .input-search-img-container{
    background-color: ${props => props.theme.white};
    padding: 7.5px;
    border-radius: 10px 0px 0px 10px;
  }
  .search-input {
    border: unset;
    margin-bottom: 0px;
    padding: 10px;
    margin-left: 0px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: unset;
    margin-top: 0px;
    
  }

  .search-input-container{
    width: 86%;
    position: absolute;
    right: 42px;
    top: 7px;
  }

  .icon-container{
    text-align: center;
  }
`
