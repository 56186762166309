import styled from 'styled-components'

export const UploadBulkImgModalStyle = styled.div`
  
  .upload-file-btn-box {
    position: relative;
    padding: 16px 12px;
    height: auto;
    cursor: pointer;
    border-radius: 12px;
    border: ${props => props.error ? `2px solid ${props.theme.errorDark}` : 'none'};
  }

  .upload-btn-text {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.goldDarker};
  }

  .upload-file-count {
    padding: 16px 12px;
    border-top: 1px solid ${props => props.theme.borderGrey};
    margin-top: 6px;
    cursor: pointer;
  }

  .upload-file-input {
    top: 0; 
    left: 0;
    opacity: 0; 
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .uploaded-file-progress {
    padding: 16px 12px;
  }
`

export const ImgUploadProgress = styled.div`
  width: 100;
  position: relative;
  border-radius: 10px;
  height: 10px;
  margin-bottom: 4px;
  background-color: ${props => props.theme.fontLightGrey};
`

export const ImgUploadSuccessProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: ${props => props.right || '100%'};
  height: 10px;
  border-radius: 10px;
  background-color: ${props => props.theme.shineGold};
`
