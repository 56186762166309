import { useState, useEffect, useRef, useCallback } from 'react'
import { Row } from '../../commonStyle/index.style'
import { AgentColorPallette } from '../../configs/colorPallete'
import { LANG_STRINGS } from '../../lang'
import CustomText from '../CustomText'
import { ShowSnackbarStyle } from './index.style'

function ShowSnackbar (props) {
  const { show, text, hideOkay, error } = props
  const [state, setState] = useState({
    text,
    error,
    visible: show
  })
  const timerRef = useRef(null)

  ShowSnackbar.success = useCallback(({ text, visible }) => {
    setState({
      visible,
      text
    })
  }, [])

  ShowSnackbar.error = useCallback(({ text, visible }) => {
    setState({
      visible,
      text,
      error: true
    })
  }, [])

  useEffect(() => {
    // setSnackBar(show)
    if (state.visible) {
      timerRef.current = setTimeout(() => {
        setState({
          visible: false,
          text: '',
          error: false
        })
        clearTimeout(timerRef.current)
      }, 3000)
    }
  }, [state.visible])

  return state.visible ? (
    <ShowSnackbarStyle className={state.visible && 'show-snack'} error={state.error}>
      <Row align='center' justify='space-between' className='snack-bar-box'>
        <CustomText color={state.error ? AgentColorPallette.bloodRed : AgentColorPallette.white}>
          {state.text}
        </CustomText>
        {
          !hideOkay && !state.error && (
            <CustomText weight={800} color={AgentColorPallette.primary}>
              {LANG_STRINGS.okay}
            </CustomText>
          )
        }
      </Row>
    </ShowSnackbarStyle>
  ) : null
}

export default ShowSnackbar
