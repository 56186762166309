import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const InputOtpWrapper = styled.div`
  display: flex;
  padding: 12px 36px 12px 36px;
  .partitioned {
    border: 0;
    background-color: ${props => props.isNewDesign ? AgentColorPallette.white : AgentColorPallette.otpBackground};
    border-bottom: ${props => props.isNewDesign ? `1px solid ${AgentColorPallette.regular}` : ''};
    width: 100%;
    font-size: 24px;
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    border-radius: ${props => props.isNewDesign ? '' : '4px'};
    padding: 6px 8px;
    color: ${props => props.isNewDesign ? AgentColorPallette.regular : AgentColorPallette.white};
    font-weight: bold;
    max-width: 24px;
    margin: auto;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  input:focus {
    outline: none;
  }
  .display-none {
    opacity:0;
    width: 0;
  }
  @media only screen and (min-width: 320px) {
    padding: 12px;
  }
`
