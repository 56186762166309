import { GLOBAL_ACTIONS } from './actions'
import { getHeader, getGlobalStateObject } from '../../helpers/utility'
import { getMapProductWithIcon } from '../../helpers/data'

export const INITIAL_STATE = {
  globalState: {},
  isVNAgent: window.location.origin.includes('mitra-vn') || (process.env.REACT_APP_CC === 'VN'),
  showAgentPIC: false
}

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload = {} } = action
  switch (type) {
    case GLOBAL_ACTIONS.GLOBAL_STATE_UPDATE:
    case GLOBAL_ACTIONS.ADD_DEVICE_FCM:
    case GLOBAL_ACTIONS.GET_PRODUCT:
    case GLOBAL_ACTIONS.GET_OFFLINE_PRODUCT_LIST:
    case GLOBAL_ACTIONS.GET_PARTNER_TOKEN:
    case GLOBAL_ACTIONS.GET_VERIFICATION_DOC:
    case GLOBAL_ACTIONS.VERIFY_AGENT_EMAIL:
      return {
        ...state,
        ...payload,
        headers: getHeader()
      }
    case GLOBAL_ACTIONS.GET_ALL_PRODUCT:
      return {
        ...state,
        getAllProductData: ((payload.getAllProductData) ? [...payload.getAllProductData] : []),
        productIconData: getMapProductWithIcon(payload.getAllProductData)
      }
    case GLOBAL_ACTIONS.SAVE_CUSTOMER_DATA:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ...payload
        }
      }
    case GLOBAL_ACTIONS.CHECK_AUTHORIZATION:
      return {
        ...state,
        globalState: {
          isLoggedIn: payload.checkAuthorizationData?.message === 'Session active'
        }
      }
    case GLOBAL_ACTIONS.GET_BRANCH_LIST:
      return {
        ...state,
        ...payload
      }
    case GLOBAL_ACTIONS.SHOW_AGENT_PIC_INFO:
      return {
        ...state,
        showAgentPIC: !state.showAgentPIC
      }
    case GLOBAL_ACTIONS.GET_AGENT_DETAIL:
      return {
        ...state,
        ...payload,
        ...getGlobalStateObject(payload.getAgentDetailData)
      }
    case GLOBAL_ACTIONS.GET_AGENT_BADGES:
      return {
        ...state,
        ...payload,
        isHideAgentBadges: !payload.getAgentBadgesData?.actual_slab // TODO: [406, 403].includes(action.statusCode),
      }
    case GLOBAL_ACTIONS.GET_AGENT_GWP:
      return {
        ...state,
        ...payload
      }
    case GLOBAL_ACTIONS.LOGOUT_AGENT:
      return {
        globalState: {
          isLoggedIn: false
        },
        isLoggedOut: true,
        ...payload
      }
    case GLOBAL_ACTIONS.RESET_GLOBAL_INITIAL_STATE:
      return {
        globalState: {
          isLoggedIn: false
        },
        isLoggedOut: undefined
      }
    default:
      return {
        ...state
      }
  }
}
export default reducer
