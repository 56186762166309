import styled from 'styled-components'

export const SideMenuStyle = styled.div`
  position: relative;
  .list{
    list-style-type: none;
    position: absolute;
    top: 0;
    right: 10px;
    border-radius: 5%;
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.regular};
    font-size: 14px;
    font-weight: normal;
    padding: 5%;
    padding-bottom: 0px;
    width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01),0 6px 16px 0 rgba(0,0,0,0.06);
  }
  .sideMenuIcon{
    color: ${props => props.theme.black}
  }
`

export const SideMenuListItemStyle = styled.div`
  .list-item{
    padding: 16px;
    font-weight: 600;
    border-bottom: 1px solid ${props => props.theme.grey};
    color: ${props => props.fontColor || props.theme.black};
  }
`
