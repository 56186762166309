import { Component } from 'react'
import PropTypes from 'prop-types'
import { ModifyTermsConditionsStyle } from './index.style'
import CustomText from '../../../CustomText'
import { AgentColorPallette } from '../../../../configs/colorPallete'

class ModifyTermsConditions extends Component {
  static propTypes = {
    data: PropTypes.object
  }

  renderTerms = (terms = [], type) => {
    return (
      <ol className='modify-terms' type={type || '1'}>
        {
          terms.map((term, index) => (
            <li
              key={`terms_${index}`}
              className='modify-item'
            >
              {typeof term === 'object' ? term.title : term}
              {
                Array.isArray(term.terms) && this.renderTerms(term.terms, term.type)
              }
            </li>
          ))
        }
      </ol>
    )
  }

  renderBottom = (terms) => {
    return (
      <ul className='modify-terms modify-btm-list'>
        {
          terms.map((term, index) => (
            <li
              key={`terms_${index}`}
              className='modify-item'
            >
              {term}
            </li>
          ))
        }
      </ul>
    )
  }

  renderTitle = (title) => {
    return (
      <CustomText color={AgentColorPallette.black}>
        {title}
      </CustomText>
    )
  }

  render () {
    const { data = {} } = this.props
    const { title, terms = [], bottom = [] } = data
    return (
      <ModifyTermsConditionsStyle>
        {
          title && (
            <CustomText color={AgentColorPallette.black} weight={600}>
              {title}
            </CustomText>
          )
        }
        {
          this.renderTerms(terms)
        }
        {
          this.renderBottom(bottom)
        }
      </ModifyTermsConditionsStyle>
    )
  }
}

export default ModifyTermsConditions
