import { Component, createRef } from 'react'
import { CustomCollapseWrapper } from './index.style'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'

class CustomCollapse extends Component {
  constructor (props) {
    super(props)
    this.state = {
      label: props.label,
      isOpened: !!props.isOpened
    }
    this.ref = createRef(null)
  }

  handleClick (e) {
    const { ref } = this
    if (ref.current && ref.current.contains(e.target)) {
      const { isOpened } = this.state
      this.setState({
        isOpened: !isOpened
      })
    }
  }

  renderContent () {
    const { children } = this.props
    return (
      <div className='collapse-body'>
        {children}
      </div>
    )
  }

  renderArrow () {
    const { isOpened } = this.state
    if (isOpened) {
      return (
        <div>
          <i className='arrow-up' />
        </div>
      )
    } else {
      return (
        <div className='arrow-holder'>
          <i className='arrow-down' />
        </div>
      )
    }
  }

  render () {
    const { label, isOpened } = this.state
    const { headingClass, hideBorders } = this.props
    return (
      <CustomCollapseWrapper hideBorders={hideBorders}>
        <div ref={this.ref} className='collapse-header' onClick={(e) => this.handleClick(e)}>
          <CustomText size='13px' weight='700' color={AgentColorPallette.fontDarkerGrey} className={headingClass}>
            {label}
          </CustomText>
          {this.renderArrow()}
        </div>
        <div />
        <div className={isOpened ? '' : 'display-none'}>
          {this.renderContent()}
        </div>
      </CustomCollapseWrapper>
    )
  }
}

export default CustomCollapse
