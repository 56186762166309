import { ConfigUrl } from '../../configs/url'

export const GLOBAL_ACTIONS = {
  GLOBAL_STATE_UPDATE: 'GLOBAL_STATE_UPDATE',

  GET_AGENT_DETAIL_REQUEST: 'GET_AGENT_DETAIL_REQUEST',
  GET_AGENT_DETAIL: 'GET_AGENT_DETAIL',

  GET_ALL_PRODUCT_REQUEST: 'GET_ALL_PRODUCT_REQUEST',
  GET_ALL_PRODUCT: 'GET_ALL_PRODUCT',

  GET_AGENT_BADGES_REQUEST: 'GET_AGENT_BADGES_REQUEST',
  GET_AGENT_BADGES: 'GET_AGENT_BADGES',

  GET_BRANCH_LIST_REQUEST: 'GET_BRANCH_LIST_REQUEST',
  GET_BRANCH_LIST: 'GET_BRANCH_LIST',

  CHECK_AUTHORIZATION_REQUEST: 'CHECK_AUTHORIZATION_REQUEST',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  ADD_DEVICE_FCM_REQUEST: 'ADD_DEVICE_FCM_REQUEST',
  ADD_DEVICE_FCM: 'ADD_DEVICE_FCM',

  LOGOUT_AGENT_REQUEST: 'LOGOUT_AGENT_REQUEST',
  LOGOUT_AGENT: 'LOGOUT_AGENT',

  GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
  GET_PRODUCT: 'GET_PRODUCT',

  SHOW_AGENT_PIC_INFO: 'SHOW_AGENT_PIC_INFO',

  RESET_GLOBAL_INITIAL_STATE: 'RESET_GLOBAL_INITIAL_STATE',
  SAVE_CUSTOMER_DATA: 'SAVE_CUSTOMER_DATA',
  GET_GLOBAL_HEADERS: 'GET_GLOBAL_HEADERS',

  GET_PARTNER_TOKEN_REQUEST: 'GET_PARTNER_TOKEN_REQUEST',
  GET_PARTNER_TOKEN: 'GET_PARTNER_TOKEN',

  GET_VERIFICATION_DOC_REQUEST: 'GET_VERIFICATION_DOC_REQUEST',
  GET_VERIFICATION_DOC: 'GET_VERIFICATION_DOC',

  VERIFY_AGENT_EMAIL_REQUEST: 'VERIFY_AGENT_EMAIL_REQUEST',
  VERIFY_AGENT_EMAIL: 'VERIFY_AGENT_EMAIL',

  GET_OFFLINE_PRODUCT_LIST_REQUEST: 'GET_OFFLINE_PRODUCT_LIST_REQUEST',
  GET_OFFLINE_PRODUCT_LIST: 'GET_OFFLINE_PRODUCT_LIST',

  GET_AGENT_GWP_REQUEST: 'GET_AGENT_GWP_REQUEST',
  GET_AGENT_GWP: 'GET_AGENT_GWP'
}

export const updateGlobalState = (payload) => ({
  type: GLOBAL_ACTIONS.GLOBAL_STATE_UPDATE,
  payload
})

export const checkAuthorization = () => ({
  type: GLOBAL_ACTIONS.CHECK_AUTHORIZATION_REQUEST,
  payload: {
    method: 'GET',
    url: ConfigUrl.checkAuthorizationApi
  }
})

export const getAllProducts = () => ({
  type: GLOBAL_ACTIONS.GET_ALL_PRODUCT_REQUEST,
  payload: {
    url: ConfigUrl.getAllProducts,
    method: 'GET'
  }
})

export const getAgentDetails = (payload = {}) => ({
  type: GLOBAL_ACTIONS.GET_AGENT_DETAIL_REQUEST,
  payload: {
    ...payload,
    method: 'GET',
    url: ConfigUrl.getAgentDetails
  }
})

export const getAgentBadges = (payload) => ({
  type: GLOBAL_ACTIONS.GET_AGENT_BADGES_REQUEST,
  payload: {
    method: 'GET',
    query: {
      ...payload
    },
    url: ConfigUrl.getAgentBadgesApi
  }
})

export const getAgentGwp = (payload) => ({
  type: GLOBAL_ACTIONS.GET_AGENT_GWP_REQUEST,
  payload: {
    method: 'GET',
    query: {
      ...payload
    },
    url: ConfigUrl.getAgentGwpApi
  }
})

export const getBranchList = (payload) => ({
  type: GLOBAL_ACTIONS.GET_BRANCH_LIST_REQUEST,
  payload: {
    ...payload,
    method: 'GET',
    url: ConfigUrl.getBranchList(payload.providerCode)
  }
})

export const resetInitialState = () => ({
  type: GLOBAL_ACTIONS.RESET_GLOBAL_INITIAL_STATE
})

// all policy holder info
export const saveCustomerData = (payload) => ({
  type: GLOBAL_ACTIONS.SAVE_CUSTOMER_DATA,
  payload
})

export const addDeviceFCMToken = (payload) => ({
  type: GLOBAL_ACTIONS.ADD_DEVICE_FCM_REQUEST,
  payload: {
    ...payload,
    url: ConfigUrl.addDeviceFCMApi,
    method: 'POST'
  }
})

export const showAgentPICInfo = () => ({
  type: GLOBAL_ACTIONS.SHOW_AGENT_PIC_INFO
})

export const logoutAgent = () => ({
  type: GLOBAL_ACTIONS.LOGOUT_AGENT
})

export const logoutAgentRequest = (payload) => ({
  type: GLOBAL_ACTIONS.LOGOUT_AGENT_REQUEST,
  payload: {
    ...payload,
    method: 'POST',
    url: ConfigUrl.logout
  }
})

export const getGlobalHeaders = () => ({
  type: GLOBAL_ACTIONS.GET_GLOBAL_HEADERS
})

export const getProducts = () => ({
  type: GLOBAL_ACTIONS.GET_PRODUCT_REQUEST,
  payload: {
    url: ConfigUrl.getProduct,
    method: 'GET'
  }
})

export const getPartnerToken = (payload) => ({
  type: GLOBAL_ACTIONS.GET_PARTNER_TOKEN_REQUEST,
  payload: {
    ...payload,
    method: 'POST', // 'authGet',
    url: ConfigUrl.getPartnerTokenApi(payload.partner_code)
  }
})

export const getVerificationDoc = () => ({
  type: GLOBAL_ACTIONS.GET_VERIFICATION_DOC_REQUEST,
  payload: {
    method: 'GET',
    url: ConfigUrl.getVerificationDataUrl
  }
})

export const verifyAgentEmail = (payload = {}) => {
  return {
    type: GLOBAL_ACTIONS.VERIFY_AGENT_EMAIL_REQUEST,
    payload: {
      ...payload,
      method: 'POST',
      url: ConfigUrl.sendEmailVerifyLink
    }
  }
}

export const getOfflineProductList = () => ({
  type: GLOBAL_ACTIONS.GET_OFFLINE_PRODUCT_LIST_REQUEST,
  payload: {
    method: 'GET',
    url: ConfigUrl.getOfflineProductListApi
  }
})
