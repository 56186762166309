import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './action'
import authGetApi from '../../methods/authGet'
import authPostApi from '../../methods/authPost'
import { getErrorMessage } from '../../helpers/utility'
import { ConfigUrl } from '../../configs/url'
import { ERROR_LANG } from '../../lang'
import get from 'lodash/get'

export function * getTransactionList () {
  yield takeEvery(actions.GET_TRANSACTION_LIST_REQUEST, function * ({ payload }) {
    try {
      if (!payload) {
        payload = { page_size: 3 }
      }
      // ConfigUrl.getTransactionList
      const res = yield call(authGetApi, { url: ConfigUrl.getTransactionList, query: payload })
      if (res) {
        yield put({
          type: actions.GET_TRANSACTION_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_TRANSACTION_LIST_FAILURE,
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_TRANSACTION_LIST_FAILURE,
        data: {}
      })
    }
  })
}

export function * getPointsList () {
  yield takeEvery(actions.GET_POINTS_LIST_REQUEST, function * ({ payload }) {
    try {
      if (!payload) {
        payload = { page_size: 3 }
      }
      // ConfigUrl.getPointsTransactionListApi
      const res = yield call(authGetApi, { url: ConfigUrl.getPointsTransactionListApi, query: payload })
      if (res) {
        yield put({
          type: actions.GET_POINTS_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_POINTS_LIST_FAILURE,
          message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_POINTS_LIST_FAILURE,
        message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
        data: {}
      })
    }
  })
}

export function * getBankList () {
  yield takeEvery(actions.GET_BANK_LIST_REQUEST, function * () {
    try {
      const res = yield call(authGetApi, { url: ConfigUrl.getBankList })
      if (res) {
        yield put({
          type: actions.GET_BANK_LIST_SUCCESS,
          data: res.data
        })
      } else {
        yield put({
          type: actions.GET_BANK_LIST_FAILURE,
          data: []
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_BANK_LIST_FAILURE,
        data: []
      })
    }
  })
}

export function * getWithDrawalList () {
  yield takeEvery(actions.GET_WITHDRAWAL_LIST_REQUEST, function * ({ payload }) {
    try {
      if (!payload) {
        payload = {
          page_size: 5,
          status: 'SUCCESS,INITIATED,PENDING'
        }
      }
      if (!payload.status) {
        payload = {
          ...payload,
          status: 'SUCCESS,INITIATED,PENDING'
        }
      }
      const res = yield call(authGetApi, { url: ConfigUrl.withdrawalList, query: payload })
      if (res) {
        yield put({
          type: actions.GET_WITHDRAWAL_LIST_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_WITHDRAWAL_LIST_FAILURE,
          message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
          data: {}
        })
      }
    } catch (res) {
      yield put({
        type: actions.GET_WITHDRAWAL_LIST_FAILURE,
        message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
        data: {}
      })
    }
  })
}

export function * bankWithdrawRequest () {
  yield takeEvery(actions.BANK_WITHDRAW_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPostApi, { body: payload, url: ConfigUrl.requestBankWithdraw })
      if (res) {
        yield put({
          type: actions.BANK_WITHDRAW_SUCCESS,
          data: res.data
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: actions.BANK_WITHDRAW_FAILURE,
        message: get(res.data, 'error_code', '') === 'PP-AS-W-403' ? res.data : getErrorMessage(res.status, res.data.error_code),
        data: res.data
      })
    }
  })
}

export function * convertPointsRequest () {
  yield takeEvery(actions.CONVERT_POINTS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPostApi, {
        body: payload,
        url: ConfigUrl.convertPointUrl
      })
      if (res) {
        yield put({
          type: actions.CONVERT_POINTS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.CONVERT_POINTS_FAILURE,
          data: [],
          message: res.data.error
        })
      }
    } catch (res) {
      yield put({
        type: actions.CONVERT_POINTS_FAILURE,
        message: res.data ? res.data.error : ERROR_LANG.somethingWentWrong,
        data: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getTransactionList),
    fork(getPointsList),
    fork(getBankList),
    fork(getWithDrawalList),
    fork(convertPointsRequest),
    fork(bankWithdrawRequest)
  ])
}
