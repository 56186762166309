import { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import { PaymentBaseWrapper } from './index.style'
import CustomText from '../CustomText'

class PaymentBase extends Component {
  renderText (baseText) {
    return baseText.map((text, index) => (
      <Fragment key={index}>
        {text}<br />
      </Fragment>
    ))
  }

  render () {
    const { imageUrl, pageIcon, children, headerText, bodyText } = this.props
    return (
      <PaymentBaseWrapper imageUrl={imageUrl}>
        <img src={pageIcon} className='page-icon' alt='page-icon' />
        <CustomText textAlign='center' weight='800' size='24px'>
          {this.renderText(headerText)}
        </CustomText>
        <CustomText textAlign='center' weight='500' size='16px' top='5%' lineHeight='24px'>
          {this.renderText(bodyText)}
        </CustomText>
        {children}
      </PaymentBaseWrapper>
    )
  }
}

export default withRouter(PaymentBase)
