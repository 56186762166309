// getSharingData = (source) => {
//   const { quotationData = {}, quotation = {} } = this.props
//   const totalBill = quotation.accessories_sum_insured + quotation.vehicle_sum_insured
//   const shareMsg = 'Terima kasih telah melakukan transaksi Asuransi Kendaraan di PasarPolis Mitra. Lengkapi dokumen dan lanjutkan pembayaran di'
//   const linkOfShare = `${APP_HOST}/customer/policy/${quotationData.id}/?source=${source}`
//   let extraData = `Rincian transaksi\nNama Produk: ${quotation.package.name}\n`
//   extraData += `Penyedia Asuransi: ${quotation.provider.name}\n`
//   extraData += `Periode Perlindungan: ${quotation.premium.period} Bulan\n`
//   extraData += `Premi: ${formatMoney(quotation.sub_total, 0)}\n`
//   extraData += `Jumlah Pertanggungan: ${formatMoney(totalBill, 0)}`

import { shareWithImage, shareOnWhatsApp, shareWithMob, shareImageWithMob } from '../bridges/android'
import searchBuilder from './queryBuilder'
import { WHATS_APP_URL } from '../constants/appUrls'

//   return `${shareMsg}\n${linkOfShare}\n\n${extraData}`
// }

// handleWhatsAppShare = () => {
//   // this is handle the sharing on whatsapp
//   const queryString = searchBuilder({
//     text: this.getSharingData('quotation_wa')
//   })
//   gTagOnSendSendQuotationWA()
//   const url = encodeURI(`${WHATS_APP_URL}${queryString}`)
//   window.open(url, '_blank')
// }

export const shareWithReqularWhatsApp = (finalMsg, phone, callback = () => {}) => {
  const shareObj = {
    text: finalMsg
  }

  if (phone) {
    shareObj.phone = phone
  }
  const queryString = searchBuilder(shareObj)
  const url = encodeURI(`${WHATS_APP_URL}${queryString}`)
  callback(finalMsg, phone)
  // this to start timer for whatsAppBtn and disable the btn for 60 sec.
  window.open(url, '_blank')
}

export const sharingViaWhatsApp = ({ text, mobile, img, callback = () => {} }) => {
  if (img && window.PPBridge) {
    // share on whatsapp with specific number
    if (mobile) {
      // share on whtsapp with mobile
      shareImageWithMob(
        text,
        '',
        text,
        mobile,
        () => {
          shareWithReqularWhatsApp(text, mobile, callback)
        }
      )
      return
    }
    // share on whatsapp without mobile
    shareWithImage(
      this.shareInfographicImg,
      '',
      this.finalSharingMsg,
      () => {
        shareWithReqularWhatsApp(text, mobile, callback)
      }
    )
  } else if (mobile) {
    // share on whatsapp with specific number
    shareWithMob(
      '',
      text,
      mobile,
      () => {
        shareWithReqularWhatsApp(text, mobile, callback)
      }
    )
  } else {
    // share on whatsapp with text only
    shareOnWhatsApp(
      '',
      text,
      () => {
        shareWithReqularWhatsApp(text, mobile, callback)
      }
    )
  }
}
