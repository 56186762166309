import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-responsive-modal'
import { FullPageModalStyle, FullPageModalHeaderStyle, FullPageModalBody, PageModalHeader } from './index.style'
import { ImageUrl, PASAR_MITRA_BB } from '../../images'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import { isMobileView } from '../../helpers/utility'
import './index.css'

const FULL_PAGE_MODAL = {
  modal: {
    padding: 0,
    width: '100%',
    maxWidth: isMobileView() ? '100%' : '450px',
    backgroundColor: '#f5f5f5',
    height: '100%'
  },
  overlay: {
    padding: 0,
    width: '100%',
    height: '100vh'
    // maxWidth: 400
  },
  closeButton: {
    padding: '8px',
    color: '#ffffff',
    top: 0,
    zIndex: 10
  }
}

const TRANSPARENT_MODAL = {
  modal: {
    padding: 0,
    width: '100%',
    maxWidth: isMobileView() ? '100%' : '450px',
    backgroundColor: '#0000009e',
    height: '100%'
  },
  overlay: {
    padding: 0,
    width: '100%'
    // maxWidth: 400
  },
  closeButton: {
    padding: '8px',
    color: '#ffffff',
    top: 0,
    zIndex: 10
  }
}

class FullPageModal extends Component {
  static propTypes = {
    header: PropTypes.string,
    backBtnId: PropTypes.string,
    isImgHeader: PropTypes.bool,
    onCloseModal: PropTypes.func,
    history: PropTypes.object,
    showModal: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    isTransparent: PropTypes.bool
  }

  render () {
    const {
      showModal, header, isImgHeader, backBtnId,
      children, onCloseModal, isTransparent, rightIcon = null, leftIcon
    } = this.props
    let modelChangeStyles = FULL_PAGE_MODAL
    const modalClassNames = {
      closeIcon: ''
    }
    if (isTransparent) {
      modelChangeStyles = TRANSPARENT_MODAL
      modalClassNames.closeIcon = 'modal-white-btn'
    }
    const hideCloseIcon = true
    return (
      <FullPageModalStyle>
        <ReactModal
          open={showModal}
          styles={modelChangeStyles}
          classNames={modalClassNames}
          showCloseIcon={!hideCloseIcon}
          onClose={onCloseModal}
          focusTrapped={false}
          blockScroll
          closeIconSize={22}
          center
        >
          <PageModalHeader>
            <FullPageModalHeaderStyle isTransparent={isTransparent}>
              {
                hideCloseIcon && (
                  leftIcon ? (
                    <>
                      {leftIcon}
                    </>
                  ) : (
                    <div className='back'>
                      <img src={ImageUrl.backIcon} alt='icon' onClick={onCloseModal} id={backBtnId} />
                    </div>
                  )
                )
              }
              {
                isImgHeader ? (<img className='header-img' src={PASAR_MITRA_BB} alt='Mitra' />)
                  : (
                    <CustomText
                      size='14px'
                      weight={800}
                      color={!isTransparent && AgentColorPallette.black}
                      textAlign={isTransparent ? 'start' : 'center'}
                      className='header-title'
                    >
                      {header}
                    </CustomText>
                  )
              }
              {
                hideCloseIcon && rightIcon
              }
            </FullPageModalHeaderStyle>
          </PageModalHeader>
          <FullPageModalBody>
            {children}
          </FullPageModalBody>
        </ReactModal>
      </FullPageModalStyle>
    )
  }
}

export default FullPageModal
