import { Route, Redirect } from 'react-router-dom'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const logoutState = ['/profile', '/my-profile']
  return (
    <Route
      exact
      {...rest}
      render={props => {
        return isLoggedIn ? (<Component {...props} />)
          : (
            <Redirect
              to={{
                pathname: '/login',
                search: props.location.search,
                state: {
                  from: logoutState.includes(props.location.pathname) ? '/' : `${props.location.pathname}${props.location.search}`
                }
              }}
            />
          )
      }}
    />
  )
}

export default RestrictedRoute
