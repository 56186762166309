import { Component } from 'react'
import PropTypes from 'prop-types'
import { RadioWrapper, RadioBox, RadioInput, Fill } from './index.style'

class Radio extends Component {
  static propTypes = {
    radioSize: PropTypes.number,
    disabled: PropTypes.bool,
    hideLabel: PropTypes.bool,
    boxShadow: PropTypes.string,
    defaultValue: PropTypes.string,
    labels: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      selected: props.defaultValue || props.value[0]
    }
  }

  handleRadioChange = (value) => {
    if (this.props.disabled) return
    this.setState({ selected: value })
    this.props.onChange(value)
  }

  render () {
    const { labels, value, name, radioSize, hideLabel, boxShadow, defaultValue, disabled } = this.props
    const { selected } = this.state

    return (
      <RadioWrapper boxShadow={boxShadow} disabled={disabled}>
        {
          labels.map((label, index) => (
            <div
              className='radio-wrapper'
              key={`radio_${label}`}
              disabled={disabled}
            >
              {!hideLabel && <span className='radio-label'>{label}</span>}
              <RadioBox
                key={index}
                onClick={() => this.handleRadioChange(value[index])}
                size={radioSize}
              >
                <RadioInput
                  value={value[index]}
                  name={name}
                  checked={value[index] === selected}
                  aria-checked={value[index] === selected}
                  defaultValue={defaultValue}
                  defaultChecked={value[index] === defaultValue}
                />
                <Fill />
              </RadioBox>
            </div>
          ))
        }
      </RadioWrapper>
    )
  }
}

export default Radio
