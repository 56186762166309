import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const PolicyStepBarWrapper = styled.div`
  width: 100%;
  background-color: ${AgentColorPallette.white};
  padding: 4px 0px 4px 0px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  display: inline-table;
  .progressbar {
    counter-reset: step;
    padding-left: 0;
  }
  .progressbar li {
    list-style: none;
    display: inline-block;
    width: 20%;
    position: relative;
    text-align: center;
    cursor: pointer;
    font-size: 8px;
    margin: auto;
  }
  .progressbar li:before {
    content: ' \\25CF';
    color: ${AgentColorPallette.borderGrey};
    counter-increment: step;
    width: 13px;
    height: 12px;
    line-height: 10px;
    border: 1px solid ${AgentColorPallette.borderGrey};
    border-radius: 50%;
    text-align: center;
    margin: 10px auto;
    z-index: 1;
    background-color: ${AgentColorPallette.white};
    display: block;
    padding: 1px;
    font-size: 15px;
  }
  .progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${AgentColorPallette.borderGrey};
    top: 17px;
    left: calc(50% + 8px);
    z-index: 0;
  }
  .progressbar li:last-child:after {
    content: none;
  }
  .progressbar li.active {
    content: ' \\25CF';
    color: ${AgentColorPallette.blue};
    z-index: 0;
  }
  .progressbar li.active:before {
    content: ' \\25CF';
    color: ${AgentColorPallette.blue};
    border-color: ${AgentColorPallette.blue};
  } 
  .progressbar li.active:after {
    background-color: ${AgentColorPallette.blue};
  }
  @media screen and (max-width: 375px) {
    .progressbar li {
      font-size: 6px;
    }
  }
`
