import UploadFileButtonContainer from './container'
import { UploadFileContextProvider } from '../context'

function UploadFileButton (props) {
  return (
    <UploadFileContextProvider>
      <UploadFileButtonContainer {...props} />
    </UploadFileContextProvider>
  )
}

export default UploadFileButton
