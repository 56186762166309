export const INSURANCE_TYPE_COOKIES = 'INSURANCE_TYPE'
export const EMAIL_KTP_WARNING_COOKIE = 'KTP_EMAIL_WARNING_NOT_VERIFIED'
export const ADD_TO_HOME_COOKIE = 'ADD_TO_HOME'
export const RM_NOTIFICATION_COOKIE = 'RM_NOTIFICATION_COOKIE'
export const DASHBOARD_INFO = 'DASHBOARD_INFO'
export const DOWNLINE_INFO = 'DOWNLINE_INFO'
export const COVID_LINK_SHARING_INTRO = 'COVID_LINK_SHARING_INTRO'
export const COVID_PKG_CARD_INTRO = 'COVID_PKG_CARD_INTRO'
export const LOGIN_AS_GURDIAN = 'dacd'
export const LOGIN_INFO_MODAL = 'LOGIN_INFO_MODAL'
export const IS_TRANSACTION_CHANGE = 'IS_TRANSACTION_CHANGE'
export const REGISTRATION_DATA = 'REGISTRATION_DATA'

// localStorage Keys
export const POLICY_DATA_KEY = 'POLICY_DATA_KEY'
export const PACKAGE_ADDONS_KEY = 'PACKAGE_ADDONS_KEY'
export const POLICY_DATA_WITH_ADDONS = 'POLICY_DATA_WITH_ADDONS'
export const PACKAGE_CODE = 'PACKAGE_CODE'
export const CREATE_POLICY_DATA = 'CREATE_POLICY_DATA'
export const CUSTOMER_FLOW = 'CUSTOMER'
export const WALLET_VIEW = 'WALLET_VIEW'

export const POLICY_HOLDER_DATA = 'POLICY_HOLDER_DATA'
export const POLICY_MEMEBERS_DATA = 'POLICY_MEMEBERS_DATA'
export const INSURED_MEMEBERS_DATA = 'INSURED_MEMEBERS_DATA'

export const REDIRECT_URL = 'REDIRECT_URL'
export const PROOF_ID = 'proofId'
export const BANK_VA = 'BANK_VA'
export const SEND_QUOTATION_SUCCESS = 'SEND_QUOTATION_SUCCESS'

export const REFERRAL_CODE = 'REFERRAL_CODE'
// Property localstorage
export const PROPERTY_PROVINCE_CITY = 'PROPERTY_PROVINCE_CITY'
export const PROPERTY_FORM_1 = 'PROPERTY_FORM_1'
export const PROPERTY_BUILDING_VALUE = 'PROPERTY_BUILDING_VALUE'
export const PROPERTY_ADDONS = 'PROPERTY_ADDONS'
export const AGENT_MV_FORM_DATA = 'AGENT_MV_FORM_DATA'
export const AGENT_PROPERTY_FORM_DATA = 'AGENT_PROPERTY_FORM_DATA'
export const AGENT_CARGO_FORM_DATA = 'AGENT_CARGO_FORM_DATA'
export const IS_SCROLL_START = 'IS_SCROLL_START'

// referral Banner close and open cookie
export const REFERRAL_BANNER_AD_COOKIE = 'REFERRAL_BANNER_AD'
export const SHOW_VA_PAYMENT_BANNER_COOKIE = 'SHOW_VA_PAYMENT_BANNER'
export const SHOW_RAMADAN_BONANZA_COOKIE = 'SHOW_RAMADAN_BONANZA_COOKIE'

// Instant Payout policies
export const INSTANT_PAYOUT_POLICIES = 'INSTANT_PAYOUT_POLICIES'
export const INSTANT_POLICIES_INFO = 'INSTANT_POLICIES_INFO'
export const SHOW_DOWN_TIME = 'SHOW_DOWN_TIME'

// Questionnaire Success Submit MSG key
export const SHOW_QUESTIONNAIRE_SUBMIT_MSG = 'SHOW_QUESTIONNAIRE_SUBMIT_MSG'

// Editable Quoatation
export const TRANSACTION_EDIT_SUCCESS = 'TRANSACTION_EDIT_SUCCESS'

// renewal Data storage keys
export const RENEWAL_POLICY_EDIT_DATA = 'RENEWAL_POLICY_EDIT_DATA'
export const RENEWAL_POLICY_ACTION = 'RENEWAL_POLICY_ACTION'

// set Policy Pkg Request data
export const POLICY_PKG_REQ_DATA = 'POLICY_PKG_REQ_DATA'
export const POLICY_PKG_DATA = 'POLICY_PKG_DATA'

// steps Data
export const STEPS_DATA = 'STEPS_DATA'

// Offline to Online Data
export const OFFLINE_FORM_DATA = 'OFFLINE_FORM_DATA'

// key for Terms Modal
export const IS_TERMS_MODAL_VIEW = 'IS_TERMS_MODAL_VIEW'

// vehicle validated data
export const VEHICLE_FORM_DATA = 'VEHICLE_FORM_DATA'

// Draft Quote Create Id
export const DRAFT_QUOTE_ID = 'DRAFT_QUOTE_ID'
