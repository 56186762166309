import Auth from './auth/reducer'
import App from './app/reducer'
import Error from './error/reducer'
import Loading from './loading/reducer'
import Payment from './payment/reducer'
import User from './user/reducer'
import Transaction from './transaction/reducer'
import Policy from './policy/reducer'
import Verification from './verification/reducer'
import Customer from './customer/reducer'
import Lead from './lead/reducer'
import Motor from './motor/reducer'
import Address from './Address/reducer'
import Property from './property/reducer'

const reducers = {
  Auth,
  App,
  Error,
  Loading,
  Payment,
  User,
  Transaction,
  Policy,
  Verification,
  Customer,
  Lead,
  Motor,
  Address,
  Property
}

export default reducers
