import styled from 'styled-components'

export const PaymentBaseWrapper = styled.div`
  background-image: url(${props => props.imageUrl});
  height: 100%;
  min-height: 100vh;
  padding-left: 4%;
  padding-right: 4%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .page-icon {
    display: flex;
    margin: auto;
    padding-top: 30%;
    padding-bottom: 0%;
    width: 60%;
  }
`
