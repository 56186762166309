import styled from 'styled-components'

export const ConfirmationStyle = styled.div`
  .payment-cancel {
    font-size: 10px;
    color: ${props => props.theme.darkGold};
    text-align: center;
    margin-top: 20px;
    text-decoration: underline;
  }
`
