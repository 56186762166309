import { Component } from 'react'
import { InnerInputWrapper } from './index.style'

class InnerInput extends Component {
  render () {
    const {
      placeholder, backgroundColor, errorField,
      className, name, displayBorder, defaultValue,
      opacity, value, ...props
    } = this.props
    return (
      <InnerInputWrapper
        error={errorField === name}
        backgroundColor={backgroundColor}
        defaultValue={defaultValue}
        opacity={opacity}
        displayBorder={displayBorder}
        className={className}
        value={value}
        placeholder={placeholder || 'Masukkan sesuatu..'}
        {...props}
      />
    )
  }
}

export default InnerInput
