import actions from './action'

const initState = {
  transactionList: {},
  bankList: []
}

export default function transactionReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionList: action.data
      }
    case actions.GET_POINTS_LIST_SUCCESS:
    case actions.GET_POINTS_LIST_FAILURE:
      return {
        ...state,
        pointsList: action.data,
        pointStatusMsg: action.message
      }
    case actions.GET_BANK_LIST_SUCCESS:
    case actions.GET_BANK_LIST_FAILURE:
      return {
        ...state,
        bankList: action.data
      }
    case actions.GET_WITHDRAWAL_LIST_SUCCESS:
    case actions.GET_WITHDRAWAL_LIST_FAILURE:
      return {
        ...state,
        withdrawalList: action.data
      }
    case actions.BANK_WITHDRAW_SUCCESS:
    case actions.BANK_WITHDRAW_FAILURE:
      return {
        ...state,
        bankWithdrawStatus: action.message,
        bankWithdrawData: action.data
      }
    case actions.CONVERT_POINTS_SUCCESS:
    case actions.CONVERT_POINTS_FAILURE:
      return {
        ...state,
        pointConvertData: action.data,
        pointConvertStatus: action.message
      }
    case actions.RESET_TRANSACTION_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    default:
      return state
  }
}
