import { useState, useEffect, useCallback, useRef } from 'react'
import { SideMenuListItemStyle, SideMenuStyle } from './index.style.js'

export default function SideMenu (props) {
  const menuRef = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const [showSideMenu, setShowSideMenu] = useState(false)

  const handleToggleSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  const handleClickOutside = event => {
    const domNode = menuRef.current
    if (!domNode || !domNode.contains(event.target)) {
      setShowSideMenu(false)
    }
  }

  const handleItemClick = useCallback((itemClick) => {
    itemClick()
    setShowSideMenu(false)
  }, [setShowSideMenu])

  return (
    <SideMenuStyle ref={menuRef} className='sideMenu'>
      <div className='sideMenuIcon' onClick={handleToggleSideMenu}>
        <i className='fas fa-ellipsis-v' />
      </div>
      {showSideMenu &&
        <ul className='list' id='list'>
          {props.sideMenuItems.map((item, index) => (
            <SideMenuListItemStyle key={index} fontColor={(item.fontColor ? item.fontColor : '')}>
              <li className='list-item' onClick={() => handleItemClick(item.handleClick)}>
                {item.head}
              </li>
            </SideMenuListItemStyle>
          )
          )}
        </ul>}
    </SideMenuStyle>
  )
}
