import { all, fork, takeEvery, put, call } from 'redux-saga/effects'
import { ConfigUrl } from '../../configs/url'
import propertyActions from './actions'
import postAuthApi from '../../methods/authPost'
import getAuthApi from '../../methods/authGet'
import { getErrorMessage } from '../../helpers/utility'

export function * getProvinceSaga () {
  yield takeEvery(propertyActions.GET_PROPERTY_PROVINCE_REQUEST, function * () {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getPropertyProvinceApi })
      if (res) {
        yield put({
          type: propertyActions.GET_PROPERTY_PROVINCE_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: propertyActions.GET_PACKAGES_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getCitiesSaga () {
  yield takeEvery(propertyActions.GET_PROPERTY_CITIES_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getPropertyCities(payload) })
      if (res) {
        yield put({
          type: propertyActions.GET_PROPERTY_CITIES_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: propertyActions.GET_PROPERTY_CITIES_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getOccupationSaga () {
  yield takeEvery(propertyActions.GET_PROPERTY_OCCUPATION_REQUEST, function * () {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getOccupationApi })
      if (res) {
        yield put({
          type: propertyActions.GET_PROPERTY_OCCUPATION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: propertyActions.GET_PROPERTY_OCCUPATION_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getConstructionSaga () {
  yield takeEvery(propertyActions.GET_PROPERTY_CONSTRUCTION_REQUEST, function * () {
    try {
      const res = yield call(getAuthApi, { url: ConfigUrl.getConstructionApi })
      if (res) {
        yield put({
          type: propertyActions.GET_PROPERTY_CONSTRUCTION_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: propertyActions.GET_PROPERTY_CONSTRUCTION_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export function * getPropertyAddonsSaga () {
  yield takeEvery(propertyActions.GET_PROPERTY_ADDONS_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(postAuthApi, {
        body: payload.body,
        url: ConfigUrl.providerAddonsApi(payload.pkgCode)
      })
      if (res) {
        yield put({
          type: propertyActions.GET_PROPERTY_ADDONS_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        throw res
      }
    } catch (res) {
      yield put({
        type: propertyActions.GET_PROPERTY_ADDONS_FAILURE,
        message: getErrorMessage(res.status),
        data: []
      })
    }
  })
}

export default function * rootSaga () {
  yield all([
    fork(getProvinceSaga),
    fork(getCitiesSaga),
    fork(getOccupationSaga),
    fork(getConstructionSaga),
    fork(getPropertyAddonsSaga)
  ])
}
