import { useCallback, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Button, CustomText } from '..'
import { DisplayCircleIcon, DisplayFlexCCC, Row } from '../../commonStyle/index.style'
import { AgentColorPallette } from '../../configs/colorPallete'
import { contactService, getAgentName } from '../../helpers/utility'
import { CLOSE_GREY, BUSSINESS_MEN, WHATS_APP_NEW, CALL_NEW, WHATSAPP_SVG, EMAIL_SVG } from '../../images'
import { LANG_STRINGS } from '../../lang'
import BottomActionSheet from '../BottomActionSheet'
import { AgentPICSheetStyle } from './index.style'

function AgentPICSheet ({ show, onClose, agent }) {
  const agentTagHandler = useMemo(() => {
    return agent?.tag_handler_details
  }, [agent?.tag_handler_details])
  const [showCustomer, setShowCustomer] = useState(!agentTagHandler?.name)

  const handleShowCustomer = useCallback(() => {
    setShowCustomer(showCustomer => !showCustomer)
  }, [])

  const renderContactIcon = useCallback((icon, text, data, id) => {
    return (
      <DisplayFlexCCC
        onClick={() => contactService(data)}
        className='contact-card-gtm-id'
        data-gtm-element-id={id}
      >
        <Button
          borderRadius='8px'
          className='contact-card-gtm-id'
          data-gtm-element-id={id}
          padTop='8px'
          padBtm='8px'
        >
          <img
            className='contact-icon contact-card-gtm-id'
            src={icon}
            alt={text}
            data-gtm-element-id={id}
          />
        </Button>
        <CustomText
          color={AgentColorPallette.darkCharcoal}
          size='12px'
          top='4px'
          className='contact-card-gtm-id'
          data-gtm-element-id={id}
        >
          {text}
        </CustomText>
      </DisplayFlexCCC>
    )
  }, [])

  const renderIconText = useCallback((icon, text, data, contactBtnGTM) => {
    return (
      <DisplayFlexCCC
        onClick={() => contactService(data)}
        id={contactBtnGTM}
      >
        <img src={icon} alt={text} />
        <CustomText color={AgentColorPallette.darkCharcoal} size='0.75em'>
          {text}
        </CustomText>
      </DisplayFlexCCC>
    )
  }, [])

  return show ? createPortal(
    <BottomActionSheet show={show} onCloseSheet={onClose} zIndex={4}>
      <AgentPICSheetStyle>
        <div className='help-agent-header'>
          <CustomText
            color={AgentColorPallette.black}
            size='1em'
            weight={600}
            className='help-title'
          >
            {LANG_STRINGS.help}
          </CustomText>
          <div className='close-icon' onClick={onClose}>
            <img src={CLOSE_GREY} alt='close' />
          </div>
        </div>
        {
          agentTagHandler?.name && (
            <div className='agent-pic-container'>
              <DisplayFlexCCC>
                <DisplayCircleIcon
                  bgColor={AgentColorPallette.platinum}
                  width='80px'
                  height='80px'
                  className='rm-icon-circle'
                >
                  <img src={BUSSINESS_MEN} alt='' />
                </DisplayCircleIcon>
                <CustomText
                  color={AgentColorPallette.darkCharcoal}
                  size='14px'
                  weight={700}
                  top='10px'
                  bottom='1px'
                >
                  {agentTagHandler?.name}
                </CustomText>
                <CustomText
                  color={AgentColorPallette.fontGrey}
                  size='12px'
                  weight={700}
                  bottom='10px'
                >
                  {LANG_STRINGS.relationshipManager}
                </CustomText>
              </DisplayFlexCCC>
              <div className='rm-contact-box'>
                {
                  renderContactIcon(
                    WHATS_APP_NEW,
                    LANG_STRINGS.whatsApp,
                    {
                      type: 'whatsapp',
                      mobile: agentTagHandler?.phone_no,
                      WAText: `hi ${agentTagHandler?.name},\nsaya ${agent?.name}, salah satu mitra di PasarPolis ingin menanyakan terkait polis/aplikasi mitra`
                    },
                    'rm_wa_detail'
                  )
                }
                {
                  renderContactIcon(
                    CALL_NEW,
                    LANG_STRINGS.telephone,
                    {
                      type: 'mobile',
                      mobile: agentTagHandler?.phone_no
                    },
                    'rm_phone_detail'
                  )
                }
              </div>
            </div>
          )
        }
        <div className='customer-section'>
          {
            agentTagHandler?.name && (
              <Row align='center' justify='space-between' className='customer-support-box' onClick={handleShowCustomer}>
                <CustomText
                  color={AgentColorPallette.black}
                  weight={600}
                  size='0.9em'
                  bottom='0'
                >
                  {LANG_STRINGS.canNotReachAgentName.replace('XXX', getAgentName(agentTagHandler?.name))}
                </CustomText>
                <div className='line' />
                <CustomText color={AgentColorPallette.fontSemiGrey} textAlign='end'>
                  <i
                    className={`fas fa-angle-down ${showCustomer ? 'caret-rotate-up' : 'caret-rotate-down'}`}
                  />
                </CustomText>
              </Row>
            )
          }
          <div className={`animation-hide ${showCustomer && 'animation-show'}`}>
            <CustomText size='0.75em' color={AgentColorPallette.darkCharcoal} bottom='8px'>
              {LANG_STRINGS.contactOurCustomerService}
            </CustomText>
            <div className='customer-helpline'>
              {
                renderIconText(
                  WHATSAPP_SVG,
                  LANG_STRINGS.whatsApp,
                  {
                    type: 'whatsapp',
                    mobile: '62818343494',
                    WAText: ''
                  },
                  'contact-customer-service-wa'
                )
              }
              {
                renderIconText(
                  EMAIL_SVG,
                  LANG_STRINGS.email,
                  {
                    type: 'email',
                    email: 'cs@ppibroker.com'
                  },
                  'contact-customer-service-email'
                )
              }
            </div>
          </div>
        </div>
      </AgentPICSheetStyle>
    </BottomActionSheet>,
    document.getElementById('bottom-sheet-portal')
  ) : null
}

export default AgentPICSheet
