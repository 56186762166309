import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import { AnchorTagBtn, ButtonWithArrow } from './index.style'
import { AgentColorPallette } from '../../configs/colorPallete'
import CustomText from '../CustomText'

class ButtonWithIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
    id: PropTypes.string,
    gtmId: PropTypes.string,
    text: PropTypes.string,
    as: PropTypes.string,
    href: PropTypes.string,
    weight: PropTypes.number,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    iconRight: PropTypes.bool
  }

  render () {
    const {
      icon, text, onClick, className = '', as, isArrowButton,
      href, id = '', iconRight, disabled, weight, gtmId = '', color, backgroundColor
    } = this.props
    return (
      as === 'a' ? (
        <AnchorTagBtn
          id={id}
          href={href}
          className={`${className} ${iconRight && 'btn-icon-position'} ${id} ${gtmId}`}
          target='_blank'
          data-gtm-element-id={id}
        >
          {
            !iconRight && (
              <>
                <img
                  src={icon}
                  alt={text}
                  className={`btn-img ${id} ${gtmId}`}
                  data-gtm-element-id={id}
                />&nbsp;&nbsp;
              </>
            )
          }
          {text}
          {
            iconRight && (
              <>
                <img
                  src={icon}
                  alt={text}
                  data-gtm-element-id={id}
                  className={`btn-img ${id} ${gtmId}`}
                />
              </>
            )
          }
        </AnchorTagBtn>
      ) : isArrowButton
        ? (
          <ButtonWithArrow
            className={`${className} ${id}`}
            onClick={onClick}
            disabled={disabled}
            id={id}
          >
            <CustomText
              color={AgentColorPallette.goldDarker}
              weight={weight || 700}
              size='14px'
            >
              {text}
            </CustomText>
            {
              icon ? (
                <img
                  src={icon}
                  alt={text}
                  className={`btn-img ${id} ${gtmId}`}
                  data-gtm-element-id={id}
                />
              ) : (
                <CustomText color={AgentColorPallette.fontGrey} weight={700}>
                  <i className='fas fa-angle-right' />
                </CustomText>
              )
            }
          </ButtonWithArrow>
        )
        : (
          <Button
            className={`${className} ${iconRight && 'btn-icon-position'} ${id} ${gtmId}`}
            onClick={onClick}
            disabled={disabled}
            id={id}
            color={color}
            backgroundColor={backgroundColor}
          >
            {
              !iconRight && (
                <>
                  <img
                    src={icon}
                    alt={text}
                    className={`btn-img ${id} ${gtmId}`}
                    data-gtm-element-id={id}
                  />&nbsp;&nbsp;
                </>
              )
            }
            <div className={`btn-text ${id} ${gtmId}`} data-gtm-element-id={id}>{text}</div>
            {
              iconRight && (
                <>
                  <img
                    src={icon}
                    alt={text}
                    className={`btn-img ${id} ${gtmId}`}
                    data-gtm-element-id={id}
                  />
                </>
              )
            }
          </Button>
        )
    )
  }
}

export default ButtonWithIcon
