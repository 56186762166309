import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from './sagas'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middleware = [thunk, sagaMiddleware, routeMiddleware]

const rootReducer = combineReducers({
  ...reducers,
  router: routerReducer
})

const store = createStore(
  (state, action) => rootReducer(['LOGOUT_REQUEST', 'LOGOUT_SUCCESS'].includes(action.type) ? undefined : state, action),
  compose(applyMiddleware(...middleware))
)

sagaMiddleware.run(rootSaga)
export { store, history }
