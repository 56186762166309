import actions from './action'

const initState = {
  paymentToken: {},
  paymentTokenStatus: '',
  paymentDetail: {},
  paymentDetailStatus: '',
  paymentDetailErrorCode: '',
  paymentStatus: {},
  paymentStatusMessage: '',
  redirectPayment: false
}

export default function paymentReducer (state = initState, action) {
  switch (action.type) {
    case actions.RESET_PAYMENT_REDUX: {
      const { payload } = action
      if (payload && Array.isArray(payload)) {
        const stateToBeReset = {}
        payload.forEach(key => {
          stateToBeReset[key] = initState[key]
        })
        return {
          ...state,
          ...stateToBeReset
        }
      } else {
        return {
          ...state,
          ...initState
        }
      }
    }
    case actions.GET_PAYMENT_TOKEN_SUCCESS:
    case actions.GET_PAYMENT_TOKEN_FAILURE:
      return {
        ...state,
        paymentToken: action.data,
        paymentTokenStatus: action.message,
        redirectPayment: action.redirectPayment
      }
    case actions.GET_PAYMENT_DETAIL_SUCCESS:
    case actions.GET_PAYMENT_DETAIL_FAILURE:
      return {
        ...state,
        paymentDetail: action.data,
        paymentDetailStatus: action.message,
        paymentDetailErrorCode: action.error_code
      }
    case actions.GET_VA_BANK_LIST_SUCCESS:
    case actions.GET_VA_BANK_LIST_FAILURE:
      return {
        ...state,
        vaBankList: action.data,
        vaBankListMsg: action.message
      }
    case actions.GET_PAYMENT_STATUS_SUCCESS:
    case actions.GET_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        paymentStatus: action.data,
        paymentStatusMessage: action.message
      }
    case actions.CALCULATE_SERVICE_CHARGE_SUCCESS:
    case actions.CALCULATE_SERVICE_CHARGE_FAILURE:
      return {
        ...state,
        serviceChargeData: action.data,
        serviceChargerMsg: action.message
      }
    default:
      return state
  }
}
