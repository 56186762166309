import { Component } from 'react'
import { InputOtpWrapper } from './index.style'

class OtpInput extends Component {
  state = {
    list: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'],
    placeholder: ['-', '-', '-', '-', '-', '-']
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.error) {
      this.setState({ first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' })
    }
  }

  onChange = (e, item) => {
    if (e.target.value.length > 0) {
      // this.setState({ deleteValue: false, [item]: e.target.value })
      if (item === 'sixth') {
        this.props.onSubmit(`${this.state.first + this.state.second + this.state.third + this.state.fourth + this.state.fifth + e.target.value}`)
      }
      if (e.target.value.length > 1) {
        const otp = e.target.value.toString().split('')
        const elements = document.getElementsByClassName('partitioned')
        const { list } = this.state
        otp.forEach((code, index) => {
          const inputElm = elements[index]
          inputElm.value = ''
          // inputElm.value = parseInt(code)

          setTimeout(() => {
            this.refs[list[index]].nextSibling.focus()
            this.setState({
              [list[index]]: code
            })
          }, 100)
        })
        setTimeout(() => {
          this.props.onSubmit(otp.join(''))
        }, 1000)
      } else {
        this.setState({
          [item]: e.target.value
        })
        this.refs[item].nextSibling.focus()
      }
    }
  }

  moveToPreviousSibling = (item) => {
    this.refs[item].previousSibling.focus()
  }

  handleDelete = (e, item) => {
    const { list } = this.state
    if (e.key === 'Backspace') {
      if (item !== 'end') {
        const index = list.indexOf(item)
        this.setState({ [list[index - 1]]: '' })
        this.moveToPreviousSibling(item)
      }
    }
  }

  render () {
    const { list } = this.state
    return (
      <InputOtpWrapper isNewDesign={this.props.isNewDesign}>
        {
          list.map((item, index) => (
            <input
              key={`otp_${index + 1}`}
              className='partitioned'
              type='number'
              minLength={1}
              maxLength={1}
              value={this.state[item]}
              placeholder={this.props.isNewDesign && '-'}
              autocomplete='one-time-code'
              onChange={(e) => this.onChange(e, item)}
              onKeyDown={e => this.handleDelete(e, index ? item : 'end')}
              ref={item}
            />
          ))
        }
        <input
          className='display-none'
          type='number'
          minLength={1}
          maxLength={1}
          onKeyDown={e => this.handleDelete(e, 'last')}
          ref='last'
        />
      </InputOtpWrapper>
    )
  }
}

export default OtpInput
