import { createRef, Component } from 'react'
import { isEmpty } from 'lodash/lang'
import { get } from 'lodash/object'
import { FormWrapper } from './index.style'
import Button from '../Button'
import { checkEmpty, typeValid, checkDobValidity } from '../../helpers/validations'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import Select from '../Select'
import InnerInput from '../InnerInput'
import Radio from '../Radio'
import Upload from '../Upload'
import AutoSearchInput from '../AutoSearchInput'
import NumericInput from '../NumericInput'
import { ERROR_LANG, LANG_STRINGS } from '../../lang'
import DobInput from '../DobInput'
import Textarea from '../Textarea'
import { LIGHT_GOLD_BULB } from '../../images'
import { Row } from '../../commonStyle/index.style'
import AutoSearchModal from '../AutoSearchModal'
import { getNameErrorMsg } from '../../helpers/utility'
import { smoothScroll } from '../../helpers/smoothScroll'
import { getFromLocalStorageData, removeFromLocalStorageData, setToLocalStorage } from '../../helpers/localstorage'
import { IS_SCROLL_START } from '../../constants/cookies'
import RadioInputCard from '../RadioInputCard'
import UploadFileButton from '../Upload/UploadFileButton'
import TitleError from '../TitleError'
import UploadBulkImgModal from '../Upload/UploadBulkImgModal'
import debounce from 'lodash/debounce'

import TimePickerOld from '../TimePickerOld'

class InnerForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      errorField: {},
      errorValue: {}
    }
    this.initState = {}
    this.searchAutoModalRef = createRef()
  }

  componentDidMount () {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.props.formData.fields.forEach((field) => {
      if (field.type === 'radio') {
        this.setState(prevState => ({ [field.name]: field.defaultValue || field.value[0] }))
      } else {
        this.setState(prevState => ({ [field.name]: '', errorField: { ...prevState.errorField, [field.name]: '' } }))
      }
    })
  }

  componentWillUnmount () {
    removeFromLocalStorageData(IS_SCROLL_START)
  }

  getErrorValue = (type) => {
    switch (type) {
      case 'select':
        return 'Harus Dipilih'
      case 'number':
        return 'Harus Angka'
      case 'chasis_number':
      case 'machine_number':
        return 'Format Tidak Valid'
      case 'phone_number':
        return ERROR_LANG.phoneRequiredError
      case 'email':
        return 'Format Email Salah'
      default:
        return 'Harus Diisi'
    }
  }

  handleOpenImgModal = (item) => {
    this.props.onGuideClick(item)
  }

  resetSelect = (fields) => {
    this.setState({ ...fields })
  }

  handleSubmit = async (event) => {
    event && event.preventDefault()
    const { formData } = this.props
    let error = true
    let errorObj = {
      ...this.state.errorField
    }
    let firstErrorElementId = ''
    formData.fields.forEach((field) => {
      let stateValue = get(this.state, `${field.name}`, '')
      if (typeof stateValue === 'string') {
        stateValue = stateValue.trim()
      }
      if (checkEmpty(stateValue) && !field.skipEmptyCheck) {
        errorObj = {
          ...errorObj,
          [field.name]: 'Harus Diisi'
        }
        if (!firstErrorElementId) {
          firstErrorElementId = field.name
        }
      } else if (field.minLength && (stateValue.length < field.minLength)) {
        const errText = `Harus lebih dari ${field.minLength} karakter`
        errorObj = {
          ...errorObj,
          [field.name]: field.lengthErr ? field.lengthErr : errText
        }
        if (!firstErrorElementId) {
          firstErrorElementId = field.name
        }
      } else if (field.maxLength && (stateValue.length > field.maxLength)) {
        const errText = `maksimum ${field.maxLength} karakter`
        errorObj = {
          ...errorObj,
          [field.name]: field.lengthErr ? field.lengthErr : errText
        }
        if (!firstErrorElementId) {
          firstErrorElementId = field.name
        }
      } else if (field.type === 'dob' && !checkDobValidity(stateValue, field.min, field.max, field.minType)) {
        // check validity here
        errorObj = {
          ...errorObj,
          [field.name]: field.error || this.getErrorValue(field.type)
        }
        if (!firstErrorElementId) {
          firstErrorElementId = field.name
        }
      } else if ((!field.skipEmptyCheck && stateValue !== '' && !typeValid(field.type, stateValue)) || (stateValue && !typeValid(field.type, stateValue))) {
        const value = this.state[field.name]
        error = true
        let errorText = field.error || this.getErrorValue(field.type)
        if (field.type === 'name') {
          // check the error text for name
          errorText = getNameErrorMsg(value)
        }
        errorObj = {
          ...errorObj,
          [field.name]: errorText
        }
        if (!firstErrorElementId) {
          firstErrorElementId = field.name
        }
      } else {
        error = false
        errorObj = {
          ...errorObj,
          [field.name]: ''
        }

        delete errorObj[field.name]
      }
    })

    if (!isEmpty(errorObj)) {
      this.setState({
        errorField: errorObj
      })
      if (getFromLocalStorageData(IS_SCROLL_START) !== true && firstErrorElementId) {
        setToLocalStorage(IS_SCROLL_START, true)
        smoothScroll(`${firstErrorElementId}_div`)
      }
      if (this.props.isReturnError) {
        return {
          error: {
            ...errorObj
          }
        }
      }
    }
    if (!error && isEmpty(errorObj)) {
      await formData.fields.forEach(field => {
        return this.setState(prevState => ({
          errorField: { ...prevState.errorField, [field.name]: '' },
          [field.name]: field.type === 'number' ? Number(this.state[field.name]) : this.state[field.name]
        }))
      })
    }
    if (!error && isEmpty(errorObj)) {
      const formValues = this.state
      delete formValues.error
      // delete formValues.errorField
      delete formValues.errorValue
      if (formValues.additional_sum_insured === '') formValues.additional_sum_insured = 0
      if (this.props.onSubmit) {
        this.props.onSubmit(formValues)
      } else return formValues
    }
  }

  renderButton = ({ type, index, text, className, id }) => {
    return (
      <Button type={type} key={index} className={className} id={`${id}-formSubmit`}>
        {text}
      </Button>
    )
  }

  handleChassisNumberInput = debounce((e, name) => {
    if (typeValid(name, e.target.value)) {
      if (this.props.onValidateChassisNumber) {
        this.props.onValidateChassisNumber(e.target.value)
      }
    }
    this.handleInputChanges(e, name)
  }, 1000)

  handleInputChanges = (e, name) => {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      error: false,
      [name]: e.target.value && e.target.value.trim(),
      errorField: { ...this.state.errorField, [name]: '' }
    }, () => {
      if (this.props.onInputChange) {
        this.props.onInputChange(this.state[name], name)
      }
    })
  }

  handleUploadChange = (value, name) => {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      error: false,
      [name]: value,
      errorField: { ...this.state.errorField, [name]: '' }
    }, () => {
      if (this.props.onUploadChange) {
        this.props.onUploadChange(this.state[name], name)
      }
    })
  }

  handleTimeChange = (name, value) => {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      error: false,
      [name]: value,
      errorField: { ...this.state.errorField, [name]: '' }
    }, () => {
      if (this.props.onInputChange) {
        this.props.onInputChange(value, name)
      }
    })
  }

  handleDobChange = (data, name) => {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      error: false,
      [name]: data,
      errorField: { ...this.state.errorField, [name]: '' }
    })
  }

  handleSelectAutoInput = (name, value, data = {}) => {
    if (this.props.onAutoSearchSelect) {
      this.props.onAutoSearchSelect(name, value, data)
    }
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      error: false,
      [name]: value,
      [`${name}_data`]: data,
      errorField: {
        ...this.state.errorField,
        [name]: ''
      }
    }, () => {
      this.props.onChangeSelect && this.props.onChangeSelect({ [name]: value })
    })
  }

  updateInitialState = () => {
    removeFromLocalStorageData(IS_SCROLL_START)
    this.setState({
      ...this.initState
    })
  }

  handleRadioCardChange = (data, name) => {
    // need to handle Radio input card
    this.setState({
      [name]: data
    })
  }

  renderItem = ({
    type, index, name, defaultValue, defaultData = '', placeholder,
    backgroundColor, opacity, label, data, value, autoFocus,
    dataKey, displayKey, border, className, ref, thousandSeparator,
    decimalSeparator, disabled: itemDisabled, minDate = '', maxDate = '',
    fileType = ''
  }) => {
    const { loading, disabled } = this.props
    const { errorField } = this.state
    const isDefaultKey = `is${name}Default`
    if (defaultValue && !this.state[isDefaultKey] && !this.state[name]) {
      this.initState = {
        ...this.initState,
        [name]: defaultValue,
        [`is${name}Default`]: true
      }
      if (defaultData) {
        this.initState[`${name}_data`] = defaultData
      }
      setTimeout(() => {
        this.updateInitialState()
      }, 50)
    }
    switch (type) {
      case 'chasis_number':
        return (
          <InnerInput
            key={index}
            errorField={errorField[name] ? name : ''}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            displayBorder={border}
            opacity={opacity}
            defaultValue={defaultValue}
            className={className}
            label={label}
            disabled={itemDisabled || disabled}
            name={name}
            onChange={(e) => this.handleChassisNumberInput(e, name)}
          />
        )
      case 'input':
      case 'name':
      case 'email':
      case 'color':
      case 'machine_number':
      case 'vehicle_plate_no':
        return (
          <InnerInput
            key={index}
            errorField={errorField[name] ? name : ''}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            displayBorder={border}
            opacity={opacity}
            defaultValue={defaultValue}
            className={className}
            label={label}
            disabled={itemDisabled || disabled}
            name={name}
            onChange={e => this.handleInputChanges(e, name)}
          />
        )
      case 'ktp':
      case 'npwp':
      case 'numeric':
      case 'phone_number':
      case 'postal_code':
      case 'amount':
      case 'number':
        return (
          <NumericInput
            errorField={errorField[name] ? name : ''}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            opacity={opacity}
            name={name}
            type={type}
            autoFocus={autoFocus}
            value={this.state[name]}
            displayBorder={border}
            className={className}
            disabled={disabled}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}

            onChange={(value) => {
              removeFromLocalStorageData(IS_SCROLL_START)
              this.setState({
                error: false,
                [name]: value,
                errorField: { ...this.state.errorField, [name]: '' }
              }, () => {
                if (this.props.onInputChange) {
                  this.props.onInputChange(value, name)
                }
              })
            }}
          />
        )
      case 'select':
        return (
          <Select
            options={data}
            errorField={errorField[name] ? name : ''}
            loading={loading}
            name={name}
            value={this.state[name] || defaultValue}
            defaultValue={defaultValue}
            displayKey={displayKey}
            dataKey={dataKey}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(value) => {
              removeFromLocalStorageData(IS_SCROLL_START)
              this.setState({ error: false, [name]: value, errorField: { ...this.state.errorField, [name]: '' } })
              this.props.onChangeSelect && this.props.onChangeSelect({ [name]: value })
            }}
          />
        )
      case 'radioCard':
        return (
          <RadioInputCard
            data={data}
            name={name}
            defaultSelected={defaultValue}
            onChange={(data) => this.handleRadioCardChange(data, name)}
          />
        )
      case 'radio':
        return (
          <Radio
            labels={data}
            defaultValue={defaultValue}
            value={value}
            name={name}
            disabled={itemDisabled || disabled}
            onChange={(e) => {
              removeFromLocalStorageData(IS_SCROLL_START)
              this.setState({ error: false, [name]: e })
              this.props.onChangeRadio && this.props.onChangeRadio({ [name]: e })
            }}
          />
        )
      case 'uploadFileButton':
        return (
          <UploadFileButton
            onUpload={(value, index) => this.handleUploadChange(value, name, index)}
            defaultValue={defaultValue}
            name={name}
            label={label}
            className={className}
            fileType={fileType}
            displayBorder={border}
            showImg
            errorField={errorField[name] ? name : ''}
          />
        )
      case 'uploadBulkImgButton':
        return (
          <UploadBulkImgModal
            onUpload={(value, index) => this.handleUploadChange(value, name, index)}
            defaultValue={defaultValue}
            name={name}
            label={label}
            className={className}
            displayBorder={border}
            fileType={fileType}
            showImg
            errorField={errorField[name] ? name : ''}
          />
        )
      case 'upload':
        return (
          <Upload
            onUpload={(value, index) => this.handleUploadChange(value, name, index)}
            defaultValue={defaultValue}
            name={name}
            className={className}
            displayBorder={border}
            showImg
            errorField={errorField[name] ? name : ''}
          />
        )
      case 'text':
        return (
          <CustomText
            size='14px'
            color={AgentColorPallette.black}
          >
            {placeholder}
          </CustomText>
        )
      case 'autosuggest':
        return (
          <AutoSearchInput
            errorField={errorField[name] ? name : ''}
            data={data}
            name={name}
            loading={loading}
            placeholder={placeholder}
            id={`auto_suggest_${index}`}
            displayKey={displayKey}
            defaultValue={defaultValue}
            dataKey={dataKey}
            className={className}
            disabled={itemDisabled || disabled}
            onSelect={value => this.handleSelectAutoInput(name, value)}
          />
        )
      case 'dob':
        return (
          <DobInput
            errorField={errorField[name] ? name : ''}
            name={name}
            disabled={itemDisabled || disabled}
            onDobSelect={(data) => this.handleDobChange(data, 'dob')}
            defaultValue={defaultValue}
            displayBorder={border}
            minDate={minDate}
            maxDate={maxDate}
          />
        )
      case 'timepicker':
        return (
          <TimePickerOld
            name={name}
            onChange={({ name, value }) => this.handleTimeChange(name, value)}
            defaultValue={defaultValue}
            errorField={errorField[name] ? name : ''}
          />
        )
      case 'textarea':
        return (
          <Textarea
            key={index}
            errorField={errorField[name] ? name : ''}
            placeholder={placeholder}
            backgroundColor={backgroundColor}
            displayBorder={border}
            opacity={opacity}
            defaultValue={defaultValue}
            className={className}
            label={label}
            disabled={itemDisabled || disabled}
            name={name}
            onChange={e => this.handleInputChanges(e, name)}
          />
        )
      case 'autoSearchModal':
        return (
          <AutoSearchModal
            errorField={errorField[name] ? name : ''}
            data={data}
            name={name}
            loading={loading}
            disabled={itemDisabled || disabled}
            placeholder={placeholder}
            id={`search_modal_${index}`}
            displayKey={displayKey}
            defaultValue={defaultValue}
            value={this.state[name] || defaultValue}
            dataKey={dataKey}
            className={className}
            title={label}
            displayBorder={border}
            ref={ref || this.searchAutoModalRef}
            onSelect={(value, data) => this.handleSelectAutoInput(name, value, data)}
          />
        )
      default:
        return (
          <InnerInput
            key={index}
            errorField={errorField.name ? name : ''}
            // errorValue={errorValue}
            placeholder={placeholder}
            displayBorder={border}
            backgroundColor={backgroundColor}
            opacity={opacity}
            label={label}
            name={name}
            disabled={itemDisabled || disabled}
            defaultValue={defaultValue}
            className={className}
            onChange={(e) => {
              removeFromLocalStorageData(IS_SCROLL_START)
              this.setState({
                error: false,
                [name]: e.target.value,
                errorField: {
                  ...this.state.errorField,
                  [name]: ''
                }
              })
            }}
          />
        )
    }
  }

  getGuideContainer = (item) => {
    return (
      <Row align='center' onClick={() => this.handleOpenImgModal(item)}>
        <CustomText
          color={AgentColorPallette.darkerGold}
          weight={700}
        >
          {LANG_STRINGS.guide}
        </CustomText>
        &nbsp;&nbsp;
        {!item.hideGuideIcon && <img src={LIGHT_GOLD_BULB} alt='' />}
      </Row>
    )
  }

  render () {
    const { formData, backgroundColor, opacity, className, titleClassName } = this.props
    const { errorField } = this.state
    return (
      <FormWrapper backgroundColor={backgroundColor} opacity={opacity}>
        <form onSubmit={this.handleSubmit} className={className}>
          {
            formData.fields.map((item, index) => (
              <div
                className={item.itemContainerClass ? item.itemContainerClass : ''}
                key={index}
                id={`${item.name}_div`}
              >
                {
                  item.label &&
                    <div className='row-space-between'>
                      <TitleError
                        title={item.label}
                        required={!item.skipEmptyCheck}
                        titleClassName={titleClassName}
                      />
                      {/* <CustomText
                        color={AgentColorPallette.fontSecondary}
                        className={titleClassName}
                        top='4px'
                        bottom='4px'
                      >
                        {item.label}
                        {
                          !item.skipEmptyCheck && (
                            <RequiredStar>*</RequiredStar>
                          )
                        }
                      </CustomText> */}
                      <div>
                        {item.guide && this.getGuideContainer(item)}
                        {
                          errorField?.[item.name] && (
                            <CustomText size='12px' color={AgentColorPallette.errorDark} top='4px' bottom='4px'>
                              {errorField && errorField[item.name]}
                            </CustomText>
                          )
                        }
                      </div>
                    </div>
                }
                {
                  this.renderItem({
                    ...item,
                    index,
                    backgroundColor,
                    opacity
                  })
                }
                {
                  item.subTitle && (
                    <CustomText
                      color={AgentColorPallette.fontDarkerGrey}
                      size='11px'
                      bottom='4px'
                      className='inner-form-subtitle'
                    >
                      {item.subTitle}
                    </CustomText>
                  )
                }
              </div>
            ))
          }
          {
            formData.buttons && formData.buttons.map(
              (button, index) => this.renderButton({
                ...button,
                index
              })
            )
          }
        </form>
      </FormWrapper>
    )
  }
}

export default InnerForm
