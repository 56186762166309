import styled from 'styled-components'
// import { AgentColorPallette } from "../configs/colorPallete"

export const FormWrapper = styled.div`
  .plate-number-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plate-number-input {
    width: 64%;
    margin-bottom: 6px;
  }
  .plate-number-select {
    width: 24%;
    margin-bottom: 12px;
    background-position: 90% center;
  }
  .row-space-between {
    display: flex;
    justify-content: space-between;
  }
  .inner-form-subtitle {
    margin-top: -8px;
  }

  .customer-phone-input {
    padding: 16px;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);

    ::placeholder {
      color: ${props => props.theme.fontGrey};
      font-size: 12px;
      letter-spacing: normal;
    }
  }
`
