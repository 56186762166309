import { Component } from 'react'
import { PolicyStepBarWrapper } from './index.style'

class PolicyStepBar extends Component {
  render () {
    const { step } = this.props
    return (
      <PolicyStepBarWrapper>
        <ul className='progressbar'>
          <li className={step >= 1 ? 'active' : ''}>HITUNG PREMI</li>
          <li className={step >= 2 ? 'active' : ''}>PILIH PAKET</li>
          <li className={step >= 3 ? 'active' : ''}>ISI DATA</li>
          <li className={step >= 4 ? 'active' : ''}>RINCIAN TRANSAKSI</li>
          <li className={step >= 5 ? 'active' : ''}>SELESAI</li>
        </ul>
      </PolicyStepBarWrapper>
    )
  }
}

export default PolicyStepBar
