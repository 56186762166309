const actions = {
  GET_CUSTOMER_POLICY_REQUEST: 'GET_CUSTOMER_POLICY_REQUEST',
  GET_CUSTOMER_POLICY_SUCCESS: 'GET_CUSTOMER_POLICY_SUCCESS',
  GET_CUSTOMER_POLICY_FAILURE: 'GET_CUSTOMER_POLICY_FAILURE',

  GET_CUSTOMER_DRAFT_QUOTE_DETAIL_REQUEST: 'GET_CUSTOMER_DRAFT_QUOTE_DETAIL_REQUEST',
  GET_CUSTOMER_DRAFT_QUOTE_DETAIL_SUCCESS: 'GET_CUSTOMER_DRAFT_QUOTE_DETAIL_SUCCESS',
  GET_CUSTOMER_DRAFT_QUOTE_DETAIL_FAILURE: 'GET_CUSTOMER_DRAFT_QUOTE_DETAIL_FAILURE',

  UPDATE_CUSTOMER_DRAFT_QUOTE_REQUEST: 'UPDATE_CUSTOMER_DRAFT_QUOTE_REQUEST',
  UPDATE_CUSTOMER_DRAFT_QUOTE_SUCCESS: 'UPDATE_CUSTOMER_DRAFT_QUOTE_SUCCESS',
  UPDATE_CUSTOMER_DRAFT_QUOTE_FAILURE: 'UPDATE_CUSTOMER_DRAFT_QUOTE_FAILURE',

  COMPLETE_CUSTOMER_DRAFT_REQUEST: 'COMPLETE_CUSTOMER_DRAFT_REQUEST',
  COMPLETE_CUSTOMER_DRAFT_SUCCESS: 'COMPLETE_CUSTOMER_DRAFT_SUCCESS',
  COMPLETE_CUSTOMER_DRAFT_FAILURE: 'COMPLETE_CUSTOMER_DRAFT_FAILURE',

  CUSTOMER_POLICY_REQUEST: 'CUSTOMER_POLICY_REQUEST',
  CUSTOMER_POLICY_SUCCESS: 'CUSTOMER_POLICY_SUCCESS',
  CUSTOMER_POLICY_FAILURE: 'CUSTOMER_POLICY_FAILURE',

  UPDATE_CUSTOMER_POLICY_REQUEST: 'UPDATE_CUSTOMER_POLICY_REQUEST',
  UPDATE_CUSTOMER_POLICY_SUCCESS: 'UPDATE_CUSTOMER_POLICY_SUCCESS',
  UPDATE_CUSTOMER_POLICY_FAILURE: 'UPDATE_CUSTOMER_POLICY_FAILURE',

  SUBMIT_CUSTOMER_POLICY_REQUEST: 'SUBMIT_CUSTOMER_POLICY_REQUEST',
  SUBMIT_CUSTOMER_POLICY_SUCCESS: 'SUBMIT_CUSTOMER_POLICY_SUCCESS',
  SUBMIT_CUSTOMER_POLICY_FAILURE: 'SUBMIT_CUSTOMER_POLICY_FAILURE',

  CUSTOMER_PAYING_REQUEST: 'CUSTOMER_PAYING_REQUEST',
  CUSTOMER_PAYING_SUCCESS: 'CUSTOMER_PAYING_SUCCESS',
  CUSTOMER_PAYING_FAILURE: 'CUSTOMER_PAYING_FAILURE',

  CHEKC_BLACK_LIST_REQUEST: 'CHEKC_BLACK_LIST_REQUEST',
  CHEKC_BLACK_LIST_SUCCESS: 'CHEKC_BLACK_LIST_SUCCESS',
  CHEKC_BLACK_LIST_FAILURE: 'CHEKC_BLACK_LIST_FAILURE',

  VALIDATE_POLICY_DATA_REQUEST: 'VALIDATE_POLICY_DATA_REQUEST',
  VALIDATE_POLICY_DATA_SUCCESS: 'VALIDATE_POLICY_DATA_SUCCESS',
  VALIDATE_POLICY_DATA_FAILURE: 'VALIDATE_POLICY_DATA_FAILURE',

  RESET_CUSTOMER_STORE: 'RESET_CUSTOMER_STORE',

  getCustomerPolicy: (payload) => ({
    type: actions.GET_CUSTOMER_POLICY_REQUEST,
    payload
  }),
  createCustomerPolicy: (payload) => ({
    type: actions.CUSTOMER_POLICY_REQUEST,
    payload
  }),
  updateCustomerDraftQuoteReq: (payload) => ({
    type: actions.UPDATE_CUSTOMER_DRAFT_QUOTE_REQUEST,
    payload
  }),
  completeCustomerDraftQuoteReq: (payload) => ({
    type: actions.COMPLETE_CUSTOMER_DRAFT_REQUEST,
    payload
  }),
  getCustomerDraftDetailsReq: (payload) => ({
    type: actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_REQUEST,
    payload
  }),
  updateCustomerPolicyData: (payload) => ({
    type: actions.UPDATE_CUSTOMER_POLICY_REQUEST,
    payload
  }),
  submitCustomerPolicy: (payload) => ({
    type: actions.SUBMIT_CUSTOMER_POLICY_REQUEST,
    payload
  }),
  customerPayingRequest: payload => ({
    type: actions.CUSTOMER_PAYING_REQUEST,
    payload
  }),
  checkBlackListCustomer: payload => ({
    type: actions.CHEKC_BLACK_LIST_REQUEST,
    payload
  }),
  validatePolicyData: payload => ({
    type: actions.VALIDATE_POLICY_DATA_REQUEST,
    payload
  }),
  resetCustomerStore: (payload) => ({
    type: actions.RESET_CUSTOMER_STORE,
    payload
  })
}

export default actions
