const motorActions = {
  GET_MOTOR_BRAND_REQUEST: 'GET_MOTOR_BRAND_REQUEST',
  GET_MOTOR_BRAND_SUCCESS: 'GET_MOTOR_BRAND_SUCCESS',
  GET_MOTOR_BRAND_FAILURE: 'GET_MOTOR_BRAND_FAILURE',

  GET_MOTOR_MODEL_REQUEST: 'GET_MOTOR_MODEL_REQUEST',
  GET_MOTOR_MODEL_SUCCESS: 'GET_MOTOR_MODEL_SUCCESS',
  GET_MOTOR_MODEL_FAILURE: 'GET_MOTOR_MODEL_FAILURE',
  RESET_MOTOR_STORE: 'RESET_MOTOR_STORE',

  getMotorBrandRequest: () => ({
    type: motorActions.GET_MOTOR_BRAND_REQUEST
  }),
  getMotorModelRequest: payload => ({
    type: motorActions.GET_MOTOR_MODEL_REQUEST,
    payload
  }),
  resetMotorStore: (payload) => ({
    type: motorActions.RESET_MOTOR_STORE,
    payload
  })
}

export default motorActions
