import { ConfigUrl } from '../../../configs/url'
import { CUSTOMER_FLOW } from '../../../constants/cookies'
import { getFromLocalStorageData } from '../../../helpers/localstorage'
import { getHeaderUploadFile } from '../../../helpers/utility'

export const UPLOAD_ACTIONS = {
  GET_UPLOAD_LINK_REQUEST: 'GET_UPLOAD_LINK_REQUEST',
  GET_UPLOAD_LINK: 'GET_UPLOAD_LINK',

  RESET_UPLOAD_FILE_STATE: 'RESET_UPLOAD_FILE_STATE'
}

export const uploadFile = (payload) => ({
  type: UPLOAD_ACTIONS.GET_UPLOAD_LINK_REQUEST,
  payload: {
    ...payload,
    headers: getHeaderUploadFile(),
    method: 'POST',
    url: getFromLocalStorageData(CUSTOMER_FLOW) === 'true' ? ConfigUrl.customerUploadUrl : ConfigUrl.getUploadLink
  }
})

export const resetUploadFileState = (payload) => ({
  type: UPLOAD_ACTIONS.RESET_UPLOAD_FILE_STATE,
  payload: {
    ...payload
  }
})
