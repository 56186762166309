import { createBrowserHistory } from 'history'
import PublicRoutes from './router'
import DashAppHolder from './dashApp.style'

export const history = createBrowserHistory()

const DashApp = () => (
  <DashAppHolder>
    <PublicRoutes history={history} />
  </DashAppHolder>
)

export default DashApp
