import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CustomText } from '../../..'
import { AgentColorPallette } from '../../../../configs/colorPallete'
import { getAgentName, getGreetingMsg } from '../../../../helpers/utility'
import { CALL_HELP, NOTIFICATION_NEW } from '../../../../images'
import { LANG_STRINGS } from '../../../../lang'
import { useShowAgentPIC } from '../../../../state/globalState/hooks/useGlobalContext'
import { HeaderGreetingStyle } from './index.style'

function HeaderGreetings ({ user, hideHelp, hideNotification }) {
  const history = useHistory()
  const [, setShowAgentPIC] = useShowAgentPIC()

  const handleTogglePIC = useCallback(() => {
    setShowAgentPIC()
  }, [setShowAgentPIC])

  return (
    <HeaderGreetingStyle>
      <div className='header-inner'>
        <CustomText
          color={AgentColorPallette.black}
          size='1em'
          weight={600}
          bottom='0'
        >
          {'Hai'} {getAgentName(user.name)}
        </CustomText>
        {
          !hideHelp && (
            <div
              className='icon-align'
              onClick={handleTogglePIC}
              data-gtm-element-id='quick-help'
              data-gtm-entry='Dashboard'
            >
              <img
                data-gtm-element-id='quick-help'
                data-gtm-entry='Dashboard'
                src={CALL_HELP} alt='call help'
              />
            </div>
          )
        }
        {
          !hideNotification && (
            <div
              className='icon-align'
              onClick={() => history.push('/notifications')}
              data-gtm-element-id='notifications'
              data-gtm-entry='Dashboard Bell'
            >
              <img
                data-gtm-element-id='notifications'
                data-gtm-entry='Dashboard Bell'
                src={NOTIFICATION_NEW} alt='notifications help'
              />
              {
                (user.red_dot_visibility) && (
                  <div className='red-dot' />
                )
              }
            </div>
          )
        }
      </div>
    </HeaderGreetingStyle>
  )
}

export default HeaderGreetings
