import * as Sentry from '@sentry/react'
import ErrorBoundary from '../components/ErrorBoundary'

import { ErrorContextProvider, LoaderContextProvider } from '@pasarpolis/common-react-hooks'
import { GlobalContextProvider } from '../contextProvider/GlobalContext'
import ServiceContainer from './ServiceContainer'

const PublicRoutes = ({ history }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => {
        return <ErrorBoundary resetError={resetError} />
      }}
    >
      <GlobalContextProvider>
        <LoaderContextProvider>
          <ErrorContextProvider>
            <ServiceContainer history={history} />
          </ErrorContextProvider>
        </LoaderContextProvider>
      </GlobalContextProvider>
    </Sentry.ErrorBoundary>
  )
}

export default PublicRoutes
