import { Component } from 'react'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import PropTypes from 'prop-types'
class Benefit extends Component {
  static propTypes = {
    benefitData: PropTypes.array
  }

  render () {
    const { benefitData } = this.props
    return Array.isArray(benefitData) && benefitData.map((item, index) =>
      <div key={`benefit_${index}`}>
        <CustomText color={AgentColorPallette.regular} size='13px' weight={800}>
          {item.description}
        </CustomText>
      </div>
    )
  }
}

export default Benefit
