import { takeEvery, call, put, all, fork } from 'redux-saga/effects'
import actions from './actions'
import authGet from '../../methods/authGet'
import authPost from '../../methods/authPost'
import { ConfigUrl } from '../../configs/url'
import { getErrorMessage } from '../../helpers/utility'
import authDelete from '../../methods/authDelete'
import authPut from '../../methods/authPut'

export function * getLeadListSaga () {
  // this function return the list of lead
  yield takeEvery(actions.GET_LEAD_LIST_REQUEST, function * () {
    try {
      const res = yield call(authGet, { url: ConfigUrl.leadListApi })
      if (res) {
        yield put({
          type: actions.GET_LEAD_LIST_SUCCESS,
          leadListData: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.GET_LEAD_LIST_FAILURE,
          leadListData: [],
          message: getErrorMessage(res.status)
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_LEAD_LIST_FAILURE,
        leadListData: [],
        message: error.data ? getErrorMessage(error.status, error.data.errorCode) : 'Lead not exit'
      })
    }
  })
}

export function * updateLeadSaga () {
  yield takeEvery(actions.UPDATE_LEAD_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPut, { body: payload, url: ConfigUrl.updateLead(payload.leadId) })
      if (res) {
        yield put({
          type: actions.UPDATE_LEAD_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.UPDATE_LEAD_FAILURE,
          data: [],
          message: getErrorMessage(res.status, res.data.errorCode)
        })
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_LEAD_FAILURE,
        data: [],
        message: error.data ? getErrorMessage(error.status, error.data.errorCode) : 'Lead not exit'
      })
    }
  })
}

export function * removeLeadSaga () {
  yield takeEvery(actions.REMOVE_LEAD_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authDelete, { url: ConfigUrl.updateLead(payload.leadId) })
      if (res) {
        yield put({
          type: actions.REMOVE_LEAD_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.REMOVE_LEAD_FAILURE,
          data: [],
          message: getErrorMessage(res.status, res.data.errorCode)
        })
      }
    } catch (error) {
      yield put({
        type: actions.REMOVE_LEAD_FAILURE,
        data: [],
        message: getErrorMessage(error.status, error.data.errorCode)
      })
    }
  })
}

export function * openLeadSaga () {
  yield takeEvery(actions.OPEN_LEAD_REQUEST, function * ({ payload }) {
    try {
      const res = yield call(authPost, { url: ConfigUrl.openLeadApi(payload.leadId) })
      if (res) {
        yield put({
          type: actions.OPEN_LEAD_SUCCESS,
          data: res.data,
          message: 'success'
        })
      } else {
        yield put({
          type: actions.OPEN_LEAD_FAILURE,
          data: [],
          message: getErrorMessage(res.status, res.data.errorCode)
        })
      }
    } catch (error) {
      yield put({
        type: actions.OPEN_LEAD_FAILURE,
        data: [],
        message: getErrorMessage(error.status, error.data.errorCode)
      })
    }
  })
}

export default function * rootSage () {
  yield all([
    fork(getLeadListSaga),
    fork(updateLeadSaga),
    fork(removeLeadSaga),
    fork(openLeadSaga)
  ])
}
