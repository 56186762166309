import styled from 'styled-components'

export const LoaderWrapper = styled.div`
  background-color: ${props => props.bgColor};
  .loader-image-container {
    position: fixed;
    background-color: ${props => props.bgColor || 'rgba(0,0,0,0.5)'};
    min-height: 100%;
    width: 100%;
    max-width: 450px;
    z-index: 2;
    top: 0;
    bottom: 0;
  }

  .loader-height {
    height: ${props => props.height || '100vh'};
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .loader-image {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen 
  and (min-device-width: 450px) 
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2)
  {
    .loader-image-container {
      max-width: 100%;
    }
  }
`

export const LoaderSvgStyle = styled.div`
  width: ${props => props.size};
  display: inline-block;

  #eGhaJjprqD13_tr {
    animation: eGhaJjprqD13_tr__tr 3000ms linear infinite normal forwards
  }
  @keyframes eGhaJjprqD13_tr__tr {
    0% {transform: translate(12px,12px) rotate(0deg)}
    100% {transform: translate(12px,12px) rotate(360.000002deg)}
  }
`
