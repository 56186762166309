import { createContext, useReducer, useContext } from 'react'
import reducer, { INITIAL_STATE } from '../../state/globalState/reducers'

export const GlobalContext = createContext()

export const GlobalContextProvider = ({ children, globalState = {}, resetState = () => {} }) => {
  return (
    <GlobalContext.Provider value={useReducer(reducer, INITIAL_STATE)}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContextValue = () => useContext(GlobalContext)
