import { useServiceRequest } from '@pasarpolis/common-react-hooks'
import { uploadKtpNpwpDoc } from '../../../../containers/Private/VerifyKTPNPWP/state/actions'
import { useUploadFileContextValue } from '../../context'
import { uploadFile } from '../actions'

export const useUploadFile = () => {
  const [uploadFileState, dispatch] = useUploadFileContextValue()

  const [callApi] = useServiceRequest(dispatch)
  const request = async (payload = {}) => await callApi(uploadFile(payload))

  return [uploadFileState, request]
}

export const useUploadKTPNPWPDoc = () => {
  const [uploadFileState, dispatch] = useUploadFileContextValue()
  const [callApi] = useServiceRequest(dispatch)
  const request = async (payload = {}) => await callApi(uploadKtpNpwpDoc(payload))

  return [uploadFileState, request]
}
