import ReactDOM from 'react-dom'
import './index.css'
import './commonStyle/font.css'
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css'
import App from './dashApp'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.REACT_APP_SENTRY_DSN) {
  // only for production env
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing()
    ],
    tracesSampleRate: 1.0
  })
}

// app root check
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
