import axios from 'axios'
import { queryBuilder, urlMapper } from '../helpers/utility'

const postFormData = ({ url = '', query = {}, body = {}, params = {}, headers = {} }) => {
  const formData = new FormData()
  formData.append('file', body.file)
  const queryString = queryBuilder(query)
  const mappedUrl = urlMapper(url, params)
  const combinedUrl = `${mappedUrl}${queryString}`
  return axios
    .post(combinedUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      }
    })
    .catch(error => {
      if (error.response) {
        throw error.response
      } else {
        throw error
      }
    })
    .then(response => {
      return response
    })
}

export default postFormData
