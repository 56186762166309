import styled from "styled-components";
import { AgentColorPallette } from "../../configs/colorPallete";

export const ModalWrapper = styled.div`
  .modal-header {
    background-color: ${AgentColorPallette.primary};
    padding: 12px;
    width: auto;
    max-width: 400px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    text-align: center;
    font-weight: 700;
  }
  .modal-content {
    padding: 18px;
  }
`