import styled from 'styled-components'

export const HeaderGreetingStyle = styled.div`
  position: fixed;
  background-color: ${props => props.theme.primary};  
  z-index: 3;
  width: 100%;
  max-width: 450px;

  .header-inner {
    padding: 16px;
    display: grid;
    grid-template-columns: 6fr 1fr 1fr;
    align-items: center;
  }

  .icon-align {
    text-align: end;
    position: relative;
    cursor: pointer;
  }

  .red-dot {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.vibrantRed};
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) { 
    max-width: 100%;
    margin: none;
  }

`
