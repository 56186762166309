import { useState, useCallback, useRef } from 'react'
import { Row } from '../../commonStyle/index.style.js'
import { SEARCH } from '../../images/index.js'
import { LANG_STRINGS } from '../../lang/index.js'
import { InnerInput } from '../index.js'
import { SearchBarStyle } from './index.style.js'
import { CLOSE_BLACK_ICON } from '../../images/imageUrl'

export default function SearchBar ({ onSearch, handleShowSearchDesc, onSearchClick, searchPlaceHolder }) {
  const [showBar, setShowBar] = useState(false)
  const searchRef = useRef()
  const timerRef = useRef()
  const inputRef = useRef(null)

  const handleInputChange = useCallback((e) => {
    e.preventDefault()
    onSearch(searchRef.current)
  }, [onSearch])

  const handleShowBar = useCallback((scenario) => {
    if (handleShowSearchDesc) {
      handleShowSearchDesc(scenario)
    }
    setShowBar(scenario)
    onSearchClick && onSearchClick()
    if (!scenario) {
      inputRef.current.value = ''
      onSearch('')
    }
  }, [handleShowSearchDesc, onSearch, onSearchClick])

  const handleSearchInput = useCallback((e) => {
    searchRef.current = e.target.value
    // debounce functionality to toggle the onSearch call
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      onSearch(searchRef.current)
    }, 200)
  }, [onSearch])

  return (
    <SearchBarStyle>
      {
        showBar && (
          <form className='search-input-container' onSubmit={handleInputChange}>
            <Row align='center'>
              <div className='input-search-img-container'>
                <img src={SEARCH} alt='' className='input-search-img' />
              </div>
              <InnerInput
                name='search'
                className='search-input'
                placeholder={`${searchPlaceHolder}...` || `${LANG_STRINGS.search}...`}
                onChange={handleSearchInput}
                ref={inputRef}
              />
            </Row>
          </form>
        )
      }
      <div className='icon-container'>
        {
          showBar
            ? (
              <div className='cross-icon' onClick={() => handleShowBar(false)}>
                <img src={CLOSE_BLACK_ICON} alt='' className='img-width' />
              </div>
            )
            : <img className='search-icon' onClick={() => handleShowBar(true)} src={SEARCH} alt='' />
        }
      </div>
    </SearchBarStyle>
  )
}
