import { Component } from 'react'
import moment from 'moment'
import { DetailContentWrapper, PaylaterBadge } from './index.style'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import { formatMoney } from '../../helpers/utility'
import { DisplayFlexCCC, Row } from '../../commonStyle/index.style'
import { LANG_STRINGS } from '../../lang'
import BranchCard from '../../containers/Private/Policy/components/BranchCard'
import { isEmpty } from 'lodash'
import isObject from 'lodash/isObject'

class MiniContent extends Component {
  renderRow = (
    title, value, paymentStatus = { titleColor: '', color: '' },
    bgColor, className = 'content-row'
  ) => {
    const { dataGtmElementId, applicationNumber } = this.props
    return (
      <Row
        justify='space-between'
        bgColor={bgColor}
        className={className}
        data-gtm-element-id={dataGtmElementId}
        data-package-code={applicationNumber}
      >
        <CustomText
          color={paymentStatus.titleColor || AgentColorPallette.fontSemiGrey}
          weight={700}
          data-gtm-element-id={dataGtmElementId}
          data-package-code={applicationNumber}
        >
          {title}
        </CustomText>
        <CustomText
          color={paymentStatus.color || AgentColorPallette.black}
          weight={700}
          data-gtm-element-id={dataGtmElementId}
          data-package-code={applicationNumber}
        >
          {value}
        </CustomText>
      </Row>
    )
  }

  render () {
    const {
      imageUrl, applicationNumber, onClick, premium, commission, packageName,
      points, heading, color, customer_name: customerName, created_at: createdAt, policyStatusExpired = '',
      createdBy, paymentStatus, policyStatus, usePayLater, id, hideComission, hidePoints,
      policyExpiryDate, dataGtmElementId, providerBranch
    } = this.props

    return (
      <DetailContentWrapper
        color={color}
        onClick={onClick}
        id={id}
        data-gtm-element-id={dataGtmElementId}
        data-package-code={applicationNumber}
      >
        <Row
          align='stretch'
          className='policy-title-section'
          data-gtm-element-id={dataGtmElementId}
          data-package-code={applicationNumber}
        >
          <DisplayFlexCCC
            className='provider-logo'
            data-gtm-element-id={dataGtmElementId}
            data-package-code={applicationNumber}
          >
            <img
              src={imageUrl}
              width={50} alt='logo'
              className='logo-image'
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            />
          </DisplayFlexCCC>
          <div
            data-gtm-element-id={dataGtmElementId}
            data-package-code={applicationNumber}
          >
            <CustomText
              color={AgentColorPallette.black}
              size='12px'
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            >
              {heading}
            </CustomText>
            <CustomText
              color={AgentColorPallette.darkGold}
              size='14px'
              weight='800'
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            >
              {packageName}
            </CustomText>
            <CustomText
              color={AgentColorPallette.black}
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            >
              {`ID Transaksi : ${applicationNumber}`}
            </CustomText>
          </div>
        </Row>
        {
          usePayLater && (
            <PaylaterBadge>
              {LANG_STRINGS.paylater}
            </PaylaterBadge>
          )
        }
        {
          providerBranch && !isEmpty(providerBranch) && isObject(providerBranch) &&
            <>
              <BranchCard
                branch={providerBranch}
              />
              <hr />
            </>
        }

        <Row
          className='content-row'
          align='center'
          justify='space-between'
          data-gtm-element-id={dataGtmElementId}
          data-package-code={applicationNumber}
        >
          <div>
            <CustomText
              color={AgentColorPallette.fontSemiGrey}
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            >
              {LANG_STRINGS.premiumPrice}
            </CustomText>
            <CustomText
              color={AgentColorPallette.black}
              weight={700}
              data-gtm-element-id={dataGtmElementId}
              data-package-code={applicationNumber}
            >
              {formatMoney(premium, 0)}
            </CustomText>
          </div>
          {
            !hideComission && (
              <div>
                <CustomText
                  color={AgentColorPallette.fontSemiGrey}
                  data-gtm-element-id={dataGtmElementId}
                  data-package-code={applicationNumber}
                >
                  {LANG_STRINGS.commissionAgent}
                </CustomText>
                <CustomText
                  color={AgentColorPallette.black}
                  weight={700}
                  textAlign='center'
                  data-gtm-element-id={dataGtmElementId}
                  data-package-code={applicationNumber}
                >
                  {formatMoney(commission, 0)}
                </CustomText>
              </div>
            )
          }
          {
            !hidePoints && (
              <div>
                <CustomText
                  color={AgentColorPallette.fontSemiGrey}
                  data-gtm-element-id={dataGtmElementId}
                  data-package-code={applicationNumber}
                >
                  {'Komisi Poin'}
                </CustomText>
                <CustomText
                  color={AgentColorPallette.black}
                  weight={700}
                  textAlign='right'
                  data-gtm-element-id={dataGtmElementId}
                  data-package-code={applicationNumber}
                >
                  {formatMoney((points || 0), 3, '') + ' Pts'}
                </CustomText>
              </div>
            )
          }
        </Row>
        <hr />
        {this.renderRow(LANG_STRINGS.paymentStatus, paymentStatus.text, paymentStatus)}
        <hr />
        {this.renderRow(LANG_STRINGS.policyStatus, policyStatus.text, policyStatus)}
        <hr />
        {
          !!customerName && (
            <>
              {this.renderRow(LANG_STRINGS.insuredName, customerName)}
              <hr />
            </>
          )
        }
        {
          this.renderRow(
            LANG_STRINGS.transactionDate,
            moment(createdAt).format('DD MMMM YYYY'),
            {},
            '',
            createdBy ? 'content-row' : 'row-btm-pad'
          )
        }
        {
          createdBy && (
            <>
              <hr />
              {
                this.renderRow(
                  LANG_STRINGS.madeBy,
                  createdBy,
                  {},
                  '',
                  !policyStatusExpired && 'row-btm-pad'
                )
              }
            </>
          )
        }
        {
          policyStatusExpired && this.renderRow(
            LANG_STRINGS.madeBy,
            moment(policyExpiryDate, 'YYYY-MM-DD').format('DD MMMM YYYY'),
            {
              titleColor: AgentColorPallette.white,
              color: AgentColorPallette.white
            },
            policyStatusExpired === 'expired' ? AgentColorPallette.maroonRed : AgentColorPallette.warning,
            'policy-status-strip'
          )
        }
      </DetailContentWrapper>
    )
  }
}

export default MiniContent
