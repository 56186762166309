import actions from './actions'

const initState = {
  customerPolicyData: {},
  customerPolicyDataStatus: '',
  updateCustomerPolicyData: {},
  updateCustomerPolicyStatus: '',
  submitCustomerPolicyData: {},
  creatCustomerPolicy: {},
  creatCustomerPolicyStatus: '',
  submitCustomerPolicyStatus: ''
}

export default function customerReducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_CUSTOMER_POLICY_SUCCESS:
    case actions.GET_CUSTOMER_POLICY_FAILURE:
      return {
        ...state,
        customerPolicyData: action.data,
        customerPolicyDataStatus: action.message
      }
    case actions.CUSTOMER_POLICY_SUCCESS:
    case actions.CUSTOMER_POLICY_FAILURE:
      return {
        ...state,
        creatCustomerPolicy: action.data,
        creatCustomerPolicyStatus: action.message
      }
    case actions.UPDATE_CUSTOMER_POLICY_SUCCESS:
    case actions.UPDATE_CUSTOMER_POLICY_FAILURE:
      return {
        ...state,
        updateCustomerPolicyData: action.data,
        updateCustomerPolicyStatus: action.message
      }
    case actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_SUCCESS:
    case actions.GET_CUSTOMER_DRAFT_QUOTE_DETAIL_FAILURE:
      return {
        ...state,
        getCustomerDraftQuoteDetailData: action.data,
        getCustomerDraftQuoteDetailStatus: action.message
      }
    case actions.UPDATE_CUSTOMER_DRAFT_QUOTE_SUCCESS:
    case actions.UPDATE_CUSTOMER_DRAFT_QUOTE_FAILURE:
      return {
        ...state,
        updateCustomerDraftQuoteData: action.data,
        updateCustomerDraftQuoteStatus: action.message
      }
    case actions.COMPLETE_CUSTOMER_DRAFT_SUCCESS:
    case actions.COMPLETE_CUSTOMER_DRAFT_FAILURE:
      return {
        ...state,
        completeCustomerDraftData: action.data,
        completeCustomerDraftStatus: action.message
      }
    case actions.SUBMIT_CUSTOMER_POLICY_SUCCESS:
    case actions.SUBMIT_CUSTOMER_POLICY_FAILURE:
      return {
        ...state,
        submitCustomerPolicyData: action.data,
        submitCustomerPolicyStatus: action.message
      }
    case actions.CUSTOMER_PAYING_SUCCESS:
    case actions.CUSTOMER_PAYING_FAILURE:
      return {
        ...state,
        customerPayingData: action.data,
        customerPayingMsg: action.message
      }
    case actions.CHEKC_BLACK_LIST_SUCCESS:
    case actions.CHEKC_BLACK_LIST_FAILURE:
      return {
        ...state,
        checkBlackListData: action.data,
        checkBlackListMsg: action.message
      }
    case actions.VALIDATE_POLICY_DATA_SUCCESS:
    case actions.VALIDATE_POLICY_DATA_FAILURE:
      return {
        ...state,
        validateCustPolicyData: action.data,
        validateCustPolicyMsg: action.message
      }
    case actions.RESET_CUSTOMER_STORE: {
      const { payload } = action
      const modifyStore = {}
      payload.forEach(stateName => {
        modifyStore[stateName] = ''
      })
      return {
        ...state,
        ...modifyStore
      }
    }
    case actions.PAY_LATER_SUCCESS:
    case actions.PAY_LATER_FAILURE:
      return {
        ...state,
        payLaterMsg: action.message
      }
    default:
      return state
  }
}
