import styled from 'styled-components'

export const ModifyTermsConditionsStyle = styled.div`
  .modify-terms {
    padding-left: 20px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .modify-item {
    font-size: 12px;
    margin-bottom: 10px;
    color: ${props => props.theme.fontSecondary};
  }
  
  .modify-btm-list {
    margin-top: 0;
  }
`
