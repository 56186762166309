import { Component } from 'react'
import { ButtonWrapper } from './index.style'
import PropTypes from 'prop-types'

class Button extends Component {
  static propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    handleClick: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    borderRadius: PropTypes.string
  }

  render () {
    const {
      color, width, backgroundColor, handleClick, id,
      disabled, children, borderRadius, ...props
    } = this.props
    return (
      <ButtonWrapper
        textColor={color}
        id={id}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        onClick={handleClick}
        disabled={disabled}
        width={width}
        {...props}
      >
        {children}
      </ButtonWrapper>
    )
  }
}

export default Button
