import { AgentColorPallette } from '../configs/colorPallete'
import {
  ADDITIONAL_INFORMATION_REQUIRED, DOCUMENT_INCOMPLETE,
  INFORMATION_IN_VERIFICATION, POLICY_ISSUED, QUOTE_ACCEPTED,
  QUOTE_EXPIRED, QUOTE_IN_PROGRESS, QUOTE_ISSUED, QUOTE_REJECTED,
  SUBMITTED, POLICY_COMPLETED, COMPLETED,
  CLOSING_SLIP_GENERATED
} from '../constants/pkgCode'
import { LANG_STRINGS } from '../lang'

export const POLICY_TYPE = {
  'new-purchase': LANG_STRINGS.newPolicy,
  'renew-purchase': LANG_STRINGS.renewPurchase,
  'renew-from-other': LANG_STRINGS.renewFromOther
}

export function getPaymentStatus (status) {
  switch (status) {
    case 'PAID':
      return { text: 'Pembayaran Berhasil', color: AgentColorPallette.blue }
    case 'POLICY_CREATED':
    case 'COMPLETED':
      return { text: 'Sudah Dibayar', color: AgentColorPallette.green }
    case 'PAYMENT_FAILED':
    case 'EXPIRED':
    case 'FAILED':
      return { text: 'Pembayaran Gagal', color: AgentColorPallette.errorDark }
    case 'PROOF_APPROVED':
    case 'VERIFICATION_IN_PROGRESS':
      return { text: 'Dalam Proses Verifikasi', color: AgentColorPallette.orange }
    case 'PROOF_SUBMITTED':
    case 'PROOF_UNDERPAYMENT':
      return { text: 'Bukti Pembayaran Diserahkan', color: AgentColorPallette.orange }
    case 'PROOF_VERIFICATION_FAILED':
      return { text: 'Verifikasi Pembayaran Gagal', color: AgentColorPallette.orange }
    case 'MITRA_CONFIRMATION_PENDING':
      return { text: 'Menunggu konfirmasi Mitra', color: AgentColorPallette.orange }
    case 'CANCELLED':
      return { text: LANG_STRINGS.canceled, color: AgentColorPallette.orange }
    case 'INSTALLMENTS_IN_PROGRESS':
      return { text: 'Cicilan sedang berjalan', color: AgentColorPallette.blue }
    default:
      return { text: 'Menunggu Pembayaran', color: AgentColorPallette.orange }
  }
}

export function getPolicyStatus (status) {
  switch (status) {
    case 'ACTIVE':
    case 'POLICY_CREATED':
      return { text: LANG_STRINGS.active, color: AgentColorPallette.green }
    case 'APPLICATION_COMPLETED':
      return { text: LANG_STRINGS.notActiveState, color: AgentColorPallette.orange }
    case 'PENDING':
    case 'PROVISIONAL_ISSUED':
      return { text: LANG_STRINGS.isVerified, color: AgentColorPallette.orange }
    case 'PROVISIONAL_FAILED':
      return { text: 'Tidak Aktif', color: AgentColorPallette.errorDark }
    case 'PAYMENT_FAILED':
      return { text: 'Pembayaran Gagal', color: AgentColorPallette.errorDark }
    case 'REJECTED':
    case 'APPLICATION_CANCELLED':
    case 'CANCELLED':
      return { text: LANG_STRINGS.cancel, color: AgentColorPallette.errorDark }
    case 'EXPIRED':
      return { text: LANG_STRINGS.expired, color: AgentColorPallette.errorDark }
    default:
      return { text: LANG_STRINGS.notActiveState, color: AgentColorPallette.orange }
  }
}

export const getPolicyStateChip = (status) => {
  switch (status) {
    case 'ACTIVE':
    case 'POLICY_CREATED':
      return { text: LANG_STRINGS.active, color: AgentColorPallette.green }
    case 'APPLICATION_COMPLETED':
      return { text: LANG_STRINGS.notActiveState, color: AgentColorPallette.orange }
    case 'APPLICATION_CANCELLED':
    case 'PROVISIONAL_FAILED':
      return { text: 'Tidak Aktif', color: AgentColorPallette.errorDark }
    case 'PAYMENT_FAILED':
      return { text: 'Pembayaran Gagal', color: AgentColorPallette.errorDark }
    case 'REJECTED':
      return { text: LANG_STRINGS.rejected, color: AgentColorPallette.errorDark }
    case 'CANCELLED':
      return { text: LANG_STRINGS.canceled, color: AgentColorPallette.errorDark }
    default:
      return { text: LANG_STRINGS.notActiveState, color: AgentColorPallette.orange }
  }
}

export const getPaymentStateChip = (status) => {
  switch (status) {
    case 'PAID':
      return { text: 'Pembayaran Berhasil', color: AgentColorPallette.Gray90 }
    case 'POLICY_CREATED':
    case 'COMPLETED':
      return { text: 'Sudah Dibayar', color: AgentColorPallette.Gray90, textColor: AgentColorPallette.black }
    case 'PAYMENT_FAILED':
    case 'EXPIRED':
    case 'FAILED':
      return { text: 'Pembayaran Gagal', color: AgentColorPallette.maroonRed }
    case 'PROOF_APPROVED':
    case 'VERIFICATION_IN_PROGRESS':
      return { text: 'Dalam Proses Verifikasi', color: AgentColorPallette.orange }
    case 'PROOF_SUBMITTED':
      return { text: 'Bukti Bayar Diserahkan', color: AgentColorPallette.Gray90 }
    case 'PROOF_UNDERPAYMENT':
      return { text: 'Bukti Pembayaran Diserahkan', color: AgentColorPallette.orange }
    case 'PROOF_VERIFICATION_FAILED':
      return { text: 'Verifikasi Pembayaran Gagal', color: AgentColorPallette.orange }
    case 'MITRA_CONFIRMATION_PENDING':
      return { text: 'Menunggu konfirmasi Mitra', color: AgentColorPallette.orange }
    case 'CANCELLED':
      return { text: LANG_STRINGS.canceled, color: AgentColorPallette.orange }
    case 'INSTALLMENTS_IN_PROGRESS':
      return { text: 'Cicilan sedang berjalan', color: AgentColorPallette.blue }
    default:
      return { text: 'Menunggu Pembayaran', color: AgentColorPallette.orangeMangoTango }
  }
}

export const QUOTE_STATUS_LANG = {
  SUBMITTED: 'Permohonan Diajukan',
  DOCUMENT_INCOMPLETE: 'Dokumen Belum Lengkap',
  INFORMATION_IN_VERIFICATION: 'Informasi Sedang Diverifikasi',
  QUOTE_IN_PROGRESS: 'Penawaran Dalam Proses',
  QUOTE_ISSUED: 'Penawaran Diterbitkan',
  CLOSING_SLIP_GENERATED: 'Penawaran Diterbitkan',
  ADDITIONAL_INFORMATION_REQUIRED: 'Informasi Tambahan Diperlukan',
  QUOTE_ACCEPTED: 'Penawaran Diterima',
  POLICY_ISSUANCE_PENDING: 'Penerbitan Polis Sedang Diproses',
  POLICY_ISSUED: 'Polis Diterbitkan',
  POLICY_COMPLETED: 'Polis Berhasil',
  QUOTE_REJECTED: 'Polis Ditolak',
  QUOTE_EXPIRED: 'Kadaluarsa',
  INITIATED: 'Penawaran Dalam Proses',
  INFORMATION_COMPLETED: 'Informasi Lengkap',
  POLICY_EXPIRED: 'Kadaluarsa'
}

export function getOfflineQuoteStatus (status) {
  const text = QUOTE_STATUS_LANG[status]
  switch (status) {
    case SUBMITTED:
    case INFORMATION_IN_VERIFICATION:
    case QUOTE_IN_PROGRESS:
    case CLOSING_SLIP_GENERATED:
      return {
        text,
        color: AgentColorPallette.orange
      }
    case DOCUMENT_INCOMPLETE:
    case ADDITIONAL_INFORMATION_REQUIRED:
    case QUOTE_REJECTED:
    case QUOTE_EXPIRED:
      return {
        text,
        color: AgentColorPallette.errorDark
      }
    case QUOTE_ACCEPTED:
    case QUOTE_ISSUED:
    case POLICY_ISSUED:
      return { text, color: AgentColorPallette.green }
    case POLICY_COMPLETED:
    case COMPLETED:
      return { text, color: AgentColorPallette.darkBlue }
    default:
      return { text, color: AgentColorPallette.orange }
  }
}

export function getVehicleType (type) {
  switch (type) {
    case 'non-bus-non-truck':
      return 'Mobil Penumpang'
    case 'bus':
      return 'Bus'
    default:
      return 'Truk Pickup'
  }
}

export function checkApplicationStep (current, expected) {
  if (current === expected) {
    return true
  } else {
    return false
  }
}

export function paymentStatusBahasa (status) {
  switch (status) {
    case 'PAID':
      return 'Sudah Dibayar'
    case 'WAITING_FOR_PAYMENT':
      return 'Menunggu Pembayaran'
    case 'FAILED':
      return 'Pembayaran Gagal'
    case 'ACTIVE':
      return 'Dalam Proses'
    case 'EXPIRED':
      return 'Kedaluarsa'
    case 'NO_PAYMENT_MADE':
      return 'Belum Ada Pembayaran'
    default:
      return ''
  }
}

export const getOfflineQuotePathOnStatus = (status, id, isApplicationId) => {
  if (isApplicationId) {
    return `/transactions/${id}/details`
  }
  // case QUOTE_ACCEPTED:
  // case POLICY_ISSUANCE_PENDING:
  switch (status) {
    case POLICY_ISSUED:
    case POLICY_COMPLETED:
      return `/transactions/${id}/details`
    case QUOTE_ISSUED:
    // case QUOTE_IN_PROGRESS:
      return `/offline/quotes/${id}`
    default:
      return `/offline/detail/${id}`
  }
}

export const getMapProductWithIcon = (productData = []) => {
  const mapped = {}
  if (Array.isArray(productData)) {
    productData.forEach(product => {
      mapped[product.code] = product.logo
      mapped[product.name] = product.logo
    })
  }
  return mapped
}
