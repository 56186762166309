import styled from 'styled-components'

export const InnerHeaderWrapper = styled.div`
  position: ${props => props.headerPosition || 'fixed'};
  top: 0;
  background-color: ${props => props.backgroundColor || props.theme.primary};
  font-weight: bold;
  cursor: pointer;
  color: ${props => props.theme.white};
  width: 100%;
  max-width: 450px;
  z-index: 3;
  .inner {
    padding: 8px;
    display: grid;
    grid-template-columns: 24px 1fr 40px 40px;
  }
  .help-btn {
    padding: 4px 8px;
    color: ${props => props.theme.black};
    background-color: ${props => props.theme.white};
    font-size: 10px;
    font-weight: 800;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.black};
  }
  .back {
    width: 28px;
    grid-column: 1/2;
    z-index: 1;
    grid-row: 1 /1;
  }
  .close-cross {
    width: 26px;
    height: 26px;
    grid-column: 4/4;
    grid-row: 1 /1;
    justify-self: center;
  }
  .side-menu-icon {
    grid-column: 4/4;
    grid-row: 1 /1;
    justify-self: center;
  }
  .save-icon {
    width: 26px;
    height: 26px;
    grid-column: 3/4;
    grid-row: 1 /1;
  }

  .save-blur {
    opacity: 0.6
  }

  .img-width {
    max-width: 100%;
    max-height: 100%;
  }
  .heading {
    text-align: center;
    color: ${props => props.theme.black};
  }
  .pp-logo {
    width: auto;
    max-height: 18px;
  }
  .head-container {
    text-align: center;
    padding: 6px 0;
    flex: 1;
    grid-column: 1 / 5;
    grid-row: 1 /1;
  }
  .notification-icon {
    position: relative;
    padding: 6px;
  }
  .green-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.theme.deepSkyBlue};
    border: 1px solid ${props => props.theme.black};
  }

  .saved-icon-pos {
    position: relative;

    .saved-done {
      position: absolute;
      right: 0;
      top: -5px;
    }
  }

  .delete-icon {
    width: 100%;
    height: 100%;
    grid-column: 4/4;
    grid-row: 1 /1;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 823px) and (orientation: landscape){
    max-width: 100%;
    margin: none;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) { 
    max-width: 100%;
    margin: none;
  }
`
