import { MiniContentWrapper } from './index.style'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import LazyLoad from 'react-lazyload'
import { useCallback } from 'react'
import { Grid } from '../../commonStyle/index.style'
import { PolicyStatusChip } from '../MVPolicyCard/index.style'
import { getPolicyStatus, getPaymentStateChip } from '../../helpers/data'
import { useGlobalContextValue } from '../../contextProvider/GlobalContext/index'
import { LANG_STRINGS } from '../../lang/index'

function MiniContent ({ color, onClick, application }) {
  const policyState = getPolicyStatus(application.policy_status)
  const paymentState = getPaymentStateChip(application.payment_status)
  const [{ productIconData = {} }] = useGlobalContextValue()

  const getNameInfo = useCallback(() => {
    const { customer_data: customerData } = application
    let name = ''
    if (customerData.name) {
      name = `${customerData.name}`
    }
    if (application.vehicle_plate_no && application.region_code) {
      name = `${name} / ${application.region_code} ${application.vehicle_plate_no}`
    }
    if (!name) {
      name = application.application_no
    }
    return name
  }, [application])

  return (
    <MiniContentWrapper color={color} onClick={onClick}>
      <div className='provider-logo-box'>
        <LazyLoad height={40} once>
          <img
            src={productIconData[application.product.name]}
            className='provider-logo'
            alt='img-logo'
            width={36}
          />
        </LazyLoad>
      </div>
      <div className='first-row'>
        <CustomText color={AgentColorPallette.fontDarkerGrey} size='0.7rem'>
          {application.product.provider.name}
        </CustomText>
        <CustomText color={AgentColorPallette.black} weight={700} size='0.9rem' bottom='1rem'>
          {getNameInfo()}
        </CustomText>
        <Grid gridColumn='minmax(0, auto) minmax(0, auto) minmax(0, auto)' gridGap='1rem'>
          <PolicyStatusChip
            bgColor={policyState.color}
            textAlign='center'
            config={{
              size: '0.6em',
              padding: '0.4rem'
            }}
          >
            {policyState.text}
          </PolicyStatusChip>
          {
            !['ACTIVE', 'POLICY_CREATED'].includes(application.policy_status) && (
              <PolicyStatusChip
                bgColor={paymentState.color}
                color={paymentState.textColor}
                textAlign='center'
                config={{
                  size: '0.6em',
                  padding: '0.4rem'
                }}
              >
                {paymentState.text}
              </PolicyStatusChip>
            )
          }
          {
            (application.use_pay_later) && (
              <PolicyStatusChip
                bgColor={AgentColorPallette.darkPurple}
                textAlign='center'
                config={{
                  size: '0.6em',
                  padding: '0.4rem'
                }}
              >
                PAY LATER: {application.pay_later_expire_pending_days}&nbsp;{LANG_STRINGS.days}
              </PolicyStatusChip>
            )
          }
          {
            application.expire_days && (
              <PolicyStatusChip
                bgColor={AgentColorPallette.orangeMangoTango}
                textAlign='center'
                config={{
                  size: '0.6em',
                  padding: '0.4rem'
                }}
              >
                AKAN BERAKHIR: {application.expire_days}&nbsp;{LANG_STRINGS.days}
              </PolicyStatusChip>
            )
          }
        </Grid>
      </div>
    </MiniContentWrapper>
  )
}

export default MiniContent
