import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const PublicFooterWrapper = styled.div`
  position: absolute;
  color: ${AgentColorPallette.font};
  background-color: rgba(0,0,0,0.6);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  bottom: ${props => props.bottom ? props.bottom : '0'};
  ${props => props.fullBorder && `
    border-bottom-right-radius: 14px;
  `}
  ${props => props.fullBorder && `
    border-bottom-left-radius: 14px;
  `}
  left: 0;
  right: 0;
  margin: 0 1rem;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  max-width: 450px;
  span {
    color: ${AgentColorPallette.primary};
    margin-left: 4px;
    cursor: pointer;
  }

  .otp-footer {
    width: 100%;
  }

  @media only screen 
    and (min-device-width: 450px) 
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 2)
    {
      max-width: 100%;
    }
`
