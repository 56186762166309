import axios from 'axios'
import { queryBuilder, urlMapper } from '../helpers/utility'

const post = ({ url = '', query = {}, payload = {}, params = {}, body = {} }) => {
  const queryString = queryBuilder(query)
  const mappedUrl = urlMapper(url, params)
  const combinedUrl = `${mappedUrl}${queryString}`
  return axios
    .post(combinedUrl, {
      ...payload,
      ...body
    })
    .catch(error => {
      if (error.response) {
        throw error.response
      } else {
        throw error
      }
    })
    .then(response => {
      return response
    })
}

export default post
