import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'
import { ARROW_DOWN } from '../../images'

export const SelectWrapper = styled.select`
  margin-bottom: 12px;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: ${props => props.error ? `2px solid ${AgentColorPallette.errorDark} !important` : props.displayBorder ? `1px solid ${AgentColorPallette.grey}` : 'none'};
  background-color: ${AgentColorPallette.white};
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: ${props => props.textColor ? AgentColorPallette.black : AgentColorPallette.fontGrey};
  font-size: 12px;
  background-image: url(${ARROW_DOWN});
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  &:invalid {
    color: ${props => props.theme.fontSemiGrey};
  }
  &::-ms-expand {
   display: none;
  }
  &:focus {
    outline: none;
  }
`
