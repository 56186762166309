import { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { AutoSearchStyle } from './index.style'
import { getDiffaultForAutoSuggest } from '../../helpers/utility'
import get from 'lodash/get'

class AutoSearchInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    errorField: PropTypes.string,
    id: PropTypes.string,
    displayKey: PropTypes.string,
    dataKey: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    loading: PropTypes.string,
    onSelect: PropTypes.func,
    data: PropTypes.array,
    className: PropTypes.string
  }

  constructor (props) {
    super(props)
    const { data, dataKey, displayKey, defaultValue } = props
    this.state = {
      selectedValue: this.getTrimDefaultValue(data, dataKey, displayKey, defaultValue),
      suggestions: []
    }
    this.isValueSet = false
  }

  componentWillReceiveProps (nextProps) {
    const { data, dataKey, displayKey, defaultValue } = nextProps
    const { selectedValue } = this.state
    if (!this.isValueSet && get(data, 'length', 0) && defaultValue && defaultValue !== selectedValue) {
      this.isValueSet = true
      this.setState({
        selectedValue: this.getTrimDefaultValue(data, dataKey, displayKey, defaultValue) // (defaultValue && defaultValue.trim() && getDiffaultForAutoSuggest(data, dataKey, displayKey, defaultValue)) || ''
      })
    }
  }

  getTrimDefaultValue = (data, dataKey, displayKey, defaultValue) => {
    let trimDefaultValue = defaultValue
    if (defaultValue && (typeof defaultValue === 'string')) {
      trimDefaultValue = defaultValue.trim()
    }
    return (trimDefaultValue && getDiffaultForAutoSuggest(data, dataKey, displayKey, trimDefaultValue)) || ''
  }

  getSuggestionValue = suggestion => {
    const { displayKey, dataKey } = this.props
    this.props.onSelect(suggestion[dataKey])
    return suggestion[displayKey]
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const { data, displayKey } = this.props
    return inputLength === 0 ? data : data.filter(item => {
      if (item[displayKey]) {
        return item[displayKey].toString().toLowerCase().slice(0, inputLength) === inputValue
      }
      return false
    })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handleAutoSuggestOnFocus = () => {
    return true
  }

  renderSuggestion = suggestion => (
    <div className='auto-suggestion-value'>
      {suggestion[this.props.displayKey]}
    </div>
  )

  onChange = (event, { newValue }) => {
    this.setState({
      selectedValue: newValue.toString()
    })
  }

  resetAutoSuggest = () => {
    // reset the auto suggest data
    this.setState({
      selectedValue: ''
    })
  }

  render () {
    const {
      id, name, loading, errorField,
      placeholder = 'Cari...', className = ''
    } = this.props
    const { selectedValue, suggestions } = this.state
    const inputProps = {
      placeholder: loading === name ? 'Memuat...' : placeholder,
      value: selectedValue || '',
      onChange: this.onChange
    }

    return (
      <AutoSearchStyle error={errorField === name} className={className}>
        <Autosuggest
          id={id}
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </AutoSearchStyle>
    )
  }
}

export default AutoSearchInput
