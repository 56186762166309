import { Component } from 'react'
import CustomText from '../CustomText'
import { AgentColorPallette } from '../../configs/colorPallete'
import ModifyTermsConditions from './components/ModifytTermsConditions'

class TermsCondition extends Component {
  render () {
    const { termsData, className } = this.props
    return (
      Array.isArray(termsData) ? termsData.map((item, index) =>
        <div key={`terms_${index}`}>
          <CustomText color={AgentColorPallette.regular} size='14px' bottom='8px' className={className}>
            <div dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item}` }} />
          </CustomText>
        </div>
      ) : (
        <ModifyTermsConditions data={termsData} />
      )
    )
  }
}

export default TermsCondition
