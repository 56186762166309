import styled from "styled-components";

export const InputWrapper = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: ${props => props.backgroundColor || props.theme.font};
  opacity: ${props => props.opacity || 1};
  margin-bottom: 12px;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 14px;
  color: ${props => props.error ? props.theme.errorDark : props.theme.black};
  border: ${props => props.error ? "2px solid " + props.theme.errorDark : "none"};
  padding: 12px;
  letter-spacing: 4px;
  text-align: center;
  font-weight: 600;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${props => props.theme.fontGrey};
    font-size: 14px;
    letter-spacing: 2px;
  }
`