import styled from 'styled-components'
import { AgentColorPallette } from '../../configs/colorPallete'

export const NotificationWrapper = styled.div`
  background-color: ${props => props.type === 'error' ? AgentColorPallette.error : AgentColorPallette.secondary};
  color: ${AgentColorPallette.white};
  padding: 12px;
  width: 84%;
  text-align: center;
  display: flex;
  border-radius: 24px;
  justify-content: center;
  position: fixed;
  top: ${props => props.top || '12%'};
  max-width: 390px;
  font-size: 14px;
`
