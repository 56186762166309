const actions = {
  GET_PACKAGES_REQUEST: 'GET_PACKAGES_REQUEST',
  GET_PACKAGES_SUCCESS: 'GET_PACKAGES_SUCCESS',
  GET_PACKAGES_FAILURE: 'GET_PACKAGES_FAILURE',

  GET_REGION_CODE_REQUEST: 'GET_REGION_CODE_REQUEST',
  GET_REGION_CODE_SUCCESS: 'GET_REGION_CODE_SUCCESS',
  GET_REGION_CODE_FAILURE: 'GET_REGION_CODE_FAILURE',

  GET_PACKAGE_DETAIL_REQUEST: 'GET_PACKAGE_DETAIL_REQUEST',
  GET_PACKAGE_DETAIL_SUCCESS: 'GET_PACKAGE_DETAIL_SUCCESS',
  GET_PACKAGE_DETAIL_FAILURE: 'GET_PACKAGE_DETAIL_FAILURE',

  GET_RENEWAL_PACKAGE_DETAIL_REQUEST: 'GET_RENEWAL_PACKAGE_DETAIL_REQUEST',
  GET_RENEWAL_PACKAGE_DETAIL_SUCCESS: 'GET_RENEWAL_PACKAGE_DETAIL_SUCCESS',
  GET_RENEWAL_PACKAGE_DETAIL_FAILURE: 'GET_RENEWAL_PACKAGE_DETAIL_FAILURE',

  GET_VA_DETAIL_REQUEST: 'GET_VA_DETAIL_REQUEST',
  GET_VA_DETAIL_SUCCESS: 'GET_VA_DETAIL_SUCCESS',
  GET_VA_DETAIL_FAILURE: 'GET_VA_DETAIL_FAILURE',

  DOWNLOAD_PAYMENT_INSTRUCTIONS_REQUEST: 'DOWNLOAD_PAYMENT_INSTRUCTIONS_REQUEST',
  DOWNLOAD_PAYMENT_INSTRUCTIONS_SUCCESS: 'DOWNLOAD_PAYMENT_INSTRUCTIONS_SUCCESS',
  DOWNLOAD_PAYMENT_INSTRUCTIONS_FAILURE: 'DOWNLOAD_PAYMENT_INSTRUCTIONS_FAILURE',

  GET_WORK_SHOP_LIST_REQUEST: 'GET_WORK_SHOP_LIST_REQUEST',
  GET_WORK_SHOP_LIST_SUCCESS: 'GET_WORK_SHOP_LIST_SUCCESS',
  GET_WORK_SHOP_LIST_FAILURE: 'GET_WORK_SHOP_LIST_FAILURE',

  CREATE_POLICY_REQUEST: 'CREATE_POLICY_REQUEST',
  CREATE_POLICY_SUCCESS: 'CREATE_POLICY_SUCCESS',
  CREATE_POLICY_FAILURE: 'CREATE_POLICY_FAILURE',

  GET_UPLOAD_LINK_REQUEST: 'GET_UPLOAD_LINK_REQUEST',
  GET_UPLOAD_LINK_SUCCESS: 'GET_UPLOAD_LINK_SUCCESS',
  GET_UPLOAD_LINK_FAILURE: 'GET_UPLOAD_LINK_FAILURE',

  SAVE_APPLICATION_DATA_REQUEST: 'SAVE_APPLICATION_DATA_REQUEST',
  SAVE_POLICY_APPLICATION_STEP_REQUEST: 'SAVE_POLICY_APPLICATION_STEP_REQUEST',

  GET_APPLICATION_DETAIL_REQUEST: 'GET_APPLICATION_DETAIL_REQUEST',
  GET_APPLICATION_DETAIL_SUCCESS: 'GET_APPLICATION_DETAIL_SUCCESS',
  GET_APPLICATION_DETAIL_FAILURE: 'GET_APPLICATION_DETAIL_FAILURE',

  GET_RENEWAL_QUOTATION_DATA_REQUEST: 'GET_RENEWAL_QUOTATION_DATA_REQUEST',
  GET_RENEWAL_QUOTATION_DATA_SUCCESS: 'GET_RENEWAL_QUOTATION_DATA_SUCCESS',
  GET_RENEWAL_QUOTATION_DATA_FAILURE: 'GET_RENEWAL_QUOTATION_DATA_FAILURE',

  SUBMIT_RENEWAL_QUOTATION_DATA_REQUEST: 'SUBMIT_RENEWAL_QUOTATION_DATA_REQUEST',
  SUBMIT_RENEWAL_QUOTATION_DATA_SUCCESS: 'SUBMIT_RENEWAL_QUOTATION_DATA_SUCCESS',
  SUBMIT_RENEWAL_QUOTATION_DATA_FAILURE: 'SUBMIT_RENEWAL_QUOTATION_DATA_FAILURE',

  GET_RENEWAL_POLICY_REQUEST: 'GET_RENEWAL_POLICY_REQUEST',
  GET_RENEWAL_POLICY_SUCCESS: 'GET_RENEWAL_POLICY_SUCCESS',
  GET_RENEWAL_POLICY_FAILURE: 'GET_RENEWAL_POLICY_FAILURE',

  UPDATE_EMAIL_REQUEST: 'UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'UPDATE_EMAIL_FAILURE',

  SUBMIT_APPLICATION_REQUEST: 'SUBMIT_APPLICATION_REQUEST',
  SUBMIT_APPLICATION_SUCCESS: 'SUBMIT_APPLICATION_SUCCESS',
  SUBMIT_APPLICATION_FAILURE: 'SUBMIT_APPLICATION_FAILURE',

  SEND_PAYMENT_LINK_REQUEST: 'SEND_PAYMENT_LINK_REQUEST',
  SEND_PAYMENT_LINK_SUCCESS: 'SEND_PAYMENT_LINK_SUCCESS',
  SEND_PAYMENT_LINK_FAILURE: 'SEND_PAYMENT_LINK_FAILURE',

  RESEND_PAYMENT_LINK_REQUEST: 'RESEND_PAYMENT_LINK_REQUEST',
  RESEND_PAYMENT_LINK_SUCCESS: 'RESEND_PAYMENT_LINK_SUCCESS',
  RESEND_PAYMENT_LINK_FAILURE: 'RESEND_PAYMENT_LINK_FAILURE',

  GET_APPLICATION_LIST_REQUEST: 'GET_APPLICATION_LIST_REQUEST',
  GET_APPLICATION_LIST_SUCCESS: 'GET_APPLICATION_LIST_SUCCESS',
  GET_APPLICATION_LIST_FAILURE: 'GET_APPLICATION_LIST_FAILURE',

  GET_BRAND_LIST_REQUEST: 'GET_BRAND_LIST_REQUEST',
  GET_BRAND_LIST_SUCCESS: 'GET_BRAND_LIST_SUCCESS',
  GET_BRAND_LIST_FAILURE: 'GET_BRAND_LIST_FAILURE',

  GET_MODEL_LIST_REQUEST: 'GET_MODEL_LIST_REQUEST',
  GET_MODEL_LIST_SUCCESS: 'GET_MODEL_LIST_SUCCESS',
  GET_MODEL_LIST_FAILURE: 'GET_MODEL_LIST_FAILURE',

  GET_VEHICLE_YEAR_REQUEST: 'GET_VEHICLE_YEAR_REQUEST',
  GET_VEHICLE_YEAR_SUCCESS: 'GET_VEHICLE_YEAR_SUCCESS',
  GET_VEHICLE_YEAR_FAILURE: 'GET_VEHICLE_YEAR_FAILURE',

  GET_VARIANT_LIST_REQUEST: 'GET_VARIANT_LIST_REQUEST',
  GET_VARIANT_LIST_SUCCESS: 'GET_VARIANT_LIST_SUCCESS',
  GET_VARIANT_LIST_FAILURE: 'GET_VARIANT_LIST_FAILURE',

  GET_VARIANT_PRICE_REQUEST: 'GET_VARIANT_PRICE_REQUEST',
  GET_VARIANT_PRICE_SUCCESS: 'GET_VARIANT_PRICE_SUCCESS',
  GET_VARIANT_PRICE_FAILURE: 'GET_VARIANT_PRICE_FAILURE',

  GET_ADDONS_LIST_REQUEST: 'GET_ADDONS_LIST_REQUEST',
  GET_ADDONS_LIST_SUCCESS: 'GET_ADDONS_LIST_SUCCESS',
  GET_ADDONS_LIST_FAILURE: 'GET_ADDONS_LIST_FAILURE',

  SEND_QUOTATION_REQUEST: 'SEND_QUOTATION_REQUEST',
  SEND_QUOTATION_SUCCESS: 'SEND_QUOTATION_SUCCESS',
  SEND_QUOTATION_FAILURE: 'SEND_QUOTATION_FAILURE',

  UPDATE_POLICY_QUOTE_REQUEST: 'UPDATE_POLICY_QUOTE_REQUEST',
  UPDATE_POLICY_QUOTE_SUCCESS: 'UPDATE_POLICY_QUOTE_SUCCESS',
  UPDATE_POLICY_QUOTE_FAILURE: 'UPDATE_POLICY_QUOTE_FAILURE',

  UPDATE_POLICY_QUOTE_DISCOUNT_REQUEST: 'UPDATE_POLICY_QUOTE_DISCOUNT_REQUEST',
  UPDATE_POLICY_QUOTE_DISCOUNT_SUCCESS: 'UPDATE_POLICY_QUOTE_DISCOUNT_SUCCESS',
  UPDATE_POLICY_QUOTE_DISCOUNT_FAILURE: 'UPDATE_POLICY_QUOTE_DISCOUNT_FAILURE',

  SELECTED_PAYMENT_TYPE_DATA: 'SELECTED_PAYMENT_TYPE_DATA',
  UPDATE_USE_BALANCE: 'UPDATE_USE_BALANCE',
  UPDATE_USE_POINT: 'UPDATE_USE_POINT',
  UPDATE_TAX_ON_BALANCE: 'UPDATE_TAX_ON_BALANCE',

  AGENT_PAYING_REQUEST: 'AGENT_PAYING_REQUEST',
  AGENT_PAYING_SUCCESS: 'AGENT_PAYING_SUCCESS',
  AGENT_PAYING_FAILURE: 'AGENT_PAYING_FAILURE',

  CALCULATE_ADDON_PREMIUM_REQUEST: 'CALCULATE_ADDON_PREMIUM_REQUEST',
  CALCULATE_ADDON_PREMIUM_SUCCESS: 'CALCULATE_ADDON_PREMIUM_SUCCESS',
  CALCULATE_ADDON_PREMIUM_FAILURE: 'CALCULATE_ADDON_PREMIUM_FAILURE',

  PAY_LATER_REQUEST: 'PAY_LATER_REQUEST',
  PAY_LATER_SUCCESS: 'PAY_LATER_SUCCESS',
  PAY_LATER_FAILURE: 'PAY_LATER_FAILURE',

  UPDATE_TRANSACTION_TYPE_REQUEST: 'UPDATE_TRANSACTION_TYPE_REQUEST',
  UPDATE_TRANSACTION_TYPE_SUCCESS: 'UPDATE_TRANSACTION_TYPE_SUCCESS',
  UPDATE_TRANSACTION_TYPE_FAILURE: 'UPDATE_TRANSACTION_TYPE_FAILURE',

  GET_DOWNLOAD_PDF_REQUEST: 'GET_DOWNLOAD_PDF_REQUEST',
  GET_DOWNLOAD_PDF_SUCCESS: 'GET_DOWNLOAD_PDF_SUCCESS',
  GET_DOWNLOAD_PDF_FAILURE: 'GET_DOWNLOAD_PDF_FAILURE',

  GET_DOWNLOAD_DRAFT_PDF_REQUEST: 'GET_DOWNLOAD_DRAFT_PDF_REQUEST',
  GET_DOWNLOAD_DRAFT_PDF_REQUEST_SUCCESS: 'GET_DOWNLOAD_DRAFT_PDF_REQUEST_SUCCESS',
  GET_DOWNLOAD_DRAFT_PDF_REQUEST_FAILURE: 'GET_DOWNLOAD_DRAFT_PDF_REQUEST_FAILURE',

  GET_INSURANCE_DOCS_REQUEST: 'GET_INSURANCE_DOCS_REQUEST',
  GET_INSURANCE_DOCS_SUCCESS: 'GET_INSURANCE_DOCS_SUCCESS',
  GET_INSURANCE_DOCS_FAILURE: 'GET_INSURANCE_DOCS_FAILURE',

  GET_INSTANT_PAYOUT_DETAIL_REQUEST: 'GET_INSTANT_PAYOUT_DETAIL_REQUEST',
  GET_INSTANT_PAYOUT_DETAIL_SUCCESS: 'GET_INSTANT_PAYOUT_DETAIL_SUCCESS',
  GET_INSTANT_PAYOUT_DETAIL_FAILURE: 'GET_INSTANT_PAYOUT_DETAIL_FAILURE',

  GET_TAX_SLAB_REQUEST: 'GET_TAX_SLAB_REQUEST',
  GET_TAX_SLAB_SUCCESS: 'GET_TAX_SLAB_SUCCESS',
  GET_TAX_SLAB_FAILURE: 'GET_TAX_SLAB_FAILURE',

  VALIDATE_CHASSIS_NO_REQUEST: 'VALIDATE_CHASSIS_NO_REQUEST',
  VALIDATE_CHASSIS_NO_SUCCESS: 'VALIDATE_CHASSIS_NO_SUCCESS',
  VALIDATE_CHASSIS_NO_FAILURE: 'VALIDATE_CHASSIS_NO_FAILURE',

  RESET_POLICY_STORE: 'RESET_POLICY_STORE',
  SAVE_ADDONS_TO_STORE_REQUEST: 'SAVE_ADDONS_TO_STORE_REQUEST',
  RESET_POLICY_DATA: 'RESET_POLICY_DATA',

  getTaxSlabRequest: (payload) => ({
    type: actions.GET_TAX_SLAB_REQUEST,
    payload
  }),
  getRenewalPolicyRequest: (payload) => ({
    type: actions.GET_RENEWAL_POLICY_REQUEST,
    payload
  }),
  getPackages: payload => ({
    type: actions.GET_PACKAGES_REQUEST,
    payload
  }),
  getRegionCode: () => ({
    type: actions.GET_REGION_CODE_REQUEST
  }),
  getPackageDetail: payload => ({
    type: actions.GET_PACKAGE_DETAIL_REQUEST,
    payload
  }),
  getRenewalPackageDetail: payload => ({
    type: actions.GET_RENEWAL_PACKAGE_DETAIL_REQUEST,
    payload
  }),
  getVaDetail: payload => ({
    type: actions.GET_VA_DETAIL_REQUEST,
    payload
  }),
  downloadPaymentInstructions: payload => ({
    type: actions.DOWNLOAD_PAYMENT_INSTRUCTIONS_REQUEST,
    payload
  }),
  createPolicy: payload => ({
    type: actions.CREATE_POLICY_REQUEST,
    payload
  }),
  updatePolicyQuotation: payload => ({
    type: actions.UPDATE_POLICY_QUOTE_REQUEST,
    payload
  }),
  updatePolicyQuotationDiscount: payload => ({
    type: actions.UPDATE_POLICY_QUOTE_DISCOUNT_REQUEST,
    payload
  }),
  getUploadLink: payload => ({
    type: actions.GET_UPLOAD_LINK_REQUEST,
    payload
  }),
  saveApplicationData: payload => ({
    type: actions.SAVE_APPLICATION_DATA_REQUEST,
    payload
  }),
  getApplicationDetail: payload => ({
    type: actions.GET_APPLICATION_DETAIL_REQUEST,
    payload
  }),
  getRenewalQuoatationData: payload => ({
    type: actions.GET_RENEWAL_QUOTATION_DATA_REQUEST,
    payload
  }),
  submitRenewalQuoatationData: payload => ({
    type: actions.SUBMIT_RENEWAL_QUOTATION_DATA_REQUEST,
    payload
  }),
  savePolicyApplicationStep: payload => ({
    type: actions.SAVE_POLICY_APPLICATION_STEP_REQUEST,
    payload
  }),
  updateEmail: payload => ({
    type: actions.UPDATE_EMAIL_REQUEST,
    payload
  }),
  submitApplication: payload => ({
    type: actions.SUBMIT_APPLICATION_REQUEST,
    payload
  }),
  sendPaymentLink: payload => ({
    type: actions.SEND_PAYMENT_LINK_REQUEST,
    payload
  }),
  resendPaymentLink: payload => ({
    type: actions.RESEND_PAYMENT_LINK_REQUEST,
    payload
  }),
  getApplicationList: payload => ({
    type: actions.GET_APPLICATION_LIST_REQUEST,
    payload
  }),
  getBrandList: payload => ({
    type: actions.GET_BRAND_LIST_REQUEST,
    payload
  }),
  getModelList: payload => ({
    type: actions.GET_MODEL_LIST_REQUEST,
    payload
  }),
  getVariantList: payload => ({
    type: actions.GET_VARIANT_LIST_REQUEST,
    payload
  }),
  getVariantYear: payload => ({
    type: actions.GET_VEHICLE_YEAR_REQUEST,
    payload
  }),
  getAddonsList: payload => ({
    type: actions.GET_ADDONS_LIST_REQUEST,
    payload
  }),
  sendQuotation: (payload) => ({
    type: actions.SEND_QUOTATION_REQUEST,
    payload
  }),
  saveAddonsToStore: payload => ({
    type: actions.SAVE_ADDONS_TO_STORE_REQUEST,
    payload
  }),
  updateStoreValue: payload => ({
    type: payload.type,
    payload: payload.value
  }),
  agentPayingRequest: payload => ({
    type: actions.AGENT_PAYING_REQUEST,
    payload
  }),
  calculateAddonPremium: payload => ({
    type: actions.CALCULATE_ADDON_PREMIUM_REQUEST,
    payload
  }),
  getWorkShopList: (payload) => ({
    type: actions.GET_WORK_SHOP_LIST_REQUEST,
    payload
  }),
  payLaterRequest: payload => ({
    type: actions.PAY_LATER_REQUEST,
    payload
  }),
  getDownloadPdfRequest: payload => ({
    type: actions.GET_DOWNLOAD_PDF_REQUEST,
    payload
  }),
  getDraftDownloadPdfReq: payload => ({
    type: actions.GET_DOWNLOAD_DRAFT_PDF_REQUEST,
    payload
  }),
  resetPolicyStore: payload => ({
    type: actions.RESET_POLICY_STORE,
    payload
  }),
  updateTransactionType: (payload) => ({
    type: actions.UPDATE_TRANSACTION_TYPE_REQUEST,
    payload
  }),
  getInsuranceDocs: (payload) => ({
    type: actions.GET_INSURANCE_DOCS_REQUEST,
    payload
  }),
  getInstantPayoutDetail: (payload) => ({
    type: actions.GET_INSTANT_PAYOUT_DETAIL_REQUEST,
    payload
  }),
  resetPolicyData: () => ({
    type: actions.RESET_POLICY_DATA
  }),
  validateChassisNumber: (payload) => ({
    type: actions.VALIDATE_CHASSIS_NO_REQUEST,
    payload
  })
}

export default actions
