import { BottomActionContainerStyle, Row } from '../../commonStyle/index.style'
import ButtonWithIcon from '../ButtonWithIcon'

function BottomBtn ({ icon, title, onClick, disabled, className }) {
  return (
    <BottomActionContainerStyle padding='0'>
      <Row className='button-wrapper'>
        <ButtonWithIcon
          text={title}
          icon={icon}
          iconRight
          onClick={onClick}
          disabled={disabled}
          className={className}
        />
      </Row>
    </BottomActionContainerStyle>
  )
}

export default BottomBtn
