import { Component } from 'react'
import { SelectWrapper } from './index.style'

class Select extends Component {
  render () {
    const {
      options = [], errorField, name, placeholder, disabled, border,
      value, loading, className, displayKey, dataKey, hidePlaceholder
    } = this.props
    return (
      <SelectWrapper
        className={className}
        textColor={value !== ''}
        disabled={disabled}
        displayBorder={border}
        id={`select_${name}`}
        error={errorField === name}
        value={value}
        onChange={(e) => {
          this.setState({ value: e.target.value })
          this.props.onChange(e.target.value)
        }}
        required
      >
        {
          !hidePlaceholder && (
            <option value='' selected>
              {loading === name ? 'Memuat...' : placeholder}
            </option>
          )
        }
        {
          options.map((option, index) => (
            <option
              className='option-value' key={index}
              value={
                (dataKey && option[dataKey]) || option.year || option.code ||
                option.model_code || option.region_code || option.value || option.bank_code ||
              option
              }
            >
              {
                option.region_code || option.year || option.model_name ||
                option.name || option.bank_name || (displayKey && option[displayKey]) ||
                option
              }
            </option>
          ))
        }
      </SelectWrapper>
    )
  }
}

export default Select
