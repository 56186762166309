import styled from 'styled-components'
import { SEARCH } from '../../images'

export const AutoSearchModalStyle = styled.div`
  margin-bottom: 12px;
  position: relative;

  .search-type-input {
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;
    background-color: ${props => props.disabled ? props.theme.saturatedGrey : props.theme.white};
    border: ${props => props.error ? `2px solid  ${props.theme.errorDark}` : props.displayBorder ? `1px solid ${props.theme.Gray90}` : 'none'};
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.01), 0 6px 16px 0 rgba(0,0,0,0.06);
  }

  .save-selected-item {
    width: 100%;
  }


  .multiple-item-name {
    word-break: break-all;
    font-size: 10px;
    color: ${props => props.theme.fontDarkerGrey};
  }
`
export const SearchAgentInput = styled.div`

  .search-input {
    border: 1px solid ${props => props.theme.black};
    background-image: url(${SEARCH});
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: 96%;
    background-position-y: center;
    border-radius: 30px;
  }
`

export const SearchItemsContainer = styled.div`
  margin-top: 20px;
  height: 380px;
  overflow-y: scroll;
  padding-bottom: 20px;
  background-color: ${props => props.theme.white};

  .search-items-empty {
    height: 100%;
  }

  .search-items {
    padding-bottom: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.fontLightGrey};
  }

  .search-item {
    width: ${props => props.multipleSelectionAllowed ? '95' : '100'}%;
    word-break: break-all;
  }

  .selected-icon-size {
    font-size: 8px;
    margin-top: 1px;
    color: ${props => props.theme.fontDarkerGrey};
  }

  .search-selected-item {
    color: ${props => props.theme.darkerGold};
  }

  .empty-box {
    width: 80px;
  }

  .dependent-not-exist {
    width: 250px;
  }

  @media only screen 
  and (min-device-height: 320px) 
  and (max-device-height: 640px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .empty-box {
      width: 80px;
    }
  }
`

export const AddItemStyle = styled.div`
  .save-added-brand-btn {
    margin: 20px 0;
  }
`
